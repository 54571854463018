<template>
  <div>
    <!-- ################################# Start View Mobile ################################ -->
    <div class="mt-5" v-if="$vuetify.breakpoint.mobile">
      <div class="flex items-center gap-3 px-4 justify-start">
        <v-btn icon color="green" @click="$parent.selectedMenu = null">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span
          class="font-work-sans text-lg font-semibold text-charged-grey-100"
        >
          {{ getLanguage == "en" ? "Processing" : "Proses" }}
        </span>
      </div>
      <div class="px-3 mt-4">
        <!-- <v-card outlined class="rounded-lg">
          <v-card-text>
            <div class="flex justify-between">
              <div class="text-charged-grey-400">
                <span class="font-work-sans text-xs block">Order Submit</span>
                <span class="font-work-sans text-xs block">24/07/2023</span>
              </div>
              <div>
                <div class="bg-yellow-100 px-1 rounded-lg">
                  <span
                    class="text-yellow-400 inline-block text-xs font-work-sans whitespace-nowrap"
                  >
                    Documnet Not Completed
                  </span>
                </div>
              </div>
            </div>
            <v-divider class="my-5"></v-divider>
            <div class="flex items-center gap-5">
              <div>
                <v-img
                  src="http://127.0.0.1:8080/img/shared/bikes/anoa/bike-anoa.webp"
                  width="150"
                ></v-img>
              </div>
              <div>
                <span
                  class="font-work-sans font-semibold text-charged-grey-100 block"
                >
                  RIMAU
                </span>
                <span class="font-work-sans text-charged-grey-100 block"
                  >White</span
                >
              </div>
            </div>
            <v-divider class="my-5"></v-divider>
            <v-btn
              color="primary"
              right
              class="rounded-lg ml-auto block capitalize text-sm"
            >
              Submit Document
              <v-icon style="font-size: 18px !important"
                >mdi-chevron-right</v-icon
              >
            </v-btn>
          </v-card-text>
        </v-card> -->
        <!-- <img src="/empty-folder.png" alt="empty" /> -->

        <v-img
          class="mx-auto mt-10"
          max-width="80"
          src="/empty-folder.png"
        ></v-img>
      </div>
    </div>
    <!-- ################################# End View Mobile ################################ -->

    <!-- ################################# Start View Desktop ################################ -->
    <div class="mt-10" v-if="$vuetify.breakpoint.lg">
      <span
        class="font-work-sans text-xl font-semibold text-charged-grey-100 mb-2 inline-block"
      >
        {{
          getLanguage == "en" ? "Processing Subscription" : "Proses Langganan"
        }}
      </span>
      <p class="font-work-sans text-base text-charged-grey-400">
        {{
          getLanguage == "en"
            ? "These bikes subscriptions are still in process. If you have questions, please call your sales PIC."
            : "Langganan sepeda ini masih dalam proses. Jika Anda memiliki pertanyaan, silakan hubungi PIC penjualan Anda."
        }}
      </p>

      <!-- <v-card outlined class="p-5 rounded-lg">
        <div class="flex">
          <div>
            <v-img
              src="http://127.0.0.1:8080/img/shared/bikes/anoa/bike-anoa.webp"
              width="150"
            ></v-img>
          </div>
          <div class="flex justify-between">
            <div class="font-work-sans text-sm text-charged-grey-400">
              <p>Order Submitted on 24/07/2023 - SI/24681</p>
            </div>
            <div>
              <div class="bg-yellow-100 px-1 rounded-lg">
                <span
                  class="text-yellow-400 inline-block text-sm font-work-sans"
                >
                  Documnet Not Completed
                </span>
              </div>
            </div>
          </div>
        </div>
      </v-card> -->

      <!-- <img src="/empty-folder.png" alt="empty" /> -->
      <v-img
        class="mx-auto mt-10"
        max-width="80"
        src="/empty-folder.png"
      ></v-img>
    </div>
    <!-- ################################# End View Desktop ################################ -->
  </div>
</template>
<script>
export default {
  name: "myaccount-processing",
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getIdSubs() {
      return this.$store.getters.getIdSubs;
    },
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      if (this.getIdSubs !== null) {
        // console.log("OKOKOKOK");

        let vehicle = this.$cookies.get("v_s");
        console.log("Vehicle", vehicle.split("."));
      }
    },
  },
};
</script>