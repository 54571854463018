<template>
  <div>
    <!-- ################################# Start View Mobile ################################ -->
    <div class="mt-5" v-if="$vuetify.breakpoint.mobile">
      <div class="flex items-center gap-3 px-4 justify-start">
        <v-btn icon color="green" @click="$parent.selectedMenu = null">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span
          class="font-work-sans text-lg font-semibold text-charged-grey-100"
        >
          {{
            getLanguage == "en"
              ? "Ongoing Subscription"
              : "Langganan Sedang Berlangsung"
          }}
        </span>
      </div>
      <div class="px-3 mt-4">
        <v-card
          v-if="getUser.customerType == 'mitra_driver_gojek'"
          outlined
          class="rounded-lg"
        >
          <v-card-text>
            <div class="flex justify-between">
              <div class="text-charged-grey-400">
                <span class="font-work-sans text-xs block">Subsribed</span>
                <span class="font-work-sans text-xs block">-</span>
              </div>
            </div>
            <v-divider class="my-5"></v-divider>
            <div class="flex items-center gap-5">
              <div>
                <v-img :src="'/' + opsiVehicle.image" width="150"></v-img>
              </div>
              <div>
                <span
                  class="font-work-sans font-semibold text-charged-grey-100 block"
                >
                  {{ opsiVehicle.name }}
                </span>
                <span class="font-work-sans text-charged-grey-100 block"
                  >White</span
                >
                <!-- <span class="font-work-sans text-charged-grey-400 block text-xs"
                  >1st Month Subscription : -</span
                > -->
                <span
                  class="font-work-sans text-charged-grey-400 block text-xs"
                  v-if="plan.plans_qty && getLanguage == 'en'"
                  >{{ plan.plans_qty + "th Month Subscription" }}</span
                >
              </div>
            </div>
            <span class="text-xs font-work-sans text-charged-grey-300 block"
              >Last Service : -</span
            >
            <!-- <span class="text-xs font-work-san text-red-500 block"
              >(Next service in 30 days)</span
            > -->
            <v-divider class="my-5"></v-divider>
            <v-btn
              color="primary"
              right
              class="rounded-lg ml-auto block capitalize text-sm"
              @click="goToForm"
            >
              {{
                getLanguage == "en"
                  ? "Continue Subcription"
                  : "Lanjutkan Berlangganan"
              }}
            </v-btn>
          </v-card-text>
        </v-card>

        <v-img
          v-if="getUser.customerType !== 'mitra_driver_gojek'"
          class="mx-auto mt-10"
          max-width="80"
          src="/empty-folder.png"
        ></v-img>
      </div>
    </div>
    <!-- ################################# End View Mobile ################################ -->

    <!-- ################################# Start View Desktop ################################ -->
    <div class="mt-10" v-if="$vuetify.breakpoint.lg">
      <span
        class="font-work-sans text-xl font-semibold text-charged-grey-100 mb-2 inline-block"
      >
        {{
          getLanguage == "en"
            ? "Ongoing Subscription"
            : "Langganan Yang Sedang Berlangsung"
        }}
      </span>
      <p class="font-work-sans text-base text-charged-grey-400 max-w-4xl">
        {{
          getLanguage == "en"
            ? "These are bikes that you are currently subscribed to. If you have questions, please call our sales +62 812 3768 6970."
            : "Ini adalah sepeda yang saat ini menjadi langganan Anda. Jika Anda memiliki pertanyaan, silakan hubungi sales kami di +62 812 3768 6970."
        }}
      </p>

      <v-card
        outlined
        class="p-5 rounded-lg"
        v-if="getUser.customerType == 'mitra_driver_gojek'"
      >
        <div class="flex gap-5">
          <div>
            <v-img :src="'/' + opsiVehicle.image" width="180"></v-img>
          </div>
          <div class="w-full">
            <div class="flex justify-between">
              <div class="font-work-sans text-sm text-charged-grey-400">
                <span class="block mb-1">
                  {{ getLanguage == "en" ? "Subcribed on" : "Langganan pada" }}
                  <!-- 24/07/2023 - SI/24681 -->
                  -
                </span>
                <span
                  class="font-work-sans text-lg font-semibold text-charged-grey-100 block"
                >
                  {{ opsiVehicle.name }}
                </span>
                <span class="block"
                  >{{ getLanguage == "en" ? "Color" : "Warna" }} : White</span
                >

                <span v-if="plan.plans_qty && getLanguage == 'en'">{{
                  plan.plans_qty + "th Month Subscription"
                }}</span>
              </div>
            </div>
            <span class="w-full border border-dashed inline-block"></span>
            <span
              class="font-work-sans text-sm text-charged-grey-300"
              v-if="plan.plans_qty && getLanguage == 'id'"
            >
              {{ "Langganan " + plan.plans_qty + " Bulan" }} : Rp{{
                formatPrice(plan.total_amount)
              }}/{{ plan.plans_periode }}
            </span>
          </div>
        </div>
        <v-btn
          class="ma-2 rounded-lg ml-auto block capitalize"
          color="primary"
          right
          :disabled="btnLoad"
          :loading="btnLoad"
          @click="goToForm"
        >
          {{
            getLanguage == "en"
              ? "Continue Subcription"
              : "Lanjutkan Berlangganan"
          }}
        </v-btn>
      </v-card>
      <v-img
        v-if="getUser.customerType !== 'mitra_driver_gojek'"
        class="mx-auto mt-10"
        max-width="80"
        src="/empty-folder.png"
      ></v-img>
    </div>
    <!-- ################################# End View Desktop ################################ -->
  </div>
</template>
<script>
import { getCurrentPlantApi } from "../../../../services/back-end-ruby";
export default {
  name: "myaccount-ongoing",
  data: () => {
    return {
      opsiVehicle: "",
      plan: "",
      btnLoad: false,
    };
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getIdSubs() {
      return this.$store.getters.getIdSubs;
    },
    getListVehicle() {
      return this.$store.getters.getListVehicle;
    },
  },
  created() {
    // console.log("LOAD MYACCOUNT-ONGOING");
    this.getData();
    // console.log(this.getUser);
  },
  methods: {
    async getData() {
      if (this.getIdSubs !== null) {
        // console.log("OKOKOKOK");

        let vehicle = this.$cookies.get("v_s") ?? null;
        // console.log("hahahaha", vehicle.split("."));

        await getCurrentPlantApi(
          this.$cookies.get("token_api"),
          this.getIdSubs
        ).then((res) => {
          // res;
          this.plan = res.data.subscription;
          // console.log("SUBSCRIBER", res);
          this.$parent.isLoad = false;
        });

        this.opsiVehicle =
          vehicle !== null
            ? this.getListVehicle.find(
                (v) => v.vehicle_id == vehicle.split(".")[1]
              )
            : null;

        // console.log("data_vehicle", this.opsiVehicle);
      }
    },
    goToForm() {
      this.btnLoad = true;
      if (this.getUser.customerType == "mitra_driver_gojek") {
        this.$router.push("/form-subscribe-mitra-gojek");
      } else {
        // this.$router.push("/form-subscribe");
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>