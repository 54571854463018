<template>
  <div>
    <v-dialog
      v-model="successGojek"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-toolbar :dark="dark" :color="dark ? 'grey darken-4' : '#FFF'">
        <!-- <v-btn @click="subscribeNowDialogClosed" icon style="margin-right: 20px">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn> -->
        <v-icon>mdi-lock-outline</v-icon>
        <!-- <span style="margin-right: 5px">Secure Checkout by </span> -->
        <!-- <v-img
          src="img/icons/xendit_logo.png"
          max-width="64"
          max-height="24"
        ></v-img> -->
        <v-spacer></v-spacer>
        <!-- v-if="!isMobileDevice" -->
        <v-img :src="logoSrcDark" max-width="144" max-height="32"></v-img>
        <v-spacer></v-spacer>
        <v-icon v-if="!isMobileDevice">mdi-phone</v-icon>
        <span v-if="!isMobileDevice"
          >{{ lang[getLanguage].LABEL_NEED_HELP }}?
          <a
            href="https://wa.me/6289604582129?text=Hi+Charged+Indonesia%2C+Saya+perlu+bantuan"
            target="_blank"
            class="underline"
            >Chat with us</a
          ></span
        >
      </v-toolbar>
      <v-card
        :dark="dark"
        :style="dark ? 'background-color:#2a2a2a' : 'background-color:#FFF'"
      >
        <div id="recaptcha-container"></div>
        <v-dialog v-model="loginDialog" persistent max-width="700">
          <v-card class="login">
            <v-card-text class="title text-center" style="margin-top: -10px">{{
              lang[getLanguage].OTP_TITLE
            }}</v-card-text>
            <v-card-text class="subheading font-weight-mediu text-center"
              >{{ lang[getLanguage].OTP_DESCRIPTION }}
              {{ phoneNo }}.</v-card-text
            >

            <v-container class="otp-field">
              <v-progress-circular
                v-if="!SMSsent"
                :size="50"
                color="primary"
                indeterminate
              >
              </v-progress-circular>
              <v-text-field
                class="otp"
                v-if="SMSsent"
                v-model="otp"
                outlined
                type="tel"
                color="deep-purple darken-4"
                maxlength="6"
                mask="######"
                label="Enter OTP"
                append-icon="mdi-message-processing-outline"
                @input="change1"
              ></v-text-field>
            </v-container>

            <v-btn
              :disabled="otp.length < 6 || loadingData"
              :loading="loadingData"
              color="primary"
              :width="windowWidth < 768 ? '100%' : '50%'"
              @click="verifyOtp"
              class="color:#2962FF;margin-left:-10px;margin-top:-4px;font-weight:normal;font-size:11px;text-transform: none !important;"
              >{{ lang[getLanguage].OTP_VERIVY }}</v-btn
            >
            <v-card-text class="subheading font-weight-mediu text-center"
              >{{ lang[getLanguage].OTP_NOT_SEND }}
              <v-btn
                text
                :style="
                  windowWidth < 325
                    ? 'color:#2962FF;margin-left:-10px;margin-top:-4px;font-weight:normal;font-size:11px;text-transform: none !important;'
                    : 'color:#2962FF;margin-left:-10px;margin-top:-4px;font-weight:normal;font-size:13px;text-transform: none !important;'
                "
                @click.stop="sendOtp"
                >{{ lang[getLanguage].OTP_SEND_AGAIN }}</v-btn
              >
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="alertDialog" persistent max-width="500">
          <v-card class="login">
            <v-icon v-if="this.alertStatus == 'success'" class="succes-icon"
              >mdi-check-circle</v-icon
            >
            <v-icon v-if="this.alertStatus == 'failed'" class="failed-icon"
              >mdi-close-circle</v-icon
            >
            <v-card-text class="title text-center">{{
              this.alertText
            }}</v-card-text>
            <v-btn
              :disabled="otp.length < 6 || loadingData"
              :loading="loadingData"
              color="primary"
              :width="windowWidth < 768 ? '100%' : '50%'"
              @click="closeAlertDialog"
              class="color:#2962FF;margin-left:-10px;margin-top:-4px;font-weight:normal;font-size:11px;text-transform: none !important;"
              >Ok</v-btn
            >
          </v-card>
        </v-dialog>

        <!-- DEKSTOP -->
        <div
          v-if="showView === 1 && !isMobileDevice"
          style="margin-right: 10%; margin-left: 10%; margin-top: 50px"
        >
          <v-container class="px-0">
            <v-layout class="booking-fee-header">
              <p class="text-center montserrat font-weight-bold">
                {{ lang[getLanguage].BOOKING_FEE_RECEIPT_TITLE }}
              </p>

              <p style="font-size: 0.9em" class="text-center montserrat">
                {{ lang[getLanguage].BOOKING_ORDER_NO }} :
                {{ orderData.orderNo }}
              </p>
              <hr
                style="
                  border: none;
                  border-top: 1px dashed #000;
                  height: 1px;
                  width: 100%;
                "
              />
              <p
                class="text-lg text-center font-work-sans text-charged-grey-400 font-normal mt-3"
              >
                {{ lang[getLanguage].BOOKING_FEE_RECEIPT_DESCRIPTION }}
              </p>
              <p
                class="text-center font-work-sans text-lg font-semibold text-charged-grey-400 -mt-2"
              >
                {{
                  getLanguage == "en"
                    ? "We are currently processing your data for approval and will get back to you shortly. Please wait for further instructions which we will send via email."
                    : "Kami sedang memproses data Anda untuk persetujuan dan akan segera menghubungi Anda kembali. Harap tunggu instruksi selanjutnya yang akan kami kirimkan melalui email."
                }}
              </p>
            </v-layout>

            <v-row class="booking-fee-body">
              <v-col col="4" md="4" class="booking-fee-bike">
                <!-- :src="'/img/shared/bikes/' + bikeName + '/' + bikeName + '.jpg'" -->
                <v-img
                  v-if="bikeName === 'ANOA'"
                  src="/img/shared/bikes/anoa/anoa.jpg"
                  max-height="200"
                  max-width="300"
                  width="100%"
                >
                </v-img>
                <v-img
                  v-if="bikeName === 'MALEO'"
                  src="/img/shared/bikes/maleo/maleo.jpg"
                  max-height="200"
                  max-width="300"
                  width="100%"
                >
                </v-img>
                <v-img
                  v-if="bikeName === 'RIMAU'"
                  src="/img/shared/bikes/rimau/rimau.jpg"
                  max-height="200"
                  max-width="300"
                  width="100%"
                >
                </v-img>
                <p class="booking-fee-info">
                  {{ lang[getLanguage].BOOKING_BIKE_LABEL }}
                </p>
                <span class="invTitle">{{ bikeName }}</span>
              </v-col>
              <v-col col="8" md="8">
                <!-- <v-card
              height="50"
              style="width: 100%; background-color: #f5f5f5"
              class="booking-fee-mini-card"
              outlined
              flat
            >
              <v-layout
                style="margin-top: 12px; margin-left: 10px"
                class="montserrat font-weight-bold"
              >
                Booking Fee
                <v-spacer></v-spacer>
                <span style="margin-right: 10px"
                  >Rp.{{ formatPrice(invoiceData.amount) }}</span
                >
              </v-layout>
            </v-card>
            <hr
              style="
                border: none;
                border-top: 1px dashed #000;
                height: 1px;
                width: 100%;
              "
            /> -->

                <p class="mt-6"></p>
                <span class="invTitle">{{
                  lang[getLanguage].BOOKING_SALES
                }}</span>
                <p class="mt-0"></p>

                <v-layout style="width: 100%">
                  <v-layout>
                    <span
                      class="text-base font-normal font-work-sans text-charged-grey-300"
                      style="width: 200px"
                      >{{ lang[getLanguage].PIC_NAME_LABEL }}</span
                    >
                    :
                    <span
                      class="text-base font-normal font-work-sans text-charged-grey-300"
                      style="margin-left: 20px"
                      >Wiby</span
                    >
                  </v-layout>
                </v-layout>

                <v-layout style="width: 100%">
                  <v-layout>
                    <span
                      class="text-base font-normal font-work-sans text-charged-grey-300"
                      style="width: 200px"
                      >{{ lang[getLanguage].PIC_PHONE_NUMBER_LABEL }}</span
                    >
                    :
                    <span
                      class="text-base font-normal font-work-sans text-charged-grey-300"
                      style="margin-left: 20px"
                      >+6282221188028</span
                    >
                  </v-layout>
                </v-layout>

                <div class="mt-12">
                  <!-- <p class="mt-0"></p> -->
                  <span class="invTitle">{{
                    lang[getLanguage].BOOKING_CUSTOMER
                  }}</span>
                  <p class="mt-0"></p>
                  <!-- <p class="mt-0"></p> -->

                  <v-layout style="width: 100%">
                    <v-layout>
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="width: 200px"
                        >{{ lang[getLanguage].LABEL_FULL_NAME }}</span
                      >
                      :
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="margin-left: 20px"
                        >{{ orderData.invoice.customer.givenNames }}</span
                      >
                    </v-layout>
                  </v-layout>

                  <v-layout class="mt-2" style="width: 100%">
                    <v-layout>
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="width: 200px"
                        >{{ "NIK" }}</span
                      >
                      :
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="margin-left: 20px"
                        >{{ orderData.invoice.customer.nik }}</span
                      >
                    </v-layout>
                  </v-layout>

                  <v-layout class="mt-2" style="width: 100%">
                    <v-layout>
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="width: 200px"
                        >{{ "ID Driver" }}</span
                      >
                      :
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="margin-left: 20px"
                        >{{ orderData.invoice.customer.idDriver }}</span
                      >
                    </v-layout>
                  </v-layout>

                  <v-layout class="mt-2" style="width: 100%">
                    <v-layout>
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="width: 200px"
                        >{{
                          "Alamat Email terdaftar dalam aplikasi Gojek"
                        }}</span
                      >
                      :
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="margin-left: 20px"
                        >{{ orderData.invoice.customer.email }}</span
                      >
                    </v-layout>
                  </v-layout>

                  <v-layout class="mt-2" style="width: 100%">
                    <v-layout>
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="width: 200px"
                        >{{ "Alamat Email Pribadi" }}</span
                      >
                      :
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="margin-left: 20px"
                        >{{ orderData.invoice.customer.emailPribadi }}</span
                      >
                    </v-layout>
                  </v-layout>

                  <v-layout class="mt-2" style="width: 100%">
                    <v-layout>
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="width: 200px"
                        >{{
                          "No. Handphone terdaftar dalam aplikasi Gojek"
                        }}</span
                      >
                      :
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="margin-left: 20px"
                        >{{ orderData.invoice.customer.phoneNumber }}</span
                      >
                    </v-layout>
                  </v-layout>

                  <v-layout class="mt-2" style="width: 100%">
                    <v-layout>
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="width: 200px"
                        >{{ "No. Handphone Pribadi" }}</span
                      >
                      :
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="margin-left: 20px"
                        >{{
                          orderData.invoice.customer.phoneNumberPribadi
                        }}</span
                      >
                    </v-layout>
                  </v-layout>

                  <v-layout class="mt-2" style="width: 100%" mb-4>
                    <v-layout>
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="width: 200px"
                        >{{ lang[getLanguage].LABEL_RESIDENCE }}</span
                      >
                      :
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="margin-left: 20px"
                      >
                        {{ orderData.invoice.customer.address.streetLine1 }},
                        {{ orderData.invoice.customer.address.district }},
                        {{ orderData.invoice.customer.address.city }},
                        {{ orderData.invoice.customer.address.province }}
                        {{ orderData.invoice.customer.address.postalCode }}
                      </span>
                    </v-layout>
                  </v-layout>
                </div>

                <div class="mt-12">
                  <span class="invTitle">{{
                    lang[getLanguage].BOOKING_SHIPPING
                  }}</span>
                  <p class="mt-0"></p>

                  <v-layout style="width: 100%" mb-2>
                    <v-layout class="inline-block">
                      <!-- <span
                    v-if="getLanguage === 'en'"
                    class="shipping-label"
                    style="color: #7b7b7b; font-size: 0.9em"
                    >Please <b>collect your bike</b> in:</span
                  >
                  <span
                    v-if="getLanguage === 'id'"
                    class="shipping-label"
                    style="color: #7b7b7b; font-size: 0.9em"
                    >Silahkan <b>ambil motor anda</b> di:</span
                  > -->

                      <span
                        class="text-charged-grey-400 font-work-sans text-base font-semibold inline-block mb-1.5"
                      >
                        {{
                          getLanguage == "en"
                            ? "Please wait until your data is approved, before picking up your bike. We will contact you shortly."
                            : "Harap tunggu hingga data Anda disetujui, sebelum mengambil motor Anda. Kami akan segera menghubungi Anda."
                        }}
                      </span>
                      <span
                        class="text-charged-grey-400 font-work-sans text-base font-normal inline-block"
                      >
                        {{
                          getLanguage == "en"
                            ? "Note: We currently do not provide delivery service."
                            : "Note : Saat ini kami tidak menyediakan layanan pengiriman."
                        }}
                      </span>
                    </v-layout>
                  </v-layout>

                  <v-row v-if="shippingMethod === 'Kemang'">
                    <v-col col="12" md="6">
                      <v-container fluid pa-0>
                        <div class="mapouter">
                          <div class="gmap_canvas">
                            <iframe
                              width="100%"
                              height="200"
                              id="gmap_canvas"
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126913.99706923915!2d106.68313351640629!3d-6.255508899999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f32cd05932a1%3A0xdda4027b9b1244dc!2sCharged%20Indonesia!5e0!3m2!1sen!2sid!4v1667820488650!5m2!1sen!2sid"
                              frameborder="0"
                              scrolling="no"
                              marginheight="0"
                              marginwidth="0"
                            ></iframe>
                          </div>
                        </div>
                      </v-container>
                    </v-col>

                    <v-col col="12" md="6">
                      <v-row class="mb-2">
                        <v-col cols="1" class="py-0"
                          ><v-icon>mdi-map-marker</v-icon></v-col
                        >
                        <v-col cols="11" class="py-0"
                          ><p class="mb-0">
                            Jl. Kemang Utara VII RT 002 RW 004, No. 21B, Bangka
                            - Mampang Prapatan, Jakarta Selatan 12730
                          </p></v-col
                        >
                      </v-row>

                      <v-row class="mb-2" style="margin-top: 2rem">
                        <v-col cols="1" class="py-0"></v-col>
                        <v-col cols="11" class="py-0">
                          <p>{{ lang[getLanguage].LABEL_OPENING_TITLE }}</p>
                          <p>{{ lang[getLanguage].LABEL_OPENING_TIME }}</p>
                          <p>{{ lang[getLanguage].LABEL_CLOSING_TIME }}</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <p class="booking-fee-info-2">
                          {{ lang[getLanguage].BOOKING_SHIPPING_MESSAGE }}
                        </p>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row v-if="shippingMethod === 'Cikupa'">
                    <v-col col="12" md="6">
                      <v-container fluid pa-0>
                        <div class="mapouter">
                          <div class="gmap_canvas">
                            <iframe
                              width="100%"
                              height="200"
                              id="gmap_canvas"
                              src="https://maps.google.com/maps?q=industri%20charged%20mobilitas&t=&z=15&ie=UTF8&iwloc=&output=embed"
                              frameborder="0"
                              scrolling="no"
                              marginheight="0"
                              marginwidth="0"
                            ></iframe>
                          </div>
                        </div>
                      </v-container>
                    </v-col>

                    <v-col col="12" md="6">
                      <v-row class="mb-2">
                        <v-col cols="1" class="py-0"
                          ><v-icon>mdi-map-marker</v-icon></v-col
                        >
                        <v-col cols="11" class="py-0"
                          ><p class="mb-0">
                            Jl. Bhumimas IV No.8 Talaga, Cikupa, Tangerang
                            Regency, Banten 15710
                          </p></v-col
                        >
                      </v-row>

                      <v-row class="mb-2" style="margin-top: 2rem">
                        <v-col cols="1" class="py-0"></v-col>
                        <v-col cols="11" class="py-0">
                          <p>{{ lang[getLanguage].LABEL_OPENING_TITLE }}</p>
                          <p>{{ lang[getLanguage].LABEL_OPENING_TIME }}</p>
                          <p>{{ lang[getLanguage].LABEL_CLOSING_TIME }}</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <p class="booking-fee-info-2">
                          {{ lang[getLanguage].BOOKING_SHIPPING_MESSAGE }}
                        </p>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>

                <div class="mt-12">
                  <span class="invTitle">{{
                    lang[getLanguage].BOOKING_SUBSCRIPTION
                  }}</span>
                  <p class="mt-0"></p>

                  <v-layout style="width: 100%">
                    <v-layout>
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="width: 200px"
                        >{{ lang[getLanguage].LABEL_COLOR }}</span
                      >
                      :
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="margin-left: 20px"
                      >
                        <v-layout>
                          <v-icon
                            class="radio-selection-color"
                            :color="orderData.bikeColorHex"
                            >mdi-circle
                          </v-icon>
                          -
                          {{
                            getLanguage === "en"
                              ? orderData.bikeColor
                              : orderData.bikeColor
                          }}
                        </v-layout>
                      </span>
                    </v-layout>
                  </v-layout>

                  <v-layout style="width: 100%">
                    <v-layout>
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="width: 200px"
                        >{{ lang[getLanguage].LABEL_CHARGER }}</span
                      >
                      :
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="margin-left: 20px"
                        >{{ this.orderData.chargerType }}</span
                      >
                    </v-layout>
                  </v-layout>

                  <v-layout style="width: 100%">
                    <v-layout>
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="width: 200px"
                      >
                        {{ lang[getLanguage].LABEL_BATTERY }}
                      </span>
                      :
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="margin-left: 20px"
                      >
                        {{
                          getLanguage === "en"
                            ? this.orderData.batteryQuantity
                            : this.orderData.batteryQuantity
                        }}
                        <span
                          v-if="getLanguage == 'en'"
                          class="text-base text-charged-grey-300 font-work-sans"
                          >(Additional battery included)</span
                        >
                        <span
                          v-if="getLanguage == 'id'"
                          class="text-base text-charged-grey-300 font-work-sans"
                          >(Termasuk tambahan baterai)</span
                        >
                      </span>
                    </v-layout>
                  </v-layout>

                  <v-layout
                    style="width: 100%"
                    v-if="this.orderData.bikeAccessories !== null"
                  >
                    <v-layout>
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="width: 200px"
                      >
                        {{
                          getLanguage == "en"
                            ? "Additional Accessories"
                            : "Tambahan Aksesoris"
                        }}
                      </span>
                      :
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="margin-left: 20px"
                      >
                        {{
                          getLanguage === "en"
                            ? this.orderData.bikeAccessories.conclusion.en
                            : this.orderData.bikeAccessories.conclusion.id
                        }}
                        <span
                          v-if="getLanguage == 'en'"
                          class="text-base text-charged-grey-300 font-work-sans"
                          >(Pre-Order for 30 days)</span
                        >
                        <span
                          v-if="getLanguage == 'id'"
                          class="text-base text-charged-grey-300 font-work-sans"
                          >(Pre-Order selama 30 hari)</span
                        >
                      </span>
                    </v-layout>
                  </v-layout>

                  <v-layout style="width: 100%" mb-4>
                    <v-layout>
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="width: 200px"
                        >{{ lang[getLanguage].LABEL_SUBUSCRIPTION_PLAN }}</span
                      >
                      :
                      <span
                        class="text-base font-normal font-work-sans text-charged-grey-300"
                        style="margin-left: 20px"
                        >{{
                          getLanguage === "en"
                            ? this.orderData.subscriptionPlan
                            : this.orderData.subscriptionPlan
                        }}
                      </span>
                    </v-layout>
                  </v-layout>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- DEKSTOP -->

        <!-- MOBILE -->
        <div v-if="showView === 1 && isMobileDevice">
          <!-- <v-container> -->
          <v-layout class="booking-fee-header">
            <v-container>
              <p class="text-center montserrat font-weight-bold">
                {{ lang[getLanguage].BOOKING_FEE_RECEIPT_TITLE }}
              </p>

              <p style="font-size: 0.9em" class="text-center montserrat">
                {{ lang[getLanguage].BOOKING_ORDER_NO }} :
                {{ orderData.orderNo }}
              </p>
            </v-container>
          </v-layout>

          <hr
            style="border: none; background: #f5f4f6; height: 8px; width: 100%"
          />

          <v-row class="booking-fee-body">
            <v-container>
              <p class="booking-fee-info text-center">
                {{ lang[getLanguage].BOOKING_FEE_RECEIPT_DESCRIPTION }}
              </p>
              <p
                class="text-center font-work-sans text-lg font-semibold text-charged-grey-400 -mt-2"
              >
                {{
                  getLanguage == "en"
                    ? "We are currently processing your data for approval and will get back to you shortly. Please wait for further instructions which we will send via email."
                    : "Kami sedang memproses data Anda untuk persetujuan dan akan segera menghubungi Anda kembali. Harap tunggu instruksi selanjutnya yang akan kami kirimkan melalui email."
                }}
              </p>
              <v-col col="4" md="4" class="booking-fee-bike">
                <v-img
                  v-if="bikeName === 'ANOA'"
                  src="/img/shared/bikes/anoa/anoa.jpg"
                  max-height="200"
                  max-width="300"
                  width="100%"
                >
                </v-img>
                <v-img
                  v-if="bikeName === 'MALEO'"
                  src="/img/shared/bikes/maleo/maleo.jpg"
                  max-height="200"
                  max-width="300"
                  width="100%"
                >
                </v-img>
                <v-img
                  v-if="bikeName === 'RIMAU'"
                  src="/img/shared/bikes/rimau/rimau.jpg"
                  max-height="200"
                  max-width="300"
                  width="100%"
                >
                </v-img>
                <p class="booking-fee-info">
                  {{ lang[getLanguage].BOOKING_BIKE_LABEL }}
                </p>
                <span class="invTitle">{{ bikeName }}</span>
              </v-col>
            </v-container>

            <hr
              style="
                border: none;
                background: #f5f4f6;
                height: 8px;
                width: 100%;
              "
            />

            <div class="px-4 py-6">
              <!-- <v-card
              height="50"
              style="width: 100%; background-color: #f5f5f5"
              class="booking-fee-mini-card"
              outlined
              flat
            >
              <v-layout
                style="margin-top: 12px; margin-left: 10px"
                class="montserrat font-weight-bold"
              >
                Booking Fee
                <v-spacer></v-spacer>
                <span style="margin-right: 10px"
                  >Rp.{{ formatPrice(invoiceData.amount) }}</span
                >
              </v-layout>
            </v-card>
            <hr
              style="
                border: none;
                border-top: 1px dashed #000;
                height: 1px;
                width: 100%;
              "
            /> -->

              <span class="item-title">{{
                lang[getLanguage].BOOKING_SALES
              }}</span>

              <div class="mt-5">
                <v-layout>
                  <span
                    class="item-label text-base font-normal font-work-sans text-charged-grey-300"
                    >{{ lang[getLanguage].PIC_NAME_LABEL }}</span
                  >
                </v-layout>
                <v-layout>
                  <span class="item-value">Wiby</span>
                </v-layout>
              </div>

              <div class="mt-4">
                <v-layout>
                  <span
                    class="item-label text-base font-normal font-work-sans text-charged-grey-300"
                    >Wiby</span
                  >
                </v-layout>
                <v-layout>
                  <span class="item-value">+6282221188028</span>
                </v-layout>
              </div>
            </div>

            <hr
              style="
                border: none;
                background: #f5f4f6;
                height: 8px;
                width: 100%;
              "
            />

            <div class="px-4 py-6">
              <span class="item-title">{{
                lang[getLanguage].BOOKING_CUSTOMER
              }}</span>

              <div class="mt-5">
                <v-layout>
                  <span
                    class="item-label text-base font-normal font-work-sans text-charged-grey-300"
                    >{{ lang[getLanguage].LABEL_FULL_NAME }}</span
                  >
                </v-layout>
                <v-layout>
                  <span class="item-value">{{
                    orderData.invoice.customer.givenNames
                  }}</span>
                </v-layout>
              </div>

              <div class="mt-5">
                <v-layout>
                  <span
                    class="item-label text-base font-normal font-work-sans text-charged-grey-300"
                    >{{ "NIK" }}</span
                  >
                </v-layout>
                <v-layout>
                  <span class="item-value">{{
                    orderData.invoice.customer.nik
                  }}</span>
                </v-layout>
              </div>

              <div class="mt-5">
                <v-layout>
                  <span
                    class="item-label text-base font-normal font-work-sans text-charged-grey-300"
                    >{{ "ID Driver" }}</span
                  >
                </v-layout>
                <v-layout>
                  <span class="item-value">{{
                    orderData.invoice.customer.idDriver
                  }}</span>
                </v-layout>
              </div>

              <div class="mt-4">
                <v-layout>
                  <span
                    class="item-label text-base font-normal font-work-sans text-charged-grey-300"
                    >{{ "Alamat Email terdaftar dalam aplikasi Gojek" }}</span
                  >
                </v-layout>
                <v-layout>
                  <span class="item-value">{{
                    orderData.invoice.customer.email
                  }}</span>
                </v-layout>
              </div>

              <div class="mt-4">
                <v-layout>
                  <span
                    class="item-label text-base font-normal font-work-sans text-charged-grey-300"
                    >{{ "Alamat Email Pribadi" }}</span
                  >
                </v-layout>
                <v-layout>
                  <span class="item-value">{{
                    orderData.invoice.customer.emailPribadi
                  }}</span>
                </v-layout>
              </div>

              <div class="mt-4">
                <v-layout>
                  <span
                    class="item-label text-base font-normal font-work-sans text-charged-grey-300"
                    >{{ "No. Handphone terdaftar dalam aplikasi Gojek" }}</span
                  >
                </v-layout>
                <v-layout>
                  <span class="item-value">{{
                    orderData.invoice.customer.phoneNumber
                  }}</span>
                </v-layout>
              </div>

              <div class="mt-4">
                <v-layout>
                  <span
                    class="item-label text-base font-normal font-work-sans text-charged-grey-300"
                    >{{ "No. Handphone Pribadi" }}</span
                  >
                </v-layout>
                <v-layout>
                  <span class="item-value">{{
                    orderData.invoice.customer.phoneNumberPribadi
                  }}</span>
                </v-layout>
              </div>

              <div class="mt-4">
                <v-layout>
                  <span
                    class="item-label text-base font-normal font-work-sans text-charged-grey-300"
                    >{{ lang[getLanguage].LABEL_RESIDENCE }}</span
                  >
                </v-layout>
                <v-layout>
                  <span class="item-value">
                    {{ orderData.invoice.customer.address.streetLine1 }},
                    {{ orderData.invoice.customer.address.district }},
                    {{ orderData.invoice.customer.address.city }},
                    {{ orderData.invoice.customer.address.province }}
                    {{ orderData.invoice.customer.address.postalCode }}
                  </span>
                </v-layout>
              </div>

              <!-- <v-layout style="width: 100%">
              <v-layout>
                <span class="font-weight-medium" style="width: 200px">{{
                  lang[getLanguage].LABEL_FULL_NAME
                }}</span>
                :
                <span class="font-weight-medium" style="margin-left: 50px">{{
                  orderData.customer.givenNames
                }}</span>
              </v-layout>
            </v-layout>

            <v-layout style="width: 100%">
              <v-layout>
                <span class="font-weight-medium" style="width: 200px">{{
                  lang[getLanguage].EMAIL_ADDRESS
                }}</span>
                :
                <span class="font-weight-medium" style="margin-left: 50px">{{
                  orderData.customer.email
                }}</span>
              </v-layout>
            </v-layout>

            <v-layout style="width: 100%">
              <v-layout>
                <span class="font-weight-medium" style="width: 200px">{{
                  lang[getLanguage].PHONE_NUMBER
                }}</span>
                :
                <span class="font-weight-medium" style="margin-left: 50px">{{
                  orderData.customer.phoneNumber
                }}</span>
              </v-layout>
            </v-layout>

            <v-layout style="width: 100%" mb-4>
              <v-layout>
                <span class="font-weight-medium" style="width: 200px">{{
                  lang[getLanguage].LABEL_RESIDENCE
                }}</span>
                :
                <span class="font-weight-medium" style="margin-left: 50px">{{
                  orderData.customer.address.streetLine1
                }}</span>
              </v-layout>
            </v-layout> -->
            </div>

            <hr
              style="
                border: none;
                background: #f5f4f6;
                height: 8px;
                width: 100%;
              "
            />

            <v-col col="8" md="8">
              <p class="mt-0"></p>
              <span class="invTitle">{{
                lang[getLanguage].BOOKING_SHIPPING
              }}</span>
              <p class="mt-0"></p>

              <v-layout style="width: 100%" mb-2>
                <v-layout class="inline-block">
                  <!-- <span
                v-if="getLanguage === 'en'"
                class="shipping-label"
                style="color: #7b7b7b; font-size: 0.9em"
                >Please <b>collect your bike</b> in:</span
              >
              <span
                v-if="getLanguage === 'id'"
                class="shipping-label"
                style="color: #7b7b7b; font-size: 0.9em"
                >Silahkan <b>ambil motor anda</b> di:</span
              > -->
                  <span
                    class="text-charged-grey-400 font-work-sans text-base font-semibold inline-block mb-1.5"
                  >
                    {{
                      getLanguage == "en"
                        ? "Please wait until your data is approved, before picking up your bike. We will contact you shortly."
                        : "Harap tunggu hingga data Anda disetujui, sebelum mengambil motor Anda. Kami akan segera menghubungi Anda."
                    }}
                  </span>
                  <span
                    class="text-charged-grey-400 font-work-sans text-base font-normal inline-block"
                  >
                    {{
                      getLanguage == "en"
                        ? "Note: We currently do not provide delivery service."
                        : "Note : Saat ini kami tidak menyediakan layanan pengiriman."
                    }}
                  </span>
                </v-layout>
              </v-layout>

              <v-row v-if="!isMobileDevice">
                <v-col col="12" md="6">
                  <v-container fluid pa-0>
                    <div class="mapouter">
                      <div class="gmap_canvas">
                        <iframe
                          width="100%"
                          height="200"
                          id="gmap_canvas"
                          src="https://maps.google.com/maps?q=industri%20charged%20mobilitas&t=&z=15&ie=UTF8&iwloc=&output=embed"
                          frameborder="0"
                          scrolling="no"
                          marginheight="0"
                          marginwidth="0"
                        ></iframe>
                      </div>
                    </div>
                  </v-container>
                </v-col>

                <v-col col="12" md="6">
                  <v-row class="mb-2">
                    <v-col cols="1" class="py-0"
                      ><v-icon>mdi-map-marker</v-icon></v-col
                    >
                    <v-col cols="11" class="py-0"
                      ><p class="shipping-label mb-0">
                        Jl. Bhumimas IV No.8 Talaga, Cikupa, Tangerang Regency,
                        Banten 15710
                      </p></v-col
                    >
                  </v-row>

                  <v-row class="mb-2" style="margin-top: 2rem">
                    <v-col cols="1" class="py-0"></v-col>
                    <v-col cols="11" class="py-0">
                      <p class="shipping-label">
                        {{ lang[getLanguage].LABEL_OPENING_TITLE }}
                      </p>
                      <p class="shipping-label">
                        {{ lang[getLanguage].LABEL_OPENING_TIME }}
                      </p>
                      <p class="shipping-label">
                        {{ lang[getLanguage].LABEL_CLOSING_TIME }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <p class="booking-fee-info-2">
                      {{ lang[getLanguage].BOOKING_SHIPPING_MESSAGE }}
                    </p>
                  </v-row>
                </v-col>
              </v-row>

              <v-row v-if="isMobileDevice">
                <!-- <v-col col="12" md="6">
              <v-container fluid pa-0>
                <div class="mapouter">
                  <div class="gmap_canvas">
                    <iframe
                      width="100%"
                      height="200"
                      id="gmap_canvas"
                      src="https://maps.google.com/maps?q=industri%20charged%20mobilitas&t=&z=15&ie=UTF8&iwloc=&output=embed"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                    ></iframe>
                  </div>
                </div>
              </v-container>
            </v-col> -->

                <v-col col="12" md="12">
                  <!-- <v-row class="mb-2">
                <v-col cols="1" class="py-0"
                  ><v-icon>mdi-map-marker</v-icon></v-col
                > 
                <v-col cols="12" class="">
                  shippingMethod:
                  {{ shippingMethod }}
                  <p class="mb-0">
                    Jl. Bhumimas IV No.8 Talaga, Cikupa, Tangerang Regency,
                    Banten 15710
                  </p></v-col
                >
              </v-row>

              <v-row>
                <v-col cols="12" class="">
                  <v-btn
                    color="#FFF"
                    large
                    class="work-sans px-16"
                    :style="
                      getLanguage === 'en'
                        ? 'width:100%;color:#6B4693;text-transform: none !important; border: 1px solid #6B4693; border-radius: 8px;'
                        : 'font-size: 11px;width:100%;color:#6B4693;text-transform: none !important; border: 1px solid #6B4693; border-radius: 8px;'
                    "
                  >
                    View in Maps
                    <v-icon style="color: #6b4693 !important"
                      >mdi-chevron-right</v-icon
                    >
                  </v-btn>
                </v-col>
              </v-row> -->

                  <div v-if="shippingMethod === 'Kemang'">
                    <v-row class="mb-2">
                      <v-col cols="12" class="">
                        <p class="shipping-label mb-0">
                          Jl. Kemang Utara VII RT 002 RW 004, No. 21B, Bangka -
                          Mampang Prapatan, Jakarta Selatan 12730
                        </p></v-col
                      >
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="">
                        <v-btn
                          color="#FFF"
                          large
                          class="work-sans px-16"
                          :style="
                            getLanguage === 'en'
                              ? 'width:100%;color:#6B4693;text-transform: none !important; border: 1px solid #6B4693; border-radius: 8px;'
                              : 'font-size: 11px;width:100%;color:#6B4693;text-transform: none !important; border: 1px solid #6B4693; border-radius: 8px;'
                          "
                          @click="viewMaps"
                        >
                          View in Maps
                          <v-icon style="color: #6b4693 !important"
                            >mdi-chevron-right</v-icon
                          >
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="shippingMethod === 'Cikupa'">
                    <v-row class="mb-2">
                      <!-- <v-col cols="1" class="py-0"
                  ><v-icon>mdi-map-marker</v-icon></v-col
                > -->
                      <v-col cols="12" class="">
                        <p class="mb-0">
                          Jl. Bhumimas IV No.8 Talaga, Cikupa, Tangerang
                          Regency, Banten 15710
                        </p></v-col
                      >
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="">
                        <v-btn
                          color="#FFF"
                          large
                          class="work-sans px-16"
                          :style="
                            getLanguage === 'en'
                              ? 'width:100%;color:#6B4693;text-transform: none !important; border: 1px solid #6B4693; border-radius: 8px;'
                              : 'font-size: 11px;width:100%;color:#6B4693;text-transform: none !important; border: 1px solid #6B4693; border-radius: 8px;'
                          "
                        >
                          View in Maps
                          <v-icon style="color: #6b4693 !important"
                            >mdi-chevron-right</v-icon
                          >
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- <v-row class="mb-2" style="margin-top: 2rem">
                <v-col cols="1" class="py-0"></v-col>
                <v-col cols="12" class="py-0">
                  <p class="shipping-label">
                    {{ lang[getLanguage].LABEL_OPENING_TITLE }}
                  </p>
                  <p class="shipping-label">
                    {{ lang[getLanguage].LABEL_OPENING_TIME }}
                  </p>
                  <p class="shipping-label">
                    {{ lang[getLanguage].LABEL_CLOSING_TIME }}
                  </p>
                </v-col>
              </v-row> -->
                  <!-- <v-row>
                <v-col cols="12" class="py-0">
                  <p class="booking-fee-info-2">
                    {{ lang[getLanguage].BOOKING_SHIPPING_MESSAGE }}
                  </p>
                </v-col>
              </v-row> -->
                </v-col>
              </v-row>
            </v-col>

            <hr
              style="
                border: none;
                background: #f5f4f6;
                height: 8px;
                width: 100%;
              "
            />

            <v-col col="8" md="8">
              <p class="mt-0"></p>
              <span class="invTitle">{{
                lang[getLanguage].BOOKING_SUBSCRIPTION
              }}</span>
              <p class="mt-0"></p>

              <div v-if="isMobileDevice">
                <div class="mt-5">
                  <v-layout>
                    <span class="item-label text-capitalize">{{
                      lang[getLanguage].LABEL_COLOR
                    }}</span>
                  </v-layout>
                  <v-layout>
                    <div class="item-value">
                      <v-icon
                        class="radio-selection-color"
                        :color="orderData.bikeColorHex"
                        >mdi-circle
                      </v-icon>
                      -
                      {{
                        getLanguage === "en"
                          ? orderData.bikeColor
                          : orderData.bikeColor
                      }}
                    </div>
                  </v-layout>
                </div>

                <div class="mt-4">
                  <v-layout>
                    <span class="item-label">{{
                      lang[getLanguage].LABEL_CHARGER
                    }}</span>
                  </v-layout>
                  <v-layout>
                    <span class="item-value">{{
                      this.orderData.chargerType
                    }}</span>
                  </v-layout>
                </div>

                <div class="mt-4">
                  <v-layout>
                    <span class="item-label">{{
                      lang[getLanguage].LABEL_BATTERY
                    }}</span>
                  </v-layout>
                  <v-layout>
                    <span class="item-value">
                      {{ this.orderData.batteryQuantity }}
                      <span
                        v-if="getLanguage == 'en'"
                        class="text-base text-charged-grey-300 font-work-sans"
                        >(Additional battery included)</span
                      >
                      <span
                        v-if="getLanguage == 'id'"
                        class="text-base text-charged-grey-300 font-work-sans"
                        >(Termasuk tambahan baterai)</span
                      >
                    </span>
                  </v-layout>
                </div>

                <div
                  class="mt-4"
                  v-if="this.orderData.bikeAccessories !== null"
                >
                  <v-layout>
                    <span class="item-label">{{
                      getLanguage == "en"
                        ? "Additional Accessories"
                        : "Tambahan Aksesoris"
                    }}</span>
                  </v-layout>
                  <v-layout>
                    <span class="item-value">
                      {{
                        getLanguage === "en"
                          ? this.orderData.bikeAccessories.conclusion.en
                          : this.orderData.bikeAccessories.conclusion.id
                      }}
                      <span
                        v-if="getLanguage == 'en'"
                        class="text-base text-charged-grey-300 font-work-sans"
                        >(Pre-Order for 30 days)</span
                      >
                      <span
                        v-if="getLanguage == 'id'"
                        class="text-base text-charged-grey-300 font-work-sans"
                        >(Pre-Order selama 30 hari)</span
                      >
                    </span>
                  </v-layout>
                </div>

                <div class="mt-4">
                  <v-layout>
                    <span class="item-label">{{
                      lang[getLanguage].LABEL_SUBUSCRIPTION_PLAN
                    }}</span>
                  </v-layout>
                  <v-layout>
                    <span class="item-value"
                      >{{
                        getLanguage === "en"
                          ? this.orderData.subscriptionPlan.en
                          : this.orderData.subscriptionPlan.id
                      }}
                    </span>
                  </v-layout>
                </div>
              </div>

              <div v-if="!isMobileDevice">
                <v-layout style="width: 100%">
                  <v-layout>
                    <span
                      class="font-weight-medium text-base font-normal font-work-sans text-charged-grey-300"
                      style="width: 200px"
                      >{{ lang[getLanguage].LABEL_COLOR }}</span
                    >
                    :
                    <span class="font-weight-medium" style="margin-left: 50px">
                      <v-layout>
                        <v-icon
                          class="radio-selection-color"
                          :color="orderData.bikeColorHex"
                          >mdi-circle
                        </v-icon>
                        -
                        {{
                          getLanguage === "en"
                            ? orderData.bikeColor
                            : orderData.bikeColor
                        }}
                      </v-layout>
                    </span>
                  </v-layout>
                </v-layout>

                <v-layout style="width: 100%">
                  <v-layout>
                    <span
                      class="font-weight-medium text-base font-normal font-work-sans text-charged-grey-300"
                      style="width: 200px"
                      >{{ lang[getLanguage].LABEL_CHARGER }}</span
                    >
                    :
                    <span
                      class="font-weight-medium"
                      style="margin-left: 50px"
                      >{{ this.orderData.chargerType }}</span
                    >
                  </v-layout>
                </v-layout>

                <v-layout style="width: 100%">
                  <v-layout>
                    <span
                      class="font-weight-medium text-base font-normal font-work-sans text-charged-grey-300"
                      style="width: 200px"
                      >{{ lang[getLanguage].LABEL_BATTERY }}</span
                    >
                    :
                    <span class="font-weight-medium" style="margin-left: 50px">
                      {{
                        getLanguage === "en"
                          ? this.orderData.batteryQuantity
                          : this.orderData.batteryQuantity
                      }}
                      <span
                        v-if="getLanguage == 'en'"
                        class="text-base text-charged-grey-300 font-work-sans"
                        >(Additional battery included)</span
                      >
                      <span
                        v-if="getLanguage == 'id'"
                        class="text-base text-charged-grey-300 font-work-sans"
                        >(Termasuk tambahan baterai)</span
                      >
                    </span>
                  </v-layout>
                </v-layout>

                <v-layout
                  style="width: 100%"
                  v-if="this.orderData.bikeAccessories !== null"
                >
                  <v-layout>
                    <span
                      class="font-weight-medium text-base font-normal font-work-sans text-charged-grey-300"
                      style="width: 200px"
                    >
                      {{
                        getLanguage == "en"
                          ? "Additional Accessories"
                          : "Tambahan Aksesoris"
                      }}</span
                    >
                    :
                    <span class="font-weight-medium" style="margin-left: 50px">
                      {{
                        getLanguage === "en"
                          ? this.orderData.bikeAccessories.conclusion.en
                          : this.orderData.bikeAccessories.conclusion.id
                      }}
                      <span
                        v-if="getLanguage == 'en'"
                        class="text-base text-charged-grey-300 font-work-sans"
                        >(Pre-Order for 30 days)</span
                      >
                      <span
                        v-if="getLanguage == 'id'"
                        class="text-base text-charged-grey-300 font-work-sans"
                        >(Pre-Order selama 30 hari)</span
                      >
                    </span>
                  </v-layout>
                </v-layout>

                <v-layout style="width: 100%" mb-4>
                  <v-layout>
                    <span
                      class="font-weight-medium text-base font-normal font-work-sans text-charged-grey-300"
                      style="width: 200px"
                      >{{ lang[getLanguage].LABEL_SUBUSCRIPTION_PLAN }}</span
                    >
                    :
                    <span class="font-weight-medium" style="margin-left: 50px"
                      >{{
                        getLanguage === "en"
                          ? this.orderData.subscriptionPlan.en
                          : this.orderData.subscriptionPlan.id
                      }}
                    </span>
                  </v-layout>
                </v-layout>
              </div>
            </v-col>
          </v-row>
          <!-- </v-container> -->
        </div>
        <!-- MOBILE -->

        <div v-if="showView === 2">
          <v-container class="my-12">
            <v-row>
              <v-col col="12" md="12">
                <v-layout column align-center>
                  <v-icon color="red" size="50"
                    >mdi-alert-circle-outline</v-icon
                  >
                  <h2 class="text-center montserrat font-weight-bold">
                    This page has expired!
                  </h2>
                </v-layout>
              </v-col>
            </v-row>
          </v-container>
        </div>

        <Footer
          id="footer"
          :isMobileDevice="isMobileDevice"
          :dark="dark"
          :drawer="drawer"
          :windowWidth="windowWidth"
        >
        </Footer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db, auth } from "@/main";
import store from "@/store/index";
// import Footer from "../Footer";
import Footer from "../FooterPressLaunch";
const dateformat = require("dateformat");
// import {
//   doc,
//   updateDoc,
//   getDoc,
//   collection,
//   getDocs,
//   query,
//   where,
//   setDoc,
// } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";
// import {
//   signInWithPhoneNumber,
//   RecaptchaVerifier,
//   updateProfile,
//   updateEmail,
// } from "firebase/auth";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
// import {ref, set } from "firebase/database";

export default {
  name: "SuccessGojek",
  props: {
    model: String,
    dark: Boolean,
    isMobileDevice: Boolean,
    bikesImage: String,
    close: Function,
    windowWidth: Number,
    drawer: Boolean,
  },
  data: () => {
    return {
      successGojek: true,
      logoSrcDark: "",
      //Initial
      showView: 0,
      orderId: 0,
      bikeName: "",
      invoiceAmount: 0,
      uid: "",
      loadingData: false,
      lang: store.state.lang,
      currentUser: null,
      //Posted Data
      name: "",
      phone: "",
      email: "",
      city_zip: "",
      shippingMethod: "",
      date: new Date().toISOString().substr(0, 10),
      loginDialog: false,
      phoneNo: "",
      SMSsent: false,
      otp: "",
      wrongOtp: false,
      alertDialog: false,
      alertStatus: false,
      alertText: "",
      countDown: 10,
      isloggedIn: false,
      invoiceData: {},
      orderData: {},
      listColor: {
        Black: "#000",
        White: "#fff",
      },
      anoaImage: "/img/shared/bikes/anoa/anoa.jpg",
      maleoImage: "/img/shared/bikes/maleo/maleo.jpg",
      rimauImage: "/img/shared/bikes/rimau/rimau.jpg",
      customerType: "",
      subscriptionPlanList: {
        "3 Months Plan": "3 Bulan",
        "6 Months Plan": "6 Bulan",
        "9 Months Plan": "9 Bulan",
        "12 Months Plan": "12 Bulan",
      },
    };
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getPlatform() {
      return this.$store.state.device.platform;
    },
    getUser() {
      return this.$store.state.user;
    },
    getSubscription() {
      return this.$store.state.subscription;
    },
    getCities() {
      return this.$store.state.cities;
    },
    getInvoice() {
      return this.$store.getters.getInvoice;
    },
  },
  components: {
    Footer,
  },
  watch: {
    // "$route.params": "checkSlug",
  },
  created() {
    this.logoSrcDark = "./img/logo/charged_logo.png";
    this.orderData = this.getInvoice;
    console.log("LOAD_INVOICE", this.orderData);
    this.bikeName = this.orderData.bikeType;
    this.showView = 1;
    if (!this.orderData.invoice) {
      this.$router.push("/");
    }
    setTimeout(() => {
      this.currentUser = auth.currentUser;
      if (this.currentUser) {
        console.log("####### Welcome App User #######");
        this.checkSlug();
        console.log("saveuderdata");
        setTimeout(() => {
          this.saveUserData();
        }, 1000);
        this.isloggedIn = true;
        return;
      } else {
        this.initReCaptcha();
      }
    }, 1000);
    // const user = this.$store.state.user;
    //   console.log(user);
    // this.customerType = this.$store.state.user.customerType;
    // console.log("customerType", this.customerType);
    // if (this.customerType == "driver") {
    //   store.commit("SetLanguage", {
    //     language: (this.getLanguage = "id"),
    //   });
    //   console.log("berubah indo");
    // }
  },
  methods: {
    //Initialize
    checkSlug() {
      var obj = this.$route.params;
      const isEmpty = Object.keys(obj).length === 0;

      if (!isEmpty) {
        this.orderId = this.$route.params.inv_id;
        this.getInvoiceRecord();
      } else {
        this.showView = 2;
      }
    },
    submitDocument() {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSfjUMfdjdPU_qKJesDXpqbMklFH82O3AbTulInAV-ISz0tOZw/viewform?vc=0&c=0&w=1&flr=0&usp=mail_form_link",
        "_blank"
      );
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
      if (this.countDown == 0) {
        this.submitDocument();
        this.isloggedIn = true;
      }
    },
    closeAlertDialog() {
      this.alertDialog = false;
    },
    change1() {
      this.alert = false;
    },
    async addUsers() {
      // const orderRef = collection(db, "users");
      // const q = query(
      //   orderRef,
      //   where("phoneNumber", "==", this.orderData.customer.phoneNumber)
      // );
      // const querySnapshot = await getDocs(q);
      // if (querySnapshot.docs.length == 0) {
      //   this.currentUser = auth.currentUser;
      //   console.log(auth.currentUser.uid);
      //   var newPostKey = doc(collection(db, "users")).id;
      //   console.log("newPostKey", doc(collection(db, "users")).id);
      //   this.userData = querySnapshot?.docs[0]?.data() ?? null;
      //   console.log(this.userData);
      //   await setDoc(doc(db, "users", newPostKey), {
      //     givenNames: this.orderData.customer.givenNames,
      //     address: this.orderData.customer.address,
      //     phoneNumber: this.orderData.customer.phoneNumber,
      //     email: this.orderData.customer.email,
      //     uid: this.userData?.uid ?? auth.currentUser.uid,
      //     customerType: this.orderData.customer.customerType,
      //   });
      //   console.log("uyuyuyuyuy");
      //   console.log(auth.currentUser);
      //   if (this.currentUser?.displayName == null) {
      //     updateProfile(this.currentUser, {
      //       displayName: this.orderData.customer.givenNames,
      //     })
      //       .then(() => {
      //         console.log("Name updated!");
      //       })
      //       .catch((error) => {
      //         console.log(error);
      //         // An error occurred
      //         // ...
      //       });
      //   }
      //   if (this.currentUser?.customerType == null) {
      //     updateProfile(this.currentUser, {
      //       customerType: this.orderData.customer.customerType,
      //     })
      //       .then(() => {
      //         console.log("Customer type updated!");
      //       })
      //       .catch((error) => {
      //         console.log(error);
      //         // An error occurred
      //         // ...
      //       });
      //   }
      //   if (this.currentUser?.email == null) {
      //     updateEmail(this.currentUser, this.orderData.customer.email)
      //       .then(() => {
      //         console.log("Email updated!");
      //         // Email updated!
      //         // ...
      //       })
      //       .catch((error) => {
      //         console.log(error);
      //         // An error occurred
      //         // ...
      //       });
      //   }
      // }
      // if (querySnapshot.docs.length != 0) {
      //   this.currentUser = auth.currentUser;
      //   console.log("uyuyuyuyuy");
      //   console.log(auth.currentUser);
      //   if (this.currentUser?.displayName == null) {
      //     updateProfile(this.currentUser, {
      //       displayName: this.orderData.customer.givenNames,
      //     })
      //       .then(() => {
      //         console.log("Profile updated!");
      //       })
      //       .catch((error) => {
      //         console.log(error);
      //         // An error occurred
      //         // ...
      //       });
      //   }
      //   if (this.currentUser?.customerType == null) {
      //     updateProfile(this.currentUser, {
      //       customerType: this.orderData.customer.customerType,
      //     })
      //       .then(() => {
      //         console.log("Profile updated!");
      //       })
      //       .catch((error) => {
      //         console.log(error);
      //         // An error occurred
      //         // ...
      //       });
      //   }
      //   if (this.currentUser?.email == null) {
      //     updateEmail(this.currentUser, this.orderData.customer.email)
      //       .then(() => {
      //         console.log("Email updated!");
      //         // Email updated!
      //         // ...
      //       })
      //       .catch((error) => {
      //         console.log(error);
      //         // An error occurred
      //         // ...
      //       });
      //   }
      //   if (this.currentUser?.uid == null) {
      //     this.userData = querySnapshot.docs[0].data();
      //     updateProfile(this.currentUser, {
      //       uid: this.userData.uid,
      //     })
      //       .then(() => {
      //         console.log("Profile updated!");
      //       })
      //       .catch((error) => {
      //         console.log(error);
      //         // An error occurred
      //         // ...
      //       });
      //   }
      // }
    },
    async saveUserData() {
      await this.addUsers();
      const orderRef = doc(db, "order", this.orderId);
      await updateDoc(orderRef, {
        userId: auth.currentUser.uid,
      });
    },
    verifyOtp() {
      this.loadingData = true;
      if (this.otp.length !== 6) {
        console.log("Invalid SMS code format!");
        this.alert = true;
      } else {
        //
        this.loader = "loading";
        this.alert = false;
        // let vm = this;
        let code = this.otp;
        //
        window.confirmationResult
          .confirm(code)
          .then(async () => {
            // User signed in successfully.
            // route to set Display name, email and password
            // vm.$router.push("/signupnext");
            // this.alertDialog = true;

            this.alertStatus = "success";
            this.alertText = "Done!";
            this.loadingData = false;
            this.loginDialog = false;
            this.countDownTimer();
            await this.addUsers();
            const orderRef = doc(db, "order", this.orderId);
            await updateDoc(orderRef, {
              userId: auth.currentUser.uid,
            });
            this.showView = 1;
          })
          .catch((error) => {
            // User couldn't sign in (bad verification code?)
            if (error) {
              // Handle error
              this.alertDialog = true;
              this.alertStatus = "failed";
              this.alertText = "Wrong OTP!";
              this.loadingData = false;
              console.log(error);
              this.alert = true;
              this.loader = null;
              this.loading = false;
            }
          });
      }
    },
    initReCaptcha() {
      setTimeout(() => {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: () => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // ...
            },
            "expired-callback": () => {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            },
          },
          auth
        );

        this.checkSlug();
        this.appVerifier = window.recaptchaVerifier;
      }, 1000);
    },
    async sendOtp(phoneNo) {
      if (phoneNo == null) {
        alert("Invalid Phone Number Format !");
      } else {
        //
        // let countryCode = this.countryCode
        // this.loader = "loading";
        // this.phoneNoValid = true;
        // this.alert = false;
        // this.SMSsent = false;
        //
        let appVerifier = this.appVerifier;

        await signInWithPhoneNumber(auth, phoneNo, appVerifier)
          .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            //
            console.log("SMS sent");
            // hide phoneNo field, show code fields
            this.SMSsent = true;
            // this.loader = null;
            // this.loading = false;
          })
          .catch((error) => {
            // Error; SMS not sent
            if (error) {
              console.log("Error ! SMS not sent");
              console.log(error);
              // this.alert = true;
              // this.loader = null;
              // this.loading = false;
            }
          });
      }
    },
    async getInvoiceRecord() {
      // const docSnap = doc(db, "order", this.orderId);
      // const docRef = await getDoc(docSnap);
      // if (docRef.exists) {
      //   console.log("Document data:", docRef);
      //   this.orderData = docRef.data();
      //   console.log("orderData", this.orderData);
      //   console.log("123123123");
      //   console.log("booking", this.orderData.shippingMethod);
      //   console.log(this.orderData.subscriptionDetail.bikeType);
      //   this.bikeName = this.orderData.subscriptionDetail.bikeType;
      //   this.phoneNo = this.orderData.customer.phoneNumber;
      //   this.shippingMethod = this.orderData.shippingMethod;
      //   // const invoiceSnap = query(
      //   //   collection(db, "invoice"),
      //   //   where("orderId", "==", this.orderData.orderId)
      //   // ); //, where("issuedFor", "==", "Booking"));
      //   // const invoiceRef = await getDocs(invoiceSnap);
      //   // this.invoiceData = 666;
      //   // console.log(this.invoiceData);
      //   // console.log(this.invoiceData.amount);
      //   this.showView = 1;
      //   if (this.currentUser == null) {
      //     // this.loginDialog = true;
      //     let phoneNumber = this.orderData.customer.phoneNumber;
      //     if (phoneNumber.startsWith(0)) {
      //       phoneNumber = "+62" + phoneNumber.slice(1);
      //     }
      //     // this.sendOtp(phoneNumber);
      //   } else {
      //     const orderRef = doc(db, "order", this.orderId);
      //     await updateDoc(orderRef, {
      //       userId: auth.currentUser.uid,
      //     });
      //   }
      // } else {
      //   console.log("No such document!");
      //   this.showView = 2;
      //   return;
      // }
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    makeDate(date) {
      return dateformat(new Date(date), "dd mmm, yyyy");
    },
    viewMaps() {
      if (this.shippingMethod === "Cikupa")
        window.open(
          "https://www.google.com/maps/dir//Pt,+Trivesta+Polymas+Perkasa+Pt.,+Jl.+Bhumimas+IV+No.8,+Talaga,+Cikupa,+Tangerang+Regency,+Banten+15710/@-6.2118741,106.4404619,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x2e42017e956dcac9:0xaf4ee447e8264c5a!2m2!1d106.5105585!2d-6.2118909",
          "_blank"
        );
      if (this.shippingMethod === "Kemang")
        window.open(
          "https://www.google.com/maps/dir/-6.3455488,106.7302227/charged+kemang+location/@-6.3026226,106.7061965,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x2e69f3d47daeaef5:0x5d4ca7413af66878!2m2!1d106.8232131!2d-6.255506",
          "_blank"
        );
    },
    printSubscriptionPlan() {
      this.orderData.subscriptionPlan;
    },
  },
};
</script>
<style>
.invTitle {
  color: #000000;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;
}

.otp {
  width: 200px;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.otp-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  align-items: center;
}

.succes-icon {
  font-size: 36px !important;
  color: #4fbd6d !important;
}

.failed-icon {
  font-size: 36px !important;
  color: #cb3a31 !important;
}

.booking-fee-sub-title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: 1rem 0;
}

.booking-fee-sub-title-countdown {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: 1rem 0;
  color: #cb3a31 !important;
}

.booking-fee-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.booking-fee-info {
  font-size: 16px;
  font-weight: 400;
  margin: 1rem 0;
}

.booking-fee-info-2 {
  font-size: 14px;
  font-weight: 400;
  margin: 1rem 0;
}

.booking-fee-icon {
  color: white !important;
}

.booking-fee-body {
  margin: 1rem 0;
}

.booking-fee-mini-card {
  margin: 1rem 0;
}

.booking-fee-bike {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.item-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #262626;
}
.item-label {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #858688;
}
.item-value {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #535456;
}
.shipping-label {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #535456;
}
</style>
