<template>
  <v-row>
    <v-col cols="12" :sm="windowWidth < 770 ? 12 : 7">
      <v-card flat mb-4>
        <v-layout style="width:100%">
          <v-layout column>
            <span class="font-weight-medium">Name on Debit/Credit Card</span>
            <v-text-field
              style="width:100%"
              outlined
              dense
              v-model="NAME_ON_CARD"
            >
            </v-text-field>
          </v-layout>
        </v-layout>
        <v-layout style="width:100%">
          <v-layout column>
            <span class="font-weight-medium">Debit/Credit Card Number *</span>
            <v-text-field
              style="width:100%"
              outlined
              dense
              :rules="[rules.cardNumber]"
              v-model="CARD_NUMBER"
              maxLength="16"
            >
            </v-text-field>
          </v-layout>
        </v-layout>
        <v-layout style="width:100%">
          <v-layout column>
            <span class="font-weight-medium">Expiry Date *</span>
            <v-text-field
              style="width:90%"
              outlined
              dense
              :rules="[rules.expDate]"
              maxlength="5"
              v-model="EXP_DATE"
            >
            </v-text-field>
          </v-layout>
          <v-layout column>
            <span class="font-weight-medium">CVV *</span>
            <v-text-field
              type="password"
              maxlength="3"
              style="width:100%"
              outlined
              :rules="[rules.cvv]"
              dense
              v-model="CVN"
            >
            </v-text-field>
          </v-layout>
        </v-layout>

        <v-layout style="width:100%">
          <v-layout>
            <v-btn
              style="width:100%;color:#FFF;text-transform: none !important;"
              color="#6B4693"
              @click="submit"
            >
              Continue to Payment
            </v-btn>
          </v-layout>
        </v-layout>
      </v-card>
    </v-col>
    <v-dialog eager v-model="openPayment">
      <v-card>
        <!-- HERE -->
        <iframe
          id="ifrm"
          :src="paymentLink"
          width="1000"
          height="1000"
        ></iframe>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import XenditService from "xendit";
export default {
  name: "Xendit",
  props: {
    dark: Boolean,
    windowWidth: Number,
    drawer: Boolean,
    amount: String,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
  },
  data() {
    return {
      lang: this.$store.state.lang,
      NAME_ON_CARD: "",
      CVN: "",
      EXP_DATE: "",
      MON: "",
      YEAR: "",
      CARD_NUMBER: "",
      cardMessage: "",
      expMessage: "",
      cvvMessage: "",
      openPayment: false,
      paymentLink: "",
      rules: {
        cardNumber: (v) => this.checkCardNumber(v) === true || this.cardMessage,
        expDate: (v) => this.checkExpDate(v) === true || this.expMessage,
        cvv: (v) => this.checkCVV(v) === true || this.cvvMessage,
      },
    };
  },
  mounted() {},
  watch: {},
  components: {},
  created() {
    this.checkXendit();
  },
  methods: {
    checkXendit() {
      XenditService.setPublishableKey(
        "xnd_public_development_omSnXqLbFV4LFcUxG3gTLDdNJ0UqQGXw3xR0BXS5cLPFrBbNrqq0rPV4gP8LCb"
      );
    },
    checkCardNumber(v) {
      if (v === "") {
        this.cardMessage = "Card Number cannot be empty";
        return false;
      }
      if (v.length !== 16) {
        this.cardMessage = "Card Number should be 16 digits";
        return false;
      }
      this.cardMessage = "Card Number is not valid";
      return XenditService.card.validateCardNumber(v);
    },
    checkExpDate(v) {
      let mon = -1;
      let year = -1;
      if (v === "") {
        this.expDate = "Expire Date cannot be empty";
        return false;
      }
      if (v.length !== 5) {
        this.expDate = "Expire Date should be 5 digits";
        return false;
      }
      if (v.includes("/") === false) {
        this.expDate = "Expire Date should contains / (MM/YY)";
        return false;
      }

      let exps = v.split("/");
      if (!isNaN(exps[0])) {
        mon = Math.floor(exps[0]);
      } else {
        this.expMessage = "Month is Not Valid";
        return false;
      }

      if (mon < 1 || mon > 12) {
        this.expMessage = "Month is Not Valid";
        return false;
      }

      this.MON = mon < 10 ? 0 + mon.toString() : mon.toString();
      console.log(this.MON);

      if (!isNaN(exps[1])) {
        year = Math.floor(exps[1]) + 2000;
        this.YEAR = year;
      } else {
        this.expMessage = "Year is Not Valid";
        return false;
      }

      this.expMessage = "Expired Date is not valid";
      return XenditService.card.validateExpiry(mon, year);
    },
    checkCVV(v) {
      if (v.length === "") {
        this.cvvMessage = "CVV cannot be empty";
        return false;
      }
      if (v.length < 3) {
        this.cvvMessage = "CVV should be 3 digits";
        return false;
      }
      this.cvvMessage = "CVV is not valid";
      return XenditService.card.validateCvn(v);
    },
    submit() {
      console.log("submit");
      XenditService.card.createToken(
        {
          amount: 5000,
          card_number: this.CARD_NUMBER,
          card_exp_month: this.MON.toString(),
          card_exp_year: this.YEAR.toString(),
          card_cvn: this.CVN,
          is_multiple_use: true,
          should_authenticate: true,
        },
        (err, creditCardToken) => {
          console.log(err);
          console.log(creditCardToken);
          this.xenditResponseHandler(err, creditCardToken);
        }
      );
    },
    xenditResponseHandler(err, creditCardToken) {
      if (err) {
        // Show the errors on the form
        console.log(err.message);
        return;
      }

      if (creditCardToken.status === "VERIFIED") {
        // Get the token ID:
        var token = creditCardToken.id;
        console.log(token);
        //Input Token to Firebase
      } else if (creditCardToken.status === "IN_REVIEW") {
        this.paymentLink = creditCardToken.payer_authentication_url;
        this.openPayment = true;
        // window.open(
        //   creditCardToken.payer_authentication_url,
        //   "sample-inline-frame"
        // );
      } else if (creditCardToken.status === "FAILED") {
        console.log(creditCardToken.failure_reason);
        // Re-enable submission
      }
    },
  },
};
</script>

<style scoped>
.montserrat {
  font-family: "Montserrat";
}
</style>
