<template>
  <v-container pa-0 fluid>
    <v-sheet
      class="mx-auto px-md-5 pb-12 pb-md-16"
      max-width="100%"
      tile
      :color="dark ? '#262626' : '#262626'"
      :dark="!dark"
      style="width: 100%; height: auto"
    >
      <v-layout
        column
        :style="
          windowWidth < 768
            ? 'margin-bottom:11px;margin-left:80px;'
            : 'margin-bottom:11px;margin-right:80px;margin-left:80px'
        "
      >
        <p style="margin-top: 50px"></p>

        <v-row>
          <v-col style="padding: 0px" cols="12" sm="12">
            <v-layout pt-4 :column="windowWidth < 800" align-center>
              <v-col
                :style="windowWidth < 800 ? 'max-width:100%' : ''"
                cols="12"
                sm="3"
              >
                <v-img
                  style="cursor: pointer"
                  @click="routerGo('/')"
                  :src="dark ? logoSrcDark : logoSrc"
                  max-width="150"
                  max-height="33"
                ></v-img>
                <p style="margin-top: 20px"></p>
                <v-layout pt-4>
                  <v-icon
                    color="#FFF"
                    @click.stop="openLink('https://facebook.com/chargedasia/')"
                    >mdi-facebook</v-icon
                  >
                  <v-icon
                    color="#FFF"
                    @click.stop="
                      openLink(
                        'https://www.linkedin.com/company/pt-industri-charged-mobilitas/'
                      )
                    "
                    style="margin-left: 20px"
                    >mdi-linkedin</v-icon
                  >
                  <v-icon
                    color="#FFF"
                    @click.stop="openLink('https://twitter.com/ChargedAsia/')"
                    style="margin-left: 20px"
                    >mdi-twitter</v-icon
                  >
                  <v-icon
                    color="#FFF"
                    @click.stop="
                      openLink('https://www.instagram.com/charged.asia/')
                    "
                    style="margin-left: 20px"
                    >mdi-instagram</v-icon
                  >
                  <!-- <v-img @click.stop="gotoLink('https://www.tiktok.com/@charged')" :src="dark ? srcTikTokWhite : srcTikTok" style="margin-left:20px"
                    max-width="24" max-height="24"></v-img> -->
                  <!-- <v-icon @click.stop="gotoLink('https://github.com')" style="margin-left:20px">mdi-github</v-icon> -->
                </v-layout>
                <p style="margin-top: 50px"></p>
              </v-col>

              <v-col
                :style="windowWidth < 800 ? 'max-width:100%' : ''"
                cols="12"
                sm="3"
              >
                <p style="font-size: 18px" class="font-weight-normal">
                  SHOWROOM
                </p>
                <span
                  style="font-size: 14px; color: #c1c2c4"
                  class="font-weight-thin"
                  >Jl. Kemang Utara VII No. 21B,</span
                ><br />
                <span
                  style="font-size: 14px; color: #c1c2c4"
                  class="font-weight-thin"
                  >Mampang Prapatan, Jakarta Selatan,</span
                ><br />
                <span
                  style="font-size: 14px; color: #c1c2c4"
                  class="font-weight-thin"
                  >DKI Jakarta 12730</span
                >
                <br />
                <span style="font-size: 14px" class="font-weight-thin"
                  ><a
                    href="tel:+621239060070"
                    style="color: #c1c2c4; text-decoration: unset"
                    >+62 123 906 0070</a
                  ></span
                >
              </v-col>

              <v-col
                :style="windowWidth < 800 ? 'max-width:100%' : ''"
                cols="12"
                sm="2"
              >
                <p style="font-size: 18px" class="font-weight-normal">
                  QUICK LINKS
                </p>
                <span
                  style="font-size: 16px; color: #c1c2c4; cursor: pointer"
                  class="font-weight-normal"
                  >{{ lang[getLanguage].ABOUT_US }}</span
                ><br />
                <span
                  style="font-size: 16px; color: #c1c2c4; cursor: pointer"
                  class="font-weight-normal"
                  @click="routerGo('/contact')"
                  >{{ lang[getLanguage].CONTACT_US }}</span
                ><br /><br /><br />
                <!-- <span style="font-size:16px" class="font-weight-medium"
                  >Going Electric</span
                ><br />
                <span
                  style="font-size:16px;cursor:pointer"
                  class="font-weight-medium"
                  @click="routerGo('/careers/home')"
                  >Careers</span> -->
              </v-col>

              <v-col
                :style="windowWidth < 800 ? 'max-width:100%' : ''"
                cols="12"
                sm="2"
              >
                <p style="font-size: 18px" class="font-weight-normal">MODELS</p>
                <!-- <span style="font-size:16px;cursor:pointer" class="font-weight-medium" @click="routerGo('/bikes/home')">CUX</span><br> -->
                <span
                  style="font-size: 16px; color: #c1c2c4; cursor: pointer"
                  class="font-weight-normal"
                  @click="routerGo('/bike/maleo')"
                  >MALEO</span
                ><br />
                <span
                  style="font-size: 16px; color: #c1c2c4; cursor: pointer"
                  class="font-weight-normal"
                  @click="routerGo('/bike/rimau')"
                  >RIMAU</span
                ><br />
                <span
                  style="font-size: 16px; color: #c1c2c4; cursor: pointer"
                  class="font-weight-normal"
                  @click="routerGo('/bike/anoa')"
                  >ANOA</span
                ><br /><br />
              </v-col>

              <v-col
                :style="windowWidth < 800 ? 'max-width:100%' : ''"
                cols="12"
                sm="2"
              >
                <p style="font-size: 18px" class="font-weight-normal">LEGAL</p>
                <v-btn
                  text
                  class="no-background-hover font-weight-normal"
                  style="
                    text-transform: none !important;
                    font-size: 16px;
                    color: #c1c2c4;
                    cursor: pointer;
                  "
                  @click="openPrivacy"
                >
                  {{ lang[getLanguage].ACC_APP_PRIVACY }}</v-btn
                >
                <v-btn
                  text
                  class="no-background-hover font-weight-normal"
                  style="
                    text-transform: none !important;
                    font-size: 16px;
                    color: #c1c2c4;
                    cursor: pointer;
                  "
                  @click="openTerms"
                >
                  {{ lang[getLanguage].ACC_APP_TERMS_OF_USE }}</v-btn
                >
                <br /><br />
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-layout>

      <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
        <v-icon color="green">mdi-check-circle-outline</v-icon>
        <span style="margin-left: 5px">{{ snackbarText }}</span>
        <v-btn text color="#388E3C" @click.native="snackbar = false"
          >Close</v-btn
        >
      </v-snackbar>
    </v-sheet>

    <!-- Bottom Footer -->
    <v-card
      color="#353638"
      elevation="0"
      class="px-md-9 pb-10 pb-md-0"
      style="border-radius: unset"
    >
      <v-row class="mx-0 py-3 charged-h5-body px-md-16 text-over-image-white">
        <v-col cols="12" md="6">
          <span>
            <v-btn
              text
              plain
              style="text-transform: none !important"
              :color="getLanguage === 'en' ? 'white' : '#C1C2C4'"
              @click="changeLanguage('en')"
              >English</v-btn
            >
          </span>
          <span>
            <v-btn
              text
              plain
              style="text-transform: none !important"
              :color="getLanguage === 'id' ? 'white' : '#C1C2C4'"
              @click="changeLanguage('id')"
              >Indonesia</v-btn
            >
          </span>
        </v-col>
        <v-col
          cols="12"
          md="6"
          :style="
            'align-self: center; color: #FFF;' +
            (windowWidth <= 768 ? 'text-align:start' : 'text-align:end;')
          "
          :class="windowWidth <= 768 ? 'px-7' : ''"
        >
          <span>
            {{ new Date().getFullYear() }} &copy;Copyright PT Charged Tech
            Indonesia. All Rights Reserved!
          </span>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ChargedFooter",
  props: {
    dark: Boolean,
    drawer: Boolean,
    windowWidth: Number,
    windowHeight: Number,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
  },
  data() {
    return {
      lang: this.$store.state.lang,
      model: null,
      active: true,
      snackbar: false,
      snackbarText: "",
      logoSrcDark: "",
      logoSrc: "",
      logoSrcWalletDark: "",
      logoSrcWalletLight: "",
      logoSrcStexDark: "",
      logoSrcStexLight: "",
      logoSrcCGDark: "",
      logoSrcCGLight: "",
      logoSrcCMCDark: "",
      logoSrcCMCLight: "",
      logoSrcBCDark: "",
      logoSrcBCLight: "",
      srcTikTok: "",
      srcTikTokWhite: "",
      donateAddress: "0x770e725359cd9A3Cf34FEeb832A16969a8D21660",
    };
  },
  components: {},
  watch: {},
  created() {
    // this.windowWidth = this.$props.windowindowWidth
    this.logoSrc = "./img/logo/charged_logo_white.png";
    this.logoSrcDark = "./img/logo/charged_logo.png";
    this.logoSrcWalletDark = "./img/icons/my_wallet_color_dark.png";
    this.logoSrcWalletLight = "./img/icons/my_wallet_color_light.png";
    this.logoSrcStexDark = "./img/icons/stex_contact_dark.png";
    this.logoSrcStexLight = "./img/icons/stex_contact_light.png";
    this.logoSrcCGDark = "./img/icons/coingecko-dark.png";
    this.logoSrcCGLight = "./img/icons/coingecko-light.png";
    this.logoSrcCMCDark = "./img/icons/cmc_dark.png";
    this.logoSrcCMCLight = "./img/icons/cmc_light.png";
    this.logoSrcBCDark = "./img/icons/bitcourier_dark.png";
    this.logoSrcBCLight = "./img/icons/bitcourier_light.png";
    this.srcTikTok = "./img/icons/tiktok_grey.png";
    this.srcTikTokWhite = "./img/icons/tiktok_white.png";
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
    openLinkSameTab(link) {
      window.open(link);
    },
    openTerms() {
      this.$emit("termsClicked");
    },
    openPrivacy() {
      this.$emit("privacyClicked");
    },
    routerGo(route) {
      console.log("this.drawer = false");
      if (this.isMobileDevice) {
        console.log("this.drawer = false");
        setTimeout(() => {
          this.drawer = false;
        }, 500);
      }
      this.$router.push(route);
    },
    handleSuccess(e) {
      console.log(e);
      this.snackbarText = "Contract Address copied.";
      this.snackbar = true;
    },
    handleError(e) {
      console.log(e);
    },
  },
};
</script>

<style>
.contactText {
  font-family: "Montserrat";
  font-size: 44px;
}

.wrapper {
  background: #fafafa;
  display: flex;
  justify-content: center;
}

.sectionHeader {
  font-family: "Comfortaa";
  font-size: 30px;
  font-weight: 900;
  text-align: center;
}

.cardsH {
  background: #fff;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  grid-gap: 1rem;
  display: grid;
  grid-auto-flow: column;
  min-height: 300px;
  max-height: 350px;
}

.cardsV {
  background: #fff;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  grid-gap: 1rem;
  display: table-column;
  grid-auto-flow: column;
  min-height: 300px;
  max-height: 350px;
}

/* Desqy CSS */

span {
  font-family: "Work sans";
}

.no-background-hover::before {
  background-color: transparent !important;
}

/* END Desqy CSS */
</style>
