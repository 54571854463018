<template>
  <div>
    <!-- ################################# Start View Mobile ################################ -->
    <div class="mt-5 mb-32" v-if="$vuetify.breakpoint.mobile">
      <div class="flex items-center gap-3 px-4 justify-start">
        <v-btn icon color="green" @click="$parent.selectedMenu = null">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span
          class="font-work-sans text-lg font-semibold text-charged-grey-100"
        >
          {{ getLanguage == "en" ? "Account Settings" : "Pengaturan Akun" }}
        </span>
      </div>
      <div class="mt-6 text-center">
        <v-img
          class="rounded-full mx-auto"
          src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
          height="100"
          width="100"
        ></v-img>
        <v-btn disabled text class="capitalize mt-4">
          {{ getLanguage == "en" ? "Select Photo" : "Pilih Photo" }}
        </v-btn>
      </div>
      <div class="px-5 my-5">
        <p class="font-work-sans text-charged-grey-100 font-semibold">
          {{
            getLanguage == "en" ? "Personal Information" : "Informasi Pribadi"
          }}
        </p>
        <div class="flex items-center text-sm">
          <div class="w-36">
            <span>
              {{ getLanguage == "en" ? "Display Name" : "Nama Tampilan" }}
            </span>
          </div>
          <div>
            <span>{{ user?.full_name }}</span>
          </div>
          <div class="ml-auto w-5">
            <v-btn
              icon
              color="primary"
              right
              class="text-base font-medium capitalize"
              disabled
            >
              <v-icon style="color: #353638 !important"
                >mdi-chevron-right</v-icon
              >
            </v-btn>
          </div>
        </div>
        <div class="flex items-center text-sm">
          <div class="w-36">
            <span>
              {{ getLanguage == "en" ? "Date Of Birth" : "Tanggal Lahir" }}
            </span>
          </div>
          <div>
            <span>
              {{ formatDate(profile?.birth_date) }}
            </span>
          </div>
          <div class="ml-auto w-5">
            <v-btn
              icon
              color="primary"
              right
              class="text-base font-medium capitalize"
              disabled
            >
              <v-icon style="color: #353638 !important"
                >mdi-chevron-right</v-icon
              >
            </v-btn>
          </div>
        </div>
        <div class="flex items-center text-sm">
          <div class="w-36">
            <span>
              {{ getLanguage == "en" ? "Gender" : "Jenis Kelamin" }}
            </span>
          </div>
          <div>
            <span>
              {{ user?.gender ?? "-" }}
            </span>
          </div>
          <div class="ml-auto w-5">
            <v-btn
              icon
              color="primary"
              right
              class="text-base font-medium capitalize"
              disabled
            >
              <v-icon style="color: #353638 !important"
                >mdi-chevron-right</v-icon
              >
            </v-btn>
          </div>
        </div>
      </div>
      <span class="w-full h-2 bg-charged-grey-700 block"></span>
      <div class="px-5 my-5">
        <p class="font-work-sans text-charged-grey-100 font-semibold">
          {{
            getLanguage == "en" ? "Personal Information" : "Informasi Pribadi"
          }}
        </p>
        <div class="flex items-center text-sm">
          <div class="w-36">
            <span>
              {{ getLanguage == "en" ? "Phone Number" : "Nomor Telepon" }}
            </span>
          </div>
          <div>
            <span>{{ user?.phone_number }}</span>
          </div>
          <div class="ml-auto w-5">
            <v-btn
              icon
              color="primary"
              right
              class="text-base font-medium capitalize"
              disabled
            >
              <v-icon style="color: #353638 !important"
                >mdi-chevron-right</v-icon
              >
            </v-btn>
          </div>
        </div>
        <div class="flex items-center text-sm">
          <div class="w-36">
            <span>
              {{ getLanguage == "en" ? "Email Address" : "Alamat Email" }}
            </span>
          </div>
          <div>
            <span>
              {{ user?.email }}
            </span>
          </div>
          <div class="ml-auto w-5">
            <v-btn
              icon
              color="primary"
              right
              class="text-base font-medium capitalize"
              disabled
            >
              <v-icon style="color: #353638 !important"
                >mdi-chevron-right</v-icon
              >
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- ################################# End View Mobile ################################ -->

    <!-- ################################# Start View Desktop ################################ -->
    <div class="flex mt-10" v-if="$vuetify.breakpoint.lg">
      <!-- start sidebar -->
      <div>
        <v-card elevation="0" outlined max-width="300" class="rounded-lg">
          <v-card-text class="py-4 px-10">
            <v-img
              class="rounded-full mx-auto"
              src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
              height="200"
              width="200"
            ></v-img>
          </v-card-text>

          <v-card-text>
            <p>
              {{
                getLanguage == "en"
                  ? "Maximum size of file: 5 MB Please upload file in .JPG, .JPEG, or .PNG format."
                  : "Ukuran maksimum file: 5 MB Silakan unggah file dalam format .JPG, .JPEG, atau .PNG."
              }}
            </p>
          </v-card-text>
        </v-card>
      </div>
      <!-- end sidebar -->

      <!-- start content -->
      <div class="pl-8 w-full">
        <p class="font-montserrat text-2xl font-bold text-charged-grey-100">
          {{ getLanguage == "en" ? "Account Settings" : "Pengaturan Akun" }}
        </p>
        <div class="mt-10 font-work-sans text-base text-charged-grey-300">
          <p class="font-work-sans text-lg text-charged-grey-100 font-semibold">
            {{
              getLanguage == "en" ? "Personal Information" : "Informasi Pribadi"
            }}
          </p>
          <v-divider class="my-5"></v-divider>
          <div class="flex items-center">
            <div class="w-1/3">
              <span>
                {{ getLanguage == "en" ? "Display Name" : "Nama Tampilan" }}
              </span>
            </div>
            <div>
              <span>{{ user?.full_name }}</span>
            </div>
            <div class="ml-auto">
              <v-btn
                text
                color="primary"
                right
                class="text-base font-medium capitalize"
                disabled
              >
                {{ getLanguage == "en" ? "Edit" : "Ubah" }}
              </v-btn>
            </div>
          </div>
          <div class="flex items-center">
            <div class="w-1/3">
              <span>
                {{ getLanguage == "en" ? "Date Of Birth" : "Tanggal Lahir" }}
              </span>
            </div>
            <div>
              <span>
                {{ formatDate(profile?.birth_date) }}
              </span>
            </div>
            <div class="ml-auto">
              <v-btn
                text
                color="primary"
                right
                class="text-base font-medium capitalize"
                disabled
              >
                {{ getLanguage == "en" ? "Edit" : "Ubah" }}
              </v-btn>
            </div>
          </div>
          <div class="flex items-center">
            <div class="w-1/3">
              <span>
                {{ getLanguage == "en" ? "Gender" : "Jenis Kelamin" }}
              </span>
            </div>
            <div>
              <span>
                {{ user?.gender ?? "-" }}
              </span>
            </div>
            <div class="ml-auto">
              <v-btn
                text
                color="primary"
                right
                class="text-base font-medium capitalize"
                disabled
              >
                {{ getLanguage == "en" ? "Edit" : "Ubah" }}
              </v-btn>
            </div>
          </div>
        </div>

        <div class="mt-10 font-work-sans text-base text-charged-grey-300">
          <p class="font-work-sans text-lg text-charged-grey-100 font-semibold">
            {{ getLanguage == "en" ? "Contact" : "Kontak" }}
          </p>
          <v-divider class="my-5"></v-divider>
          <div class="flex items-center">
            <div class="w-1/3">
              <span>
                {{ getLanguage == "en" ? "Phone Number" : "Nomor Telepon" }}
              </span>
            </div>
            <div>
              <span>{{ user?.phone_number }}</span>
            </div>
            <div class="ml-auto">
              <v-btn
                text
                color="primary"
                right
                class="text-base font-medium capitalize"
                disabled
              >
                {{ getLanguage == "en" ? "Edit" : "Ubah" }}
              </v-btn>
            </div>
          </div>
          <div class="flex items-center">
            <div class="w-1/3">
              <span>
                {{ getLanguage == "en" ? "Email Address" : "Alamat Email" }}
              </span>
            </div>
            <div>
              <span>
                {{ user?.email }}
              </span>
            </div>
            <div class="ml-auto">
              <v-btn
                text
                color="primary"
                right
                class="text-base font-medium capitalize"
                disabled
              >
                {{ getLanguage == "en" ? "Edit" : "Ubah" }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <!-- end content -->
    </div>
    <!-- ################################# End View Desktop ################################ -->
  </div>
</template>

<script>
import { getProfileApi } from "../../../../services/back-end-ruby";
export default {
  name: "myaccount-settings",
  data() {
    return {
      lang: this.$store.state.lang,
      profile: null,
      user: null,
    };
  },

  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
  },

  async created() {
    await this.getProfile();
    console.log("FITURE VUETIFY", this);
  },

  methods: {
    log() {
      this.$router.push("/");
      this.$cookies.remove("token_api");
      window.location.reload();
    },
    async getProfile() {
      await getProfileApi(this.$cookies.get("token_api")).then((res) => {
        this.profile = res.data.user.profile;
        this.user = res.data.user;
        this.$parent.isLoader = false;
      });
    },
    formatDate(inputDate) {
      if (inputDate == null) return "";
      const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      // memisahkan kemudian mengambil setiap value pada tanggal yaitu tahum, bulan, dan hari
      const [year, month, day] = inputDate.split("-");
      // menjadikan urutan bulan sebagai index untuk mencari pada array months
      const formattedMonth = months[parseInt(month) - 1];
      // membuat format yang ingin di tampilkan. cuakss!!
      return `${parseInt(day)}, ${formattedMonth} ${year}`;
    },
  },
};
</script>