<template>
  <v-sheet fluid pa-0>
    <v-dialog v-model="captureEmailDialog" persistent max-width="500">
      <v-card>
        <v-layout>
          <v-spacer></v-spacer>
          <v-btn @click="captureEmailDialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-layout>
        <div v-if="emailSaved" class="mx-1">
          <v-layout>
            <v-spacer></v-spacer>
            <v-icon color="green">mdi-check-circle</v-icon>
            <v-spacer></v-spacer>
          </v-layout>

          <v-card-text
            class="no-location-submit-title"
            style="margin-top: -10px; padding-bottom: 0; margin-top: 1rem"
          >
            {{
              lang[getLanguage].PRODUCT_NO_LOCATION_SUBMIT_TITLE
            }}</v-card-text
          >
          <v-card-text class="no-location-submit-description pb-15">{{
            lang[getLanguage].PRODUCT_NO_LOCATION_SUBMIT_DESCRIPTION
          }}</v-card-text>
        </div>

        <div v-if="!emailSaved">
          <v-card-text
            class="title text-center"
            style="margin-top: -10px; padding-bottom: 0; margin-top: 1rem"
          >
            {{ lang[getLanguage].PRODUCT_NO_LOCATION_TITLE }}</v-card-text
          >

          <v-card-text class="subheading text-center">{{
            lang[getLanguage].PRODUCT_NO_LOCATION_DESCRIPTION
          }}</v-card-text>

          <div class="mx-5">
            <span class="input-label">{{
              lang[getLanguage].PRODUCT_NO_LOCATION_AREA_LABEL
            }}</span>
            <v-text-field
              outlined
              dense
              filled
              v-model="customerArea"
              :placeholder="
                lang[getLanguage].PRODUCT_NO_LOCATION_AREA_PLACEHOLDER
              "
            >
            </v-text-field>
          </div>

          <div class="mx-5 mb-2">
            <span class="input-label">{{
              lang[getLanguage].EMAIL_ADDRESS
            }}</span>
            <v-form v-model="valid" ref="form" style="width: 100%">
              <v-text-field
                outlined
                dense
                filled
                v-model="email"
                type="email"
                :rules="emailRules"
                :placeholder="lang[getLanguage].PRODUCT_NO_LOCATION_EMAIL"
                required
                @input="change1"
                @change="change1"
              >
              </v-text-field>
            </v-form>
          </div>

          <v-card-actions class="mx-3">
            <v-btn
              color="#6B4693"
              @click="registerLeadEmail"
              :disabled="!dataProvided || customerArea == ''"
              class="mb-15"
              :style="
                windowWidth < 770
                  ? 'width:100%;color:#FFF;text-transform: none !important;'
                  : 'width:100%;color:#FFF;text-transform: none !important;'
              "
            >
              {{ lang[getLanguage].PRODUCT_NO_LOCATION_EMAIL_SUBMIT }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <DialogCheckServiceAreVue
      ref="dialogCheckServiceArea"
      :bikeName="bikeName"
      :dark="dark"
      :drawer="drawer"
      :isMobileDevice="isMobileDevice"
      :windowWidth="windowWidth"
    ></DialogCheckServiceAreVue>

    <!-- Banner Hero & footer version Dekstop -->
    <v-img
      v-show="$vuetify.breakpoint.lgAndUp"
      id="product-hero"
      :src="bikeHero"
      :position="'center top'"
    >
      <v-container>
        <v-row align="center" justify="center">
          <v-col class="banner-container" cols="12" md="5">
            <v-img class="bike-logo" :src="bikeLogo" :width="'380px'"></v-img>

            <div class="color-option-container ml-0 w-full p-5">
              <span style="text-transform: uppercase" class="font-semibold">
                {{ lang[getLanguage].COLOR_OPTION_TEXT }}
              </span>
              <span class="mx-10">:</span>
              <template v-if="bike.colorOptions">
                <div
                  v-for="color in bike.colorOptions"
                  :key="color"
                  class="color-palette"
                  :style="{ backgroundColor: color }"
                ></div>
              </template>
            </div>

            <v-row v-if="bike.isRelease" class="banner-footer">
              <v-col class="banner-footer-info">
                <div class="flex justify-center items-center">
                  <div>
                    <span
                      class="banner-footer-info-title font-work-sans font-semibold block"
                      :style="
                        getLanguage === 'id'
                          ? 'font-size: 18px !important; letter-spacing: -0.36px;'
                          : 'font-size: 18px !important; letter-spacing: -0.36px;'
                      "
                    >
                      {{ bike.text.otr[getLanguage] }} (Jakarta)
                    </span>
                    <span class="text-2xl font-bold mr-3">
                      {{ bike.price }}
                    </span>
                    <span class="text-decoration-line-through text-gray-800">
                      {{ bike.actualPrice }}
                    </span>
                    <span class="banner-footer-info-subtitle text-base block">
                      {{
                        getLanguage == "en"
                          ? "Include 1 Battery + 1 Standard Charger"
                          : "Termasuk 1 Baterai + 1 Pengisi Daya Standar"
                      }}
                    </span>
                  </div>
                  <v-divider class="mx-9 bg-white" vertical></v-divider>
                  <div>
                    <a
                      :href="
                        getLanguage === 'en'
                          ? 'https://api.whatsapp.com/send?phone=6282221188028&text=Hi%2C%20I%27m%20interested%20in%20buying%20' +
                            bike.name +
                            '%20electric%20motorcycle.%20How%20can%20I%20proceed%20%3F'
                          : 'https://api.whatsapp.com/send?phone=6282221188028&text=Halo%2C%20saya%20tertarik%20untuk%20membeli%20motor%20' +
                            bike.name +
                            '.%20Bagaimana%20caranya%20%3F'
                      "
                      target="_blank"
                      style="
                        text-decoration: none;
                        display: inline-block;
                        width: 200px !important;
                      "
                    >
                      <span
                        class="font-work-sans text-base py-3 px-6 bg-charged-purple-300 rounded-lg text-white w-full inline-block text-center hover:bg-charged-purple-400 duration-300"
                        style="letter-spacing: -0.32px"
                      >
                        {{ lang[getLanguage].PRODUCT_SUBSCRIBE_BUY_NOW }}
                      </span>
                    </a>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row v-else justify="center" class="banner-footer">
              <v-col class="banner-footer-info" cols="6">
                <div class="flex items-center">
                  <span
                    class="text-white font-montserrat text-4xl font-bold text-no-wrap mr-8 uppercase"
                  >
                    {{ lang[getLanguage].TITLE_COMMINGSOON }}
                  </span>
                  <span class="text-white font-work-sans">
                    {{ lang[getLanguage].SUBTITLE_COMMINGSOON }}
                  </span>
                </div>
              </v-col>
              <v-col cols="2">
                <v-btn
                  color="primary"
                  style="height: 48px; font-size: 16px"
                  class="capitalize font-work-sans text-base font-medium ml-5"
                  @click="gotoFormInterested"
                >
                  {{ lang[getLanguage].BUT_INTERESTED }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-img>

    <!-- Banner Horo version Mobile -->
    <v-img
      v-show="$vuetify.breakpoint.mdAndDown"
      id="product-hero"
      :src="bikeHeroMobile"
      :height="'100%'"
      :position="'center top'"
    >
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" md="5">
            <v-card-title
              style="color: #c1c2c4"
              class="text-h1 pl-0 montserrat font-weight-bold mb-12 pb-6 pr-0"
            >
              <v-img :src="bikeLogo" :width="'100%'" class="mx-auto"></v-img>
            </v-card-title>
          </v-col>
        </v-row>
      </v-container>
    </v-img>

    <!-- footer buy and subscribe in mobile -->
    <div
      class=""
      style="background: #262626; padding: 50px 0"
      v-show="$vuetify.breakpoint.mdAndDown"
    >
      <div class="banner-footer-info-mobile" v-if="bike.isRelease">
        <span
          class="font-work-sans text-xl font-semibold inline-block mb-1"
          style="letter-spacing: -0.4px"
        >
          {{ bike.text.otr[getLanguage] }} (Jakarta)
        </span>
        <br />
        <span class="text-2xl font-bold mr-3">
          {{ bike.price }}
        </span>
        <span class="text-decoration-line-through text-gray-500">
          {{ bike.actualPrice }}
        </span>
        <span class="banner-footer-info-subtitle text-base block">
          {{
            getLanguage == "en"
              ? "Include 1 Battery + 1 Standard Charger"
              : "Termasuk 1 Baterai + 1 Pengisi Daya Standar"
          }}
        </span>
        <a
          :href="
            getLanguage === 'en'
              ? 'https://api.whatsapp.com/send?phone=6282221188028&text=Hi%2C%20I%27m%20interested%20in%20buying%20' +
                bike.name +
                '%20electric%20motorcycle.%20How%20can%20I%20proceed%20%3F'
              : 'https://api.whatsapp.com/send?phone=6282221188028&text=Halo%2C%20saya%20tertarik%20untuk%20membeli%20motor%20' +
                bike.name +
                '.%20Bagaimana%20caranya%20%3F'
          "
          target="_blank"
        >
          <v-btn
            color="primary"
            class="work-sans mb-4"
            style="
              color: rgb(255, 255, 255);
              height: 45px;
              text-transform: initial;
              font-size: 16px;
              border-radius: 8px;
              margin-bottom: 0px !important;
              width: 90%;
              margin-top: 12px;
              letter-spacing: -0.32px;
              /* margin-left: 2rem; */
            "
            >{{ lang[getLanguage].PRODUCT_SUBSCRIBE_BUY_NOW }}</v-btn
          >
        </a>
      </div>

      <div class="banner-footer-info-mobile" v-else>
        <v-container>
          <v-row justify="center">
            <v-col cols="12">
              <span
                class="text-white font-montserrat text-1xl md:text-4xl font-bold text-no-wrap uppercase block"
              >
                {{ lang[getLanguage].TITLE_COMMINGSOON }}
              </span>
              <span class="text-white font-work-sans">
                {{ lang[getLanguage].SUBTITLE_COMMINGSOON }}
              </span>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                style="height: 48px"
                class="capitalize font-work-sans text-base font-medium inline-block"
                block
                @click="gotoFormInterested"
              >
                {{ lang[getLanguage].BUT_INTERESTED }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <section id="product-advantages">
      <!-- Dekstop Version -->
      <v-container
        v-if="bike.isRelease"
        v-show="$vuetify.breakpoint.lgAndUp"
        class="mx-auto my-16 px-28"
      >
        <v-row align="center" justify="center" class="px-2.5">
          <v-col cols="12">
            <v-card-title
              class="pl-0 montserrat font-weight-bold text-4xl text-charged-grey-100"
              style="letter-spacing: -1.08px"
            >
              {{ bike.productTagLine[getLanguage].title }}
            </v-card-title>

            <v-card-text class="pl-0 mt-4" style="padding-bottom: 72px">
              <p
                class="work-sans font-normal"
                style="
                  font-size: 24px;
                  color: #353638;
                  line-height: 32px;
                  letter-spacing: -0.48px;
                "
              >
                {{ bike.productTagLine[getLanguage].desctiption }}
              </p>
            </v-card-text>

            <!-- TABS -->
            <v-tabs grow v-model="tab">
              <v-tabs-slider></v-tabs-slider>

              <v-tab
                href="#tab-1"
                :class="
                  tab == 'tab-1'
                    ? 'work-sans font-semibold'
                    : 'work-sans border-b border-charged-grey-400'
                "
                style="
                  font-size: 18px;
                  text-transform: unset;
                  letter-spacing: -0.36px;
                "
              >
                {{ lang[getLanguage].SAFETY }}
              </v-tab>

              <v-tab
                href="#tab-2"
                :class="
                  tab == 'tab-2'
                    ? 'work-sans font-semibold'
                    : 'work-sans border-b border-charged-grey-400'
                "
                style="
                  font-size: 18px;
                  text-transform: unset;
                  letter-spacing: -0.36px;
                "
              >
                {{ lang[getLanguage].CONVENIENCE }}
              </v-tab>

              <v-tab
                href="#tab-3"
                :class="
                  tab == 'tab-3'
                    ? 'work-sans font-semibold capitalize'
                    : 'work-sans capitalize border-b border-charged-grey-400'
                "
                style="
                  font-size: 18px;
                  text-transform: unset;
                  letter-spacing: -0.36px;
                "
              >
                {{ lang[getLanguage].INTELLIGENCE }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <v-row align="center" justify="center" class="pa-4 ma-2">
                  <v-col cols="12" md="6" class="work-sans">
                    <div
                      class="text-charged-grey-200 mb-8"
                      v-html="bike.performanceAndSafetyDesc[getLanguage]"
                    ></div>
                    <v-btn
                      color="primary"
                      class="work-sans mb-4 font-medium"
                      style="
                        color: #fff;
                        font-size: 16px;
                        border-radius: 8px;
                        text-transform: initial;
                        padding: 12px 24px;
                        letter-spacing: -0.32px;
                        height: 48px;
                      "
                      @click="gotoFormInterestedRelease"
                    >
                      {{ bike.text?.btnInterested[getLanguage] }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-img :src="bikeTab1" max-height="25%"></v-img>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-row align="center" justify="center" class="pa-4 ma-2">
                  <v-col cols="12" md="6" class="work-sans">
                    <div
                      class="text-charged-grey-200 mb-8"
                      v-html="bike.convenienceDesc[getLanguage]"
                    ></div>
                    <v-btn
                      color="primary"
                      class="work-sans mb-4 font-medium"
                      style="
                        color: #fff;
                        font-size: 16px;
                        border-radius: 8px;
                        text-transform: initial;
                        padding: 12px 24px;
                        letter-spacing: -0.32px;
                        height: 48px;
                      "
                      @click="gotoFormInterestedRelease"
                    >
                      {{ bike.text?.btnInterested[getLanguage] }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-img :src="bikeTab2" max-height="25%"></v-img>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item value="tab-3">
                <v-row align="center" justify="center" class="pa-4 ma-2">
                  <v-col cols="12" md="6" class="work-sans">
                    <div
                      class="text-charged-grey-200 mb-8"
                      v-html="bike.intelegencyDesc[getLanguage]"
                    ></div>
                    <v-btn
                      color="primary"
                      class="work-sans mb-4 font-medium"
                      style="
                        color: #fff;
                        font-size: 16px;
                        border-radius: 8px;
                        text-transform: initial;
                        padding: 12px 24px;
                        letter-spacing: -0.32px;
                        height: 48px;
                      "
                      @click="gotoFormInterestedRelease"
                    >
                      {{ bike.text?.btnInterested[getLanguage] }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-img :src="bikeTab3" max-height="25%"></v-img>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
            <!-- END TABS -->
          </v-col>
        </v-row>
      </v-container>

      <!-- Mobile Version -->
      <v-container
        v-if="bike.isRelease"
        v-show="$vuetify.breakpoint.mdAndDown"
        class="my-16"
      >
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card-title
              class="pl-0 montserrat font-weight-bold"
              style="
                font-size: 28px;
                letter-spacing: -0.025em;
                width: 88%;
                word-break: break-word;
              "
            >
              {{ bike.productTagLine[getLanguage].title }}
            </v-card-title>

            <v-card-text class="pl-0 mt-4">
              <p
                class="work-sans"
                style="font-size: 18px; color: #353638; letter-spacing: -0.02em"
              >
                {{ bike.productTagLine[getLanguage].desctiption }}
              </p>
            </v-card-text>

            <!-- TABS -->
            <div class="faq-question">
              <input id="q1" type="checkbox" class="panel" />
              <!-- <div class="plus" style="right: 15px">+</div> -->
              <label
                for="q1"
                href="#tab-1"
                class="work-sans panel-title relative"
              >
                <span class="-ml-3 font-semibold">
                  {{ lang[getLanguage].SAFETY }}
                </span>
                <span class="btn-toggle-collaps-product"></span>
              </label>
              <div
                class="work-sans panel-content w-full px-0"
                style="margin: 0px !important"
              >
                <v-col cols="12" md="6" class="px-0">
                  <v-img :src="bikeTab1" max-height="25%"></v-img>
                </v-col>
                <v-col cols="12" md="6" class="work-sans mb-2 px-0">
                  <div
                    class="text-charged-grey-200 mb-8"
                    style="letter-spacing: -0.32px"
                    v-html="bike.performanceAndSafetyDesc[getLanguage]"
                  ></div>
                </v-col>
                <v-btn
                  color="primary"
                  class="work-sans mb-4 font-medium"
                  style="
                    color: #fff;
                    font-size: 16px;
                    border-radius: 8px;
                    text-transform: initial;
                    padding: 12px 24px;
                    letter-spacing: -0.32px;
                    height: 48px;
                  "
                  @click="gotoFormInterestedRelease"
                >
                  {{ bike.text?.btnInterested[getLanguage] }}
                </v-btn>
              </div>
            </div>

            <div class="faq-question">
              <input id="q2" type="checkbox" class="panel" />
              <!-- <div class="plus" style="right: 15px">+</div> -->
              <label
                for="q2"
                href="#tab-2"
                class="work-sans panel-title relative"
              >
                <span class="-ml-3 font-semibold">
                  {{ lang[getLanguage].CONVENIENCE }}
                </span>
                <span class="btn-toggle-collaps-product"></span>
              </label>
              <div
                class="work-sans panel-content px-0"
                style="margin: 0px !important"
              >
                <v-col cols="12" md="6" class="px-0">
                  <v-img :src="bikeTab2" max-height="25%"></v-img>
                </v-col>
                <v-col cols="12" md="6" class="work-sans mb-2 px-0">
                  <div
                    class="text-charged-grey-200 mb-8"
                    style="letter-spacing: -0.32px"
                    v-html="bike.convenienceDesc[getLanguage]"
                  ></div>
                </v-col>
                <v-btn
                  color="primary"
                  class="work-sans mb-4 font-medium"
                  style="
                    color: #fff;
                    font-size: 16px;
                    border-radius: 8px;
                    text-transform: initial;
                    padding: 12px 24px;
                    letter-spacing: -0.32px;
                    height: 48px;
                  "
                  @click="gotoFormInterestedRelease"
                >
                  {{ bike.text?.btnInterested[getLanguage] }}
                </v-btn>
              </div>
            </div>

            <div class="faq-question">
              <input id="q3" type="checkbox" class="panel" />
              <!-- <div class="plus" style="right: 15px">+</div> -->
              <label
                for="q3"
                href="#tab-3"
                class="work-sans panel-title relative"
              >
                <span class="-ml-3 font-semibold">
                  {{ lang[getLanguage].INTELLIGENCE }}
                </span>
                <span class="btn-toggle-collaps-product"></span>
              </label>
              <div
                class="work-sans panel-content px-0"
                style="margin: 0px !important"
              >
                <v-col cols="12" md="6" class="px-0">
                  <v-img :src="bikeTab3" max-height="25%"></v-img>
                </v-col>
                <v-col cols="12" md="6" class="work-sans mb-2 px-0">
                  <div
                    class="text-charged-grey-200 mb-8"
                    style="letter-spacing: -0.32px"
                    v-html="bike.intelegencyDesc[getLanguage]"
                  ></div>
                </v-col>
                <v-btn
                  color="primary"
                  class="work-sans mb-4 font-medium"
                  style="
                    color: #fff;
                    font-size: 16px;
                    border-radius: 8px;
                    text-transform: initial;
                    padding: 12px 24px;
                    letter-spacing: -0.32px;
                    height: 48px;
                  "
                  @click="gotoFormInterestedRelease"
                >
                  {{ bike.text?.btnInterested[getLanguage] }}
                </v-btn>
              </div>
            </div>
            <!-- END TABS -->
          </v-col>
        </v-row>
      </v-container>

      <v-container v-else class="mx-auto md:my-16">
        <span class="md:hidden my-10 block"></span>
        <div class="md:pl-28 md:py-24">
          <v-row align="center" justify="start">
            <v-col cols="12" md="6">
              <span
                class="text-charged-grey-200 font-montserrat text-xl md:text-2xl font-semibold mb-12 block"
                style="letter-spacing: -0.6px"
                v-html="bike?.quotes[getLanguage]"
              ></span>
              <h1
                class="font-montserrat font-bold text-1xl md:text-4xl text-charged-grey-100"
              >
                {{ bike?.quotesForm }}
              </h1>
              <p class="font-work-sans text-lg text-charged-grey-200">
                {{ bike?.assignQuotes }}
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <img
                :src="'/img/shared/bikes/' + bike.nameLowercase + '/artis.png'"
                :alt="bike.name"
              />
            </v-col>
          </v-row>
        </div>
        <span class="md:hidden my-10 block"></span>
      </v-container>

      <!-- Section image Product -->
      <div v-if="!bike.isRelease">
        <img
          :src="'/img/shared/bikes/' + bike.nameLowercase + '/product.png'"
          :alt="bike.name"
          style="background-position: center; background-size: cover"
          width="100%"
        />
      </div>

      <!-- Section Image Design Description -->
      <div
        v-if="bike?.productInspirationDesign"
        class="md:py-40 md:px-28 overflow-hidden box-border"
      >
        <span class="md:hidden my-16 block"></span>
        <v-row class="px-4">
          <v-col cols="12" md="6">
            <v-carousel hide-delimiters height="100%">
              <v-carousel-item
                v-for="(item, i) in 5"
                :key="i"
                width="100%"
                max-width="500px"
                class="mx-auto"
              >
                <v-sheet height="100%">
                  <div class="d-flex fill-height justify-center align-center">
                    <img
                      :src="
                        '/img/shared/bikes/' +
                        bikeName +
                        '/design-' +
                        (i + 1) +
                        '.png'
                      "
                      width="100%"
                      :alt="bike.name"
                    />
                  </div>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-col>
          <v-col cols="12" md="6">
            <h1
              class="text-charged-grey-100 font-montserrat font-bold text-1xl md:text-4xl mb-8"
            >
              {{ bike?.productInspirationDesign[getLanguage]?.title }}
            </h1>
            <span
              class="font-work-sans text-lg md:text-xl text-charged-grey-200"
              v-html="bike?.productInspirationDesign[getLanguage]?.desc"
            >
            </span>
          </v-col>
        </v-row>
        <span class="md:hidden my-16 block"></span>
      </div>
    </section>

    <section id="detail-specification">
      <div class="bg-charged-grey-100 w-full px-4 py-16 box-border">
        <div class="md:py-14 md:px-20">
          <v-row class="md:px-20">
            <!-- Heading -->
            <v-col cols="12" md="12">
              <v-card-title
                style="
                  color: #858688;
                  line-height: 32px;
                  letter-spacing: -0.6px;
                  font-weight: 700;
                "
                class="pl-0 pr-0 montserrat text-uppercase justify-center text-xl md:text-2xl"
              >
                {{ lang[getLanguage].PRODUCT_SPECIFICATION_TITLE }}
              </v-card-title>
            </v-col>

            <!-- 2 ROW DATA -->
            <v-col
              cols="12"
              md="6"
              class="md:border-r md:border-charged-grey-500"
            >
              <v-card-title
                style="
                  color: #858688;
                  font-size: 18px;
                  line-height: 26px;
                  letter-spacing: -0.36px;
                  font-weight: 600 !important;
                "
                class="pl-0 font-work-sans font-semibold font-weight-bold text-uppercase justify-center"
              >
                {{ lang[getLanguage].PRODUCT_SPEED_TITLE }}
              </v-card-title>
              <v-card-title class="justify-center">
                <p
                  class="montserrat font-weight-bold text-fs-52 md:text-fs-68"
                  style="color: white; letter-spacing: -1.56px"
                >
                  {{ bike.topSpeed }}
                </p>
                <small
                  class="work-sans ml-3 text-lg"
                  style="color: #858688; letter-spacing: -0.36px"
                >
                  {{ lang[getLanguage].PRODUCT_SPEED_QUANTITY }}
                </small>
              </v-card-title>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-title class="justify-center">
                <p
                  style="
                    color: #858688;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.36px;
                    font-weight: 600 !important;
                  "
                  class="pl-0 mb-0 font-work-sans font-semibold font-weight-bold text-uppercase"
                >
                  {{ lang[getLanguage].PRODUCT_DISTANCE_TITLE }}
                </p>
                <small
                  v-if="bike.battery > 1"
                  class="work-sans uppercase font-normal"
                  style="color: #858688; font-size: 14px"
                >
                  &nbsp; ({{ bike.battery }}
                  {{ lang[getLanguage].BATTERIES }})</small
                >
              </v-card-title>
              <v-card-title class="justify-center">
                <p
                  class="montserrat font-weight-bold text-fs-52 md:text-fs-68"
                  style="color: white; letter-spacing: -1.56px"
                >
                  {{ bike.maximumRange }}
                </p>
                <small
                  class="work-sans ml-3 text-lg"
                  style="color: #858688; letter-spacing: -0.36px"
                >
                  {{ lang[getLanguage].PRODUCT_DISTANCE_QUANTITY }}
                </small>
              </v-card-title>
            </v-col>

            <!-- Mini Text -->
            <v-col cols="12" md="12">
              <v-card-title
                style="
                  color: #858688;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: -0.24px;
                  font-weight: 400;
                "
                class="px-0 work-sans justify-center"
              >
                <span
                  class="hidden md:table text-center"
                  v-html="
                    formattedWrapText(
                      bike.specificationDesc[getLanguage],
                      getLanguage == 'en' ? 'Your results' : 'Hasil mungkin'
                    )
                  "
                ></span>
                <span class="md:hidden">
                  {{ bike.specificationDesc[getLanguage] }}
                </span>
              </v-card-title>
            </v-col>
          </v-row>

          <!-- dekstop -->
          <div class="hidden md:table w-full mt-24">
            <!-- Technical Specification Dekstop -->
            <div class="w-full pb-10">
              <v-tabs
                vertical
                v-model="tab2"
                background-color="#262626"
                color="white"
                class="px-16 py-4 flex justify-between custom-table-hover"
              >
                <v-tabs-slider color="white"></v-tabs-slider>

                <div class="flex gap-x-36">
                  <div class="w-full">
                    <v-tab
                      href="#tab2-1"
                      class="work-sans"
                      style="
                        color: white;
                        text-transform: unset;
                        font-size: 18px;
                        letter-spacing: -0.02em;
                      "
                    >
                      <span
                        :class="
                          tab2 == 'tab2-1'
                            ? 'font-semibold'
                            : 'font-normal text-charged-grey-500'
                        "
                      >
                        {{ lang[getLanguage].KEY_NUMBERS }}
                      </span>
                      <v-spacer></v-spacer>
                      <v-icon
                        right
                        color="white"
                        :style="
                          tab2 == 'tab2-1'
                            ? 'color: white'
                            : 'color: #C1C2C4 !important'
                        "
                      >
                        mdi-chevron-right
                      </v-icon>
                    </v-tab>

                    <v-tab
                      href="#tab2-2"
                      class="work-sans"
                      style="
                        color: white;
                        text-transform: unset;
                        font-size: 18px;
                        letter-spacing: -0.02em;
                      "
                    >
                      <span
                        :class="
                          tab2 == 'tab2-2'
                            ? 'font-semibold'
                            : 'font-normal text-charged-grey-500'
                        "
                      >
                        {{ lang[getLanguage].POWERTRAIN }}
                      </span>
                      <v-spacer></v-spacer>
                      <v-icon
                        right
                        color="white"
                        :style="
                          tab2 == 'tab2-2'
                            ? 'color: white'
                            : 'color: #C1C2C4 !important'
                        "
                      >
                        mdi-chevron-right
                      </v-icon>
                    </v-tab>

                    <v-tab
                      href="#tab2-3"
                      class="work-sans"
                      style="
                        color: white;
                        text-transform: unset;
                        font-size: 18px;
                        letter-spacing: -0.02em;
                      "
                    >
                      <span
                        :class="
                          tab2 == 'tab2-3'
                            ? 'font-semibold'
                            : 'font-normal text-charged-grey-500'
                        "
                      >
                        {{ lang[getLanguage].VEHICLE_SPECIFICATION }}
                      </span>
                      <v-spacer></v-spacer>
                      <v-icon
                        right
                        color="white"
                        :style="
                          tab2 == 'tab2-3'
                            ? 'color: white'
                            : 'color: #C1C2C4 !important'
                        "
                      >
                        mdi-chevron-right
                      </v-icon>
                    </v-tab>
                  </div>
                  <div class="w-full">
                    <v-tabs-items
                      class="mr-0"
                      v-model="tab2"
                      style="background-color: #262626"
                    >
                      <v-tab-item
                        value="tab2-1"
                        class="work-sans"
                        style="color: white; text-align: -webkit-center"
                      >
                        <v-simple-table
                          dark
                          style="
                            background-color: #262626;
                            color: white;
                            width: 707px;
                          "
                        >
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="item in bike.specification.keys[
                                  getLanguage
                                ]"
                                :key="item.description"
                              >
                                <td class="text-left font-semibold">
                                  {{ item.description }}
                                </td>
                                <td class="text-right">{{ item.details }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-tab-item>
                      <v-tab-item
                        value="tab2-2"
                        class="work-sans"
                        style="color: white; text-align: -webkit-center"
                      >
                        <v-simple-table
                          dark
                          style="
                            background-color: #262626;
                            color: white;
                            width: 707px;
                          "
                        >
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="item in bike.specification.powertrain[
                                  getLanguage
                                ]"
                                :key="item.description"
                              >
                                <td class="text-left font-semibold">
                                  {{ item.description }}
                                </td>
                                <td class="text-right">{{ item.details }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-tab-item>
                      <v-tab-item
                        value="tab2-3"
                        class="work-sans"
                        style="color: white; text-align: -webkit-center"
                      >
                        <v-simple-table
                          dark
                          style="
                            background-color: #262626;
                            color: white;
                            width: 707px;
                          "
                        >
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="item in bike.specification.other[
                                  getLanguage
                                ]"
                                :key="item.description"
                              >
                                <td class="text-left font-semibold">
                                  {{ item.description }}
                                </td>
                                <td class="text-right">{{ item.details }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                </div>
              </v-tabs>
            </div>
            <!-- button unduh brosur in Dekstop -->
            <v-row
              v-if="bike.isRelease"
              class="flex justify-center mt-14 w-full mx-auto"
            >
              <span
                class="text-white py-3 px-6 border border-white rounded-lg cursor-pointer hover:bg-white hover:bg-opacity-10"
                style="height: 48px"
                @click.stop="openBrochureBike"
              >
                <v-icon
                  color="#fff"
                  style="margin-right: 0.5rem; font-size: 20px"
                  >mdi-tray-arrow-down</v-icon
                >
                {{
                  getLanguage == "en"
                    ? "Download " + bike.name + " E-Brosur 2024"
                    : "Unduh " + bike.name + " E-Brosur 2024"
                }}
              </span>
            </v-row>
            <!-- END Technical Specification Dekstop -->
          </div>

          <!-- Mobile -->
          <div class="md:hidden">
            <!-- Technical Specification Mobile -->
            <v-container class="px-0" style="padding: 0px !important">
              <div class="faq-question">
                <input id="q4" type="checkbox" class="panel" />
                <!-- <div class="plus" style="color: white; right: 17px">+</div> -->
                <label
                  for="q4"
                  href="#tab2-1"
                  class="work-sans panel-title relative"
                  style="color: white; text-align: initial"
                >
                  <span class="-ml-3 font-semibold">
                    {{ lang[getLanguage].KEY_NUMBERS }}
                  </span>
                  <span class="btn-toggle-collaps-product-tab-2"></span>
                </label>
                <div
                  class="work-sans panel-content"
                  style="margin: 0px !important"
                  v-for="item in bike.specification.keys[getLanguage]"
                  :key="item.description"
                >
                  <v-container class="pl-0">
                    <v-row style="flex-direction: column">
                      <span
                        class="text-left font-semibold"
                        style="color: white"
                      >
                        {{ item.description }}
                      </span>
                      <span class="text-left font-normal" style="color: white">
                        {{ item.details }}
                      </span>
                    </v-row>
                  </v-container>
                </div>
              </div>

              <div class="faq-question">
                <input id="q5" type="checkbox" class="panel" />
                <!-- <div class="plus" style="color: white; right: 17px">+</div> -->
                <label
                  for="q5"
                  href="#tab2-1"
                  class="work-sans panel-title relative"
                  style="color: white; text-align: initial"
                >
                  <span class="-ml-3 font-semibold">
                    {{ lang[getLanguage].POWERTRAIN }}
                  </span>
                  <span class="btn-toggle-collaps-product-tab-2"></span>
                </label>
                <div
                  class="work-sans panel-content"
                  style="margin: 0px !important"
                  v-for="item in bike.specification.powertrain[getLanguage]"
                  :key="item.description"
                >
                  <v-container class="pl-0">
                    <v-row style="flex-direction: column">
                      <span
                        class="text-left font-semibold"
                        style="color: white"
                        >{{ item.description }}</span
                      >
                      <span
                        class="text-left font-normal"
                        style="color: #c1c2c4"
                        >{{ item.details }}</span
                      >
                    </v-row>
                  </v-container>
                </div>
              </div>

              <div class="faq-question">
                <input id="q6" type="checkbox" class="panel" />
                <label
                  for="q6"
                  href="#tab2-1"
                  class="work-sans panel-title relative"
                  style="color: white; text-align: initial"
                >
                  <span class="-ml-3 font-semibold">
                    {{ lang[getLanguage].VEHICLE_SPECIFICATION }}
                  </span>
                  <span class="btn-toggle-collaps-product-tab-2"></span>
                </label>
                <div
                  class="work-sans panel-content"
                  style="margin: 0px !important"
                  v-for="item in bike.specification.other[getLanguage]"
                  :key="item.description"
                >
                  <v-container class="pl-0">
                    <v-row style="flex-direction: column">
                      <span
                        class="text-left font-semibold"
                        style="color: white"
                        >{{ item.description }}</span
                      >
                      <span
                        class="text-left font-normal"
                        style="color: #c1c2c4"
                        >{{ item.details }}</span
                      >
                    </v-row>
                  </v-container>
                </div>
              </div>
            </v-container>

            <!-- button download -->
            <div
              v-if="bike.isRelease"
              class="mt-16 md:mt-10 flex justify-center"
            >
              <v-btn
                outlined
                class="work-sans"
                style="
                  color: #fff;
                  border-radius: 8px !important;
                  text-transform: initial;
                  width: 100%;
                  padding: 20px 10px 20px 10px !important;
                  height: 48px;
                "
                @click.stop="openBrochureBike"
              >
                <v-icon
                  color="#fff"
                  style="margin-right: 0.5rem; font-size: 20px"
                  >mdi-tray-arrow-down
                </v-icon>
                {{
                  getLanguage == "en"
                    ? "Download " + bike.name + " E-Brosur 2024"
                    : "Unduh " + bike.name + " E-Brosur 2024"
                }}
              </v-btn>
            </div>
            <!-- END Technical Specification Mobile -->
          </div>
        </div>
      </div>
    </section>

    <!-- Version Dekstop -->
    <!-- <div class="hidden md:flex justify-center">
      <v-img
        class="hidden md:table"
        id="product-hero"
        :src="bikeProduct"
        :position="'center top'"
      />
    </div> -->

    <!-- Version Mobile & Desktop -->
    <div v-if="bike.isRelease" class="px-5">
      <div class="flex flex-wrap md:px-24 mt-9 pb-5 items-center">
        <div class="w-full md:w-1/2 px-10">
          <v-img :src="bikeProductRowOne" />
        </div>
        <div class="w-full md:w-1/2 flex flex-wrap h-32 md:h-full md:p-10">
          <div class="w-1/2 md:w-full">
            <v-img
              :height="$vuetify.breakpoint.mdAndDown ? '200' : ''"
              :src="bikeProductRowTwo"
              position="center"
              contain
            />
          </div>
          <div class="w-1/2 md:w-full my-auto flex">
            <v-img
              :height="$vuetify.breakpoint.mdAndDown ? '120' : ''"
              class="my-auto inline-block w-1/2"
              :src="bikeProductRowThreeLeft"
              position="center"
              contain
            />
            <v-img
              :height="$vuetify.breakpoint.mdAndDown ? '120' : ''"
              class="my-auto inline-block w-1/2"
              :src="bikeProductRowThreeRight"
              position="center"
              contain
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="bike.isRelease" class="pt-20 pb-6 px-4">
      <div
        class="flex flex-wrap justify-between md:pt-10 md:pb-28 md:px-20 items-center"
      >
        <div class="w-full md:w-3/5 mb-8">
          <v-img
            :src="bikeSideEmblem"
            :width="$vuetify.breakpoint.mdAndDown ? '238.929px' : '457.49px'"
            class="mx-auto"
          ></v-img>
          <div class="flex justify-center mb-10 flex-wrap items-center">
            <span
              style="text-transform: uppercase"
              class="font-semibold hidden md:inline-block"
            >
              {{ lang[getLanguage].COLOR_OPTION_TEXT }}
            </span>
            <span class="mx-10 mb-2 md:mb-0 hidden md:inline-block">:</span>
            <div class="d-flex" v-if="bike.colorOptionsDes">
              <div
                v-for="color in bike.colorOptionsDes"
                :key="color.name"
                class="mx-5"
              >
                <div
                  class="color-palette mx-auto"
                  :style="{ backgroundColor: color.hex }"
                ></div>
                <span
                  class="w-full whitespace-nowrap text-center relative top-2 text-sm text-gray-40 md:block hidden"
                >
                  {{ color.name }}
                </span>
              </div>
            </div>
          </div>
          <span
            class="font-work-sans text-xs text-charged-grey-400 flex md:items-center"
            style="letter-spacing: -0.24px"
          >
            <v-icon
              color="#858688"
              class="pr-3 pb-3 pt-3"
              style="font-size: 20px"
              >mdi-alert-circle-outline</v-icon
            >
            {{ lang[getLanguage].PRODUCT_SUBSCRIBE_DESCRIPTION }}
          </span>
        </div>
        <div class="w-full my-auto lg:w-1/3">
          <v-card-title class="pl-0">
            <small
              class="work-sans text-xs md:text-base"
              style="
                color: #353638;
                font-weight: 400;
                margin-right: 0.5rem;
                letter-spacing: -0.24px;
              "
            >
              {{ bike.text.otr[getLanguage] }}
            </small>
          </v-card-title>
          <v-card-text class="pl-0 flex items-center gap-3">
            <p
              class="montserrat font-weight-bold text-charged-grey-200 text-2xl md:text-4xl"
              style="
                margin-bottom: 0 !important;
                letter-spacing: -1.08px;
                letter-spacing: -0.6px;
              "
            >
              {{ bike.price }}
            </p>
            <small
              v-if="bike.actualPrice"
              class="text-decoration-line-through text-lg font-normal"
            >
              {{ bike.actualPrice }}
            </small>
          </v-card-text>
          <v-card-text
            class="work-sans pl-0 pt-2 text-xs md:text-base"
            style="color: #858688"
          >
            {{ bike.priceDescription[getLanguage] }}
          </v-card-text>

          <v-divider class="my-2" style="border-top-width: 2px"></v-divider>

          <v-card-text
            class="work-sans pb-4 pl-0 text-charged-grey-200"
            style="font-size: 18px; font-weight: 500; letter-spacing: -0.32px"
          >
            {{ bike.text.includeInTheOtrPrice[getLanguage] }}
          </v-card-text>

          <div
            v-for="include in bike?.text?.includeInTheOtrDescription[
              getLanguage
            ]"
            :key="include.name"
          >
            <v-card-text
              class="work-sans pb-2 pl-0 text-charged-grey-200"
              style="
                font-size: 20px;
                font-weight: 600;
                padding: 0 !important;
                text-transform: capitalize;
              "
            >
              <v-icon color="green">mdi-check-circle</v-icon>
              {{ include?.name }}
            </v-card-text>
            <v-card-text class="work-sans pl-8 pt-2" style="color: #858688">
              {{ include?.description }}
            </v-card-text>
          </div>

          <v-divider
            class="mt-2 mb-6"
            style="border-top-width: 2px"
          ></v-divider>

          <!-- START FORM -->
          <span
            class="font-work-sans text-charged-grey-200 text-base md:text-lg font-medium block md:leading-3"
            v-html="lang[getLanguage].SUGEST_COMMINGSOON_FORM"
          >
          </span>

          <v-form
            v-model="formValidInterest"
            ref="form_interested_validation"
            lazy-validation
          >
            <v-row class="mt-5">
              <v-col cols="12" md="12" class="py-0">
                <span class="personal-input-title mb-1 block">
                  {{ lang[getLanguage].LABEL_FULL_NAME }} *
                </span>
                <v-text-field
                  v-model="nameUserInterest"
                  :rules="nameRules"
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  :placeholder="lang[getLanguage].PLACEHOLDER_NAME"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" class="py-0">
                <span class="personal-input-title mb-1 block">
                  {{ lang[getLanguage].LABEL_EMAIL }} *
                </span>
                <v-text-field
                  v-model="emailUserInterest"
                  :rules="emailRules"
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  :placeholder="lang[getLanguage].PLACEHOLDER_EMAIL"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <span class="personal-input-title mb-1 block">
                  {{ lang[getLanguage].LABEL_PHONE }} *
                </span>
                <v-text-field
                  v-model="phoneUserInterest"
                  :rules="phoneRules"
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  :placeholder="lang[getLanguage].PLACEHOLDER_PHONE"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-btn
              block
              class="capitalize font-work-sans font-medium text-base rounded-lg mt-5"
              color="primary"
              style="height: 48px; font-size: 16px !important"
              @click="submitFormInterest"
              :disabled="disableBtnSubmitInterest"
            >
              {{ lang[getLanguage].BUT_SUBMIT }}
            </v-btn>

            <span
              class="text-center font-work-sans text-xs text-charged-grey-400 block mt-4"
            >
              {{ lang[getLanguage].INFO_SUMIT_COMMINGSOON_FORM }}
            </span>
          </v-form>
          <div ref="form_interested_release"></div>
          <!-- END FORM -->
        </div>
      </div>
    </div>

    <!-- Section Form interested -->
    <section
      ref="form_interested"
      v-if="!bike.isRelease"
      class="overflow-hidden box-border"
    >
      <div class="px-2 md:px-4 my-16 mx-auto">
        <v-row justify="space-between" class="md:px-16 items-center">
          <v-col cols="12" md="7">
            <v-carousel hide-delimiters height="">
              <v-carousel-item
                v-for="(item, index) in 2"
                :key="index"
                class="mx-auto"
              >
                <v-sheet height="100%">
                  <div class="d-flex fill-height justify-center align-center">
                    <img
                      :src="
                        '/img/shared/bikes/' +
                        bikeName +
                        '/slide-' +
                        (index + 1) +
                        '.png'
                      "
                      width="100%"
                      style="max-width: 680px"
                      :alt="bike.name"
                    />
                  </div>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
            <div class="flex items-center mt-8">
              <div class="mr-2">
                <v-icon
                  :style="
                    windowWidth > 770 ? 'font-size: 24px;' : 'font-size: 20px;'
                  "
                  start
                  >mdi-information-outline</v-icon
                >
              </div>
              <div>
                <span
                  class="font-work-sans text-xs text-charged-grey-400 block"
                  :style="
                    windowWidth > 770
                      ? ''
                      : 'letter-spacing: -0.24px; line-height: 1.2rem;'
                  "
                >
                  {{ lang[getLanguage].PRODUCT_SUBSCRIBE_DESCRIPTION }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="5">
            <div class="flex md:block items-center gap-3">
              <p
                class="text-charged-grey-200 font-work-sans text-xs md:text-base"
              >
                {{ lang[getLanguage].LIMITED_EDITION }}
              </p>
              <p
                class="font-montserrat text-charged-grey-200 font-bold text-2xl md:text-4xl"
              >
                {{ lang[getLanguage].TITLE_COMMINGSOON }}
              </p>
            </div>
            <p
              class="font-work-sans text-charged-grey-200 font-medium text-sm md:text-base"
            >
              {{ lang[getLanguage].INFO_AVAILABLE_PURCHASE }}
            </p>
            <p
              class="font-work-sans text-charged-grey-400 text-xs md:text-sm"
              style="line-height: 1.2rem"
            >
              {{ lang[getLanguage].SUBTITLE_COMMINGSOON_FORM }}
            </p>
            <v-divider class="mb-5 mt-5" :thickness="2"></v-divider>
            <span
              class="font-work-sans text-charged-grey-200 text-base md:text-lg font-medium block md:leading-3"
              v-html="lang[getLanguage].SUGEST_COMMINGSOON_FORM"
            >
            </span>

            <v-form
              v-model="formValidInterest"
              ref="form_interested_validation"
              lazy-validation
            >
              <v-row class="mt-5">
                <v-col cols="12" md="12" class="py-0">
                  <span class="personal-input-title mb-1 block">
                    {{ lang[getLanguage].LABEL_FULL_NAME }} *
                  </span>
                  <v-text-field
                    v-model="nameUserInterest"
                    :rules="nameRules"
                    class="custom-d-input custom-combobox-d"
                    style="width: 100%"
                    outlined
                    dense
                    filled
                    :placeholder="lang[getLanguage].PLACEHOLDER_NAME"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6" class="py-0">
                  <span class="personal-input-title mb-1 block">
                    {{ lang[getLanguage].LABEL_EMAIL }} *
                  </span>
                  <v-text-field
                    v-model="emailUserInterest"
                    :rules="emailRules"
                    class="custom-d-input custom-combobox-d"
                    style="width: 100%"
                    outlined
                    dense
                    filled
                    :placeholder="lang[getLanguage].PLACEHOLDER_EMAIL"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <span class="personal-input-title mb-1 block">
                    {{ lang[getLanguage].LABEL_PHONE }} *
                  </span>
                  <v-text-field
                    v-model="phoneUserInterest"
                    :rules="phoneRules"
                    class="custom-d-input custom-combobox-d"
                    style="width: 100%"
                    outlined
                    dense
                    filled
                    :placeholder="lang[getLanguage].PLACEHOLDER_PHONE"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>

            <v-btn
              block
              class="capitalize font-work-sans font-medium text-base rounded-lg mt-5"
              color="primary"
              style="height: 48px; font-size: 16px !important"
              @click="submitFormInterest"
              :disabled="disableBtnSubmitInterest"
            >
              {{ lang[getLanguage].BUT_SUBMIT }}
            </v-btn>

            <span
              class="text-center font-work-sans text-xs text-charged-grey-400 block mt-4"
            >
              {{ lang[getLanguage].INFO_SUMIT_COMMINGSOON_FORM }}
            </span>
          </v-col>
        </v-row>
      </div>
    </section>

    <section>
      <!-- section untuk informasi / For more information DEKSTOP -->
      <v-layout wrap v-if="!isMobileDevice">
        <v-col style="padding: 0px" cols="12" sm="12">
          <v-img
            position="center"
            min-height="150"
            style="background-color: #f5f4f6"
          >
            <v-layout class="mt-16 mx-auto">
              <v-container class="md:px-20">
                <v-row>
                  <v-layout column>
                    <span
                      class="montserrat font-weight-bold"
                      style="
                        color: #535456;
                        font-size: 24px;
                        letter-spacing: -0.025em;
                      "
                      >{{ lang[getLanguage].PRODUCT_INFORMATION }}</span
                    >
                  </v-layout>

                  <v-layout column>
                    <v-btn
                      text
                      color="#535456"
                      :to="'/contact'"
                      class="work-sans no-background-hover"
                    >
                      <v-icon
                        right
                        style="
                          color: #535456;
                          font-size: 32px;
                          margin-left: auto;
                        "
                      >
                        mdi-arrow-right
                      </v-icon>
                    </v-btn>
                  </v-layout>
                </v-row>
              </v-container>
            </v-layout>
          </v-img>
        </v-col>
      </v-layout>

      <!-- section untuk informasi / For more information MOBILE -->
      <v-layout wrap v-if="isMobileDevice">
        <v-col style="padding: 0px" cols="12" sm="12">
          <v-container class="px-5">
            <v-layout class="my-7 flex flex-nowrap">
              <span
                class="montserrat font-weight-bold font-semibold text-base"
                style="
                  color: #535456;
                  font-size: 16px;
                  letter-spacing: -0.32px;
                  /* margin-left: 5px; */
                "
              >
                {{ lang[getLanguage].PRODUCT_INFORMATION }}
              </span>
              <v-btn
                text
                color="#535456"
                :to="'/contact'"
                class="work-sans no-background-hover self-center"
              >
                <v-icon right style="color: #535456; font-size: 20px">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-layout>
          </v-container>
        </v-col>
      </v-layout>
    </section>

    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
      <v-icon color="green">mdi-check-circle-outline</v-icon>
      <span>{{ snackbarText }}</span>
      <v-btn text color="#00D2D7" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-footer class="pa-0">
      <Footer
        id="footer"
        :isMobileDevice="isMobileDevice"
        :dark="dark"
        :drawer="drawer"
        :windowWidth="windowWidth"
      >
      </Footer>
    </v-footer>
  </v-sheet>
</template>
  
  <script>
import store from "@/store/index";
import Footer from "../../FooterPressLaunch";
import DialogCheckServiceAreVue from "../../components/DialogCheckServiceAre.vue";
import { contact, createContact } from "@/services/ContactApiCaller";
import { postInterestProduct } from "../../../../services/back-end-ruby";
export default {
  name: "bike-edition",
  props: {
    model: String,
    dark: Boolean,
    isMobileDevice: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean,
  },
  data: () => {
    return {
      selectedItem: "sdfsdfs",
      provinceFix: "",
      //Initial
      lang: store.state.lang,
      currentUser: null,
      //Snackbars
      snackbar: false,
      snackbarText: "",
      //tabs
      tab: null,
      tab2: null,
      keys: [],
      powertrain: [],
      vehiclespec: [],
      bikeName: "",
      slug: "",
      signupDialog: false,
      profileFormDialog: false,
      subscribeNowDialog: false,
      bikeIndex: -1,
      bike: {},
      logoSrcDark: "",
      checkServiceAreaDialog: false,
      captureEmailDialog: false,
      dataProvided: false,
      emailSaved: false,

      // Form Validation and value other
      valid: false,
      email: "",
      customerCity: "",
      province: "",
      city: "",
      customerArea: "",
      regency: "",
      loading: false,

      // Form Validation and value Interested new product
      formValidInterest: false,
      nameUserInterest: "",
      emailUserInterest: "",
      phoneUserInterest: "",

      // All Rules
      nameRules: [(v) => !!v || "Name is required!"],
      emailRules: [
        (v) => !!v || "Email is required!",
        (v) =>
          /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(v) ||
          "Email address must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone Number is required!",
        (v) => /^(\+\d{1,3})?\d+$/.test(v) || "Invalid Phone Number format!",
      ],

      disableBtnSubmitInterest: false,
    };
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getPlatform() {
      return this.$store.state.device.platform;
    },
    getUser() {
      return this.$store.state.user;
    },
    getProvince() {
      return this.$store.state.province;
    },
    getCities() {
      return this.$store.state.cities;
    },
    getRegencies() {
      return this.$store.state.regencies;
    },
    getServiceCities() {
      return this.$store.state.service_cities;
    },
    getbyUrl() {
      return this.$store.getters.getbyUrl;
    },
  },
  components: {
    Footer,
    DialogCheckServiceAreVue,
  },
  created() {
    this.getBikes();
    this.bike = this.$store.getters.vehicle_show_store;
    this.bikeName = this.bike.nameLowercase;
    this.getBikeName();
  },
  methods: {
    getBikes() {
      this.bikes = this.$store.getters.getListVehicle;
    },
    formattedWrapText(text, textWrap) {
      const textAwalWrap = text.indexOf(textWrap);
      const firstParagraph = text.slice(0, textAwalWrap).trim();
      const secondParagraph = text.slice(textAwalWrap).trim();

      return `${firstParagraph}<br>${secondParagraph}`;
    },
    openBrochureBike() {
      window.open(
        "/pdf/brochure_" + this.bikeName + "_" + this.getLanguage + ".pdf",
        "_blank"
      );
    },
    openBrochure() {
      window.open("/pdf/brochure_" + this.getLanguage + ".pdf", "_blank");
    },
    onChangeProvince() {
      this.city = "";
      this.regency = "";
    },
    onChangeCity() {
      this.regency = "";
    },
    onChangeRegency() {
      this.customerCity = "";
    },
    signupDialogClosed() {
      this.signupDialog = false;
    },
    subscribeNowDialogClosed() {
      this.$refs.subscribe.subscribeClosed();
      this.subscribeNowDialog = false;
    },
    subscribeNowClicked() {
      this.$refs.dialogCheckServiceArea.checkServiceAreaDialogOpen();
    },

    getBikeName() {
      this.bikeSubscribe =
        "/img/shared/bikes/" +
        this.bikeName +
        "/banner-" +
        this.bikeName +
        "-subscribe.png";
      this.bikeSubscribeMobile =
        "/img/shared/bikes/" +
        this.bikeName +
        "/banner-" +
        this.bikeName +
        "-subscribe-mobile.png";
      this.bikeProduct =
        "/img/shared/bikes/" +
        this.bikeName +
        "/banner-" +
        this.bikeName +
        "-product.png";
      this.bikeProductRowOne =
        "/img/shared/bikes/" +
        this.bikeName +
        "/banner-" +
        this.bikeName +
        "-product-row-1.png";
      this.bikeProductRowTwo =
        "/img/shared/bikes/" +
        this.bikeName +
        "/banner-" +
        this.bikeName +
        "-product-row-2.png";
      this.bikeProductRowThree =
        "/img/shared/bikes/" +
        this.bikeName +
        "/banner-" +
        this.bikeName +
        "-product-row-3.png";
      this.bikeProductRowThreeLeft =
        "/img/shared/bikes/" +
        this.bikeName +
        "/banner-" +
        this.bikeName +
        "-product-row-3-left.png";
      this.bikeProductRowThreeRight =
        "/img/shared/bikes/" +
        this.bikeName +
        "/banner-" +
        this.bikeName +
        "-product-row-3-right.png";
      this.bikeHero =
        "/img/shared/bikes/" +
        this.bikeName +
        "/bg-banner-" +
        this.bikeName +
        "-new.jpg";
      this.bikeHeroMobile =
        "/img/shared/bikes/" +
        this.bikeName +
        "/bg-banner-" +
        this.bikeName +
        "-mobile" +
        ".jpg";
      this.bikeLogo =
        "/img/shared/bikes/" +
        this.bikeName +
        "/logotype-" +
        this.bikeName +
        ".png";
      this.bikeBanner =
        "/img/shared/bikes/" +
        this.bikeName +
        "/banner-" +
        this.bikeName +
        ".png";
      this.bikeTab1 = "/img/shared/bikes/" + this.bikeName + "/tab1.jpg";
      this.bikeTab2 = "/img/shared/bikes/" + this.bikeName + "/tab2.jpg";
      this.bikeTab3 = "/img/shared/bikes/" + this.bikeName + "/tab3.jpg";
      this.bikeSide =
        "/img/shared/bikes/" +
        this.bikeName +
        "/" +
        this.bikeName +
        "-side.jpg";
      this.bikeSideEmblem =
        "/img/shared/bikes/" +
        this.bikeName +
        "/" +
        this.bikeName +
        "-side-emblem.png";
    },
    openSubscriptionPolicy() {
      this.$emit("subscriptionPolicyClicked");
    },
    registerLeadEmail() {
      this.loading = true;
      contact.email_id = this.email;
      contact.email_ids.push({
        parent: name[0],
        parentfield: "email_ids",
        parenttype: "Contact",
        idx: 1,
        docstatus: 0,
        email_id: this.email,
        is_primary: 1,
        doctype: "Contact Email",
        city: this.customerArea,
      });
      createContact();
      this.checkServiceAreaDialog = false;
      this.emailSaved = true;
    },
    change1() {
      // console.log(this.$refs.form.validate());
      if (this.$refs.form.validate()) {
        this.dataProvided = true;
      } else {
        this.dataProvided = false;
      }
    },
    signupDialogOpen() {
      setTimeout(() => {
        // console.log(this.customerCity);
        if (
          typeof this.customerCity.id !== "undefined" &&
          this.city.name != "Tidak tersedia dalam daftar ini" &&
          this.province.name != "Tidak tersedia dalam daftar ini"
        ) {
          // console.log("Is Object");
          // console.log(this.bikeIndex, this.selectedBike);

          const getUser = this.$store.state.user;
          // console.log("getUser", getUser);
          const getuserPhoneNumber = getUser.phone;
          // console.log("getuserPhoneNumber", getuserPhoneNumber);
          getuserPhoneNumber
            ? (this.profileFormDialog = true)
            : (this.signupDialog = true);

          store.commit("SetServiceCity", this.customerCity);
          store.commit(
            "SetBikeSubs",
            this.bikes.find((bike) => bike.name == this.bikeName)
          );
        } else {
          // console.log("Is NOT Object");
          this.emailSaved = false;
          this.captureEmailDialog = true;
        }
      }, 500);
    },
    subscribeNowServiceAreaOk() {
      setTimeout(() => {
        // console.log(this.customerCity);
        if (typeof this.customerCity.id !== "undefined") {
          // console.log("Is Object");
          this.subscribeNowDialog = true;
          // write object to store
          store.commit("SetServiceCity", this.customerCity);
        } else {
          // console.log("Is NOT Object");
          this.emailSaved = false;
          this.captureEmailDialog = true;
        }
      }, 500);
    },

    gotoFormInterested(e) {
      e.view.scrollTo({
        top: this.$refs?.form_interested?.offsetTop,
        behavior: "smooth",
      });
    },

    gotoFormInterestedRelease(e) {
      e.view.scrollTo({
        top: this.$refs?.form_interested_release?.offsetTop,
        behavior: "smooth",
      });
    },

    async submitFormInterest() {
      let token = this.$cookies.get("token_api");
      let valid = this.$refs.form_interested_validation.validate();
      let data = {
        vehicle_id: this.bike.vehicle_id,
        name: this.nameUserInterest,
        email: this.emailUserInterest,
        phone: this.phoneUserInterest,
      };
      if (valid) {
        this.disableBtnSubmitInterest = true;
        let result = await postInterestProduct(token, data);
        if (result.status == 200) {
          this.disableBtnSubmitInterest = false;
          this.$refs.form_interested_validation.reset();

          this.$parent.openDialogAlert();
        }
      }
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

.montserrat {
  font-family: "Montserrat" !important;
}

/* Desqy Add this css */

.work-sans {
  font-family: "Work Sans";
}

/* Navbar */
.v-app-bar--fixed {
  color: black !important;
}

.v-app-bar--fixed .v-btn {
  color: black !important;
}

.v-app-bar--fixed .v-icon {
  color: black !important;
}

/* END Navbar */

/* FAQ */

.faq-header {
  font-size: 42px;
  border-bottom: 1px dotted #ccc;
  padding: 24px;
}

.faq-content {
  margin: 0 auto;
}

.faq-question {
  padding: 20px 0;
  border-bottom: 1px solid #ccc !important;
}

.panel-title {
  font-size: 18px;
  line-height: 26px;
  color: #262626;
  font-weight: 700;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 10px 10px 0 48px;
  display: block;
  cursor: pointer;
}

.panel-content {
  font-size: 16px;
  padding: 0px 14px;
  line-height: 26px;
  font-weight: 400;
  color: #535456;
  height: 0;
  overflow: hidden;
  z-index: 0;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition: 0.4s ease;
}

.panel:checked ~ .panel-content {
  height: auto;
  opacity: 1;
  padding: 14px;
}

.plus {
  position: absolute;
  /* margin-left: 1120px; */
  right: 40px;
  margin-top: 4px;
  z-index: 1;
  font-size: 42px;
  line-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.panel:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.panel {
  display: none;
}

.catalog-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 3rem;
}

.cities-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

@media (max-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .plus {
    margin-left: 700px;
  }

  .panel-title {
    margin-left: -45px;
  }
}

@media (max-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
  .plus {
    z-index: 1;
    margin-left: 350px;
  }

  .panel-title {
    margin-left: -35px;
  }

  .text-responsive-m {
    font-size: 4.2vw !important;
  }
}

.bike-logo {
  margin-top: 3rem;
}

.banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
}

.banner-subscribe-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  height: 100%;
  padding: 5rem;
}

.banner-subscribe-container-mobile {
  padding-top: 25rem;
  padding-right: 4rem;
  padding-left: 4rem;
  padding-bottom: 5vh;
}

.bike-color {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.bike-color-title {
  font-weight: 600;
  color: white;
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.banner-footer {
  /* display: flex; */
  /* flex-direction: row; */
  justify-content: center;
  align-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 2rem 0;
  margin: 0 !important;
}

.banner-footer-mobile {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 2rem 0;
  margin: 0 !important;
}

.banner-footer-info {
  /* display: flex;
    flex-direction: column; */

  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  color: white;
  border-right: 1px solid white;
  padding-right: 2rem;
}

.banner-footer-info-mobile {
  text-align: center;
  width: 100%;
  color: white;
}

.banner-footer-info-title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.banner-footer-info-title-mobile {
  font-size: 15px;
  font-weight: 700;
}

.banner-footer-info-subtitle {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.08em;
}

.banner-footer-info-subtitle-mobile {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.02em;
}

.banner-footer-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  padding-left: 3rem;
}

.test-ride-button {
  color: #6b4693 !important;
}

.form-city {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.input-label {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #858688;
}

.no-location-submit-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #262626;
}

.no-location-submit-description {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #535456;
  text-align: justify;
  text-align-last: center;
}

/* @media (min-width:320px)  {     
      .plus {
        z-index: 1;
        margin-left: 250px;
      }
      .panel-title {
        margin-left: -35px;
      }
    } */

/* END FAQ */

/* END Desqy css */
.sudo-line {
  position: relative;
}
.sudo-line::after {
  content: "";
  position: absolute;
  left: 0;
  top: 40%;
  width: 42%;
  height: 2px;
  background: #c1c2c4;
}
.sudo-line::before {
  content: "";
  position: absolute;
  right: 0;
  top: 40%;
  width: 42%;
  height: 2px;
  background: #c1c2c4;
}

.custom-table-hover
  .theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: none;
}

.custom-table-hover .v-tabs-slider-wrapper .v-tabs-slider {
  display: none;
}

.btn-toggle-collaps-product::after {
  content: "";
  position: absolute;
  /* margin-top: 15px; */
  width: 16px;
  height: 2.3px;
  right: -25px;
  top: 23px;
  background: #262626;
}

.btn-toggle-collaps-product::before {
  content: "";
  position: absolute;
  /* margin-top: 15px; */
  width: 16px;
  height: 2px;
  right: -25px;
  top: 23px;
  background: #262626;
  transform: rotate(90deg);
}

.panel:checked ~ .relative .btn-toggle-collaps-product::before {
  transform: rotate(0deg);
}

.btn-toggle-collaps-product-tab-2::after {
  content: "";
  position: absolute;
  /* margin-top: 15px; */
  width: 16px;
  height: 2.3px;
  right: -25px;
  top: 23px;
  background: #fff;
}

.btn-toggle-collaps-product-tab-2::before {
  content: "";
  position: absolute;
  /* margin-top: 15px; */
  width: 16px;
  height: 2px;
  right: -25px;
  top: 23px;
  background: #fff;
  transform: rotate(90deg);
}

.panel:checked ~ .relative .btn-toggle-collaps-product-tab-2::before {
  transform: rotate(0deg);
}
</style>
  