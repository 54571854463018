<template>
    <v-sheet tile>
        <section id="marquee-text">
            <!-- <marquee>
                    <span class="kumbh-sans mr-8">MALEO X DOCHI</span>
                    <span class="kumbh-sans mr-8">MALEO X DOCHI</span>
                    <span class="kumbh-sans mr-8">MALEO X DOCHI</span>
                    <span class="kumbh-sans mr-8">MALEO X DOCHI</span>
                    <span class="kumbh-sans mr-8">MALEO X DOCHI</span>
                    <span class="kumbh-sans mr-8">MALEO X DOCHI</span>
                </marquee> -->
            <div class="marquee">
                <div class="track">
                    <div class="content">
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                    </div>
                </div>
            </div>
            <div id="maleoxdochi-content" class="text-center maleoxdochi-bg" v-show="$vuetify.breakpoint.lgAndUp">
                <img src="../../../../public/img/home/maleoxdochi/maleoxdochi.png" class="maleoxdochi-bike" alt="">
            </div>
            <div id="maleoxdochi-content" class="text-center maleoxdochi-bg-mobile"
                v-show="$vuetify.breakpoint.mdAndDown">
                <img src="../../../../public/img/home/maleoxdochi/maleoxdochi.png" class="maleoxdochi-bike-mobile"
                    alt="">
            </div>
            <div class="marquee">
                <div class="track">
                    <div class="content">
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                        <b class="kumbh-sans mr-8">MALEO X DOCHI</b>
                    </div>
                </div>
            </div>
        </section>
        <section id="floating-price" class="floating-price-bg" style="background-color: #4F3379;">
            <!-- <v-row no-gutters>
                <v-col class="d-flex" style="background-color: white;">
                    <v-sheet class="mr-6">Top Speed</v-sheet>
                    <v-sheet class="mr-6">Max Range</v-sheet>
                    <v-sheet class="mr-6">Power</v-sheet>
                </v-col>
                <v-col>
                    <v-sheet style="background-color: #4F3379; color: white;">OTR Price start from</v-sheet>
                </v-col>
            </v-row> -->
            <div id="stickyfooter" v-show="$vuetify.breakpoint.lgAndUp">
                <v-row style="background: rgba(79, 51, 121, 1); align-items: center;">
                    <v-col class="white">
                        <v-sheet class="text-left" style="border-right: 1px solid rgba(193, 194, 196, 1);">
                            <small class="spec-sticky-text">Top Speed</small>
                            <div class="justify-center">
                                <svg class="mr-2" width="34" height="35" viewBox="0 0 34 35" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_6516_39146)">
                                        <path
                                            d="M28.8717 12.6408L27.1292 15.2617C27.9695 16.9377 28.3809 18.796 28.3266 20.6701C28.2722 22.5442 27.7537 24.3755 26.8175 26H7.18254C5.96586 23.8893 5.46171 21.4428 5.74482 19.023C6.02794 16.6033 7.08325 14.3392 8.7543 12.5664C10.4254 10.7936 12.6232 9.6064 15.022 9.18091C17.4208 8.75542 19.8929 9.11424 22.0717 10.2042L24.6925 8.46168C22.0242 6.75065 18.8592 5.98393 15.7036 6.28417C12.5481 6.5844 9.58442 7.93425 7.28666 10.1178C4.98889 12.3013 3.4898 15.1924 3.02918 18.3285C2.56856 21.4646 3.17302 24.6646 4.74587 27.4167C4.9931 27.8449 5.34808 28.201 5.77555 28.4495C6.20302 28.698 6.68809 28.8304 7.18254 28.8333H26.8034C27.3026 28.8353 27.7936 28.7053 28.2264 28.4566C28.6593 28.2078 29.0187 27.8491 29.2684 27.4167C30.5737 25.1556 31.2287 22.5777 31.1612 19.9678C31.0937 17.3578 30.3063 14.8173 28.8859 12.6267L28.8717 12.6408Z"
                                            fill="black" />
                                        <path
                                            d="M15.0025 22.3308C15.2656 22.5943 15.5781 22.8033 15.9221 22.9458C16.266 23.0884 16.6347 23.1618 17.0071 23.1618C17.3794 23.1618 17.7481 23.0884 18.0921 22.9458C18.436 22.8033 18.7485 22.5943 19.0117 22.3308L27.03 10.3033L15.0025 18.3217C14.7391 18.5848 14.5301 18.8973 14.3875 19.2413C14.2449 19.5852 14.1715 19.9539 14.1715 20.3263C14.1715 20.6986 14.2449 21.0673 14.3875 21.4113C14.5301 21.7552 14.7391 22.0677 15.0025 22.3308Z"
                                            fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6516_39146">
                                            <rect width="34" height="34" fill="white" transform="translate(0 0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <small class="spec-sticky-heading">75Km</small>
                            </div>
                        </v-sheet>
                    </v-col>

                    <!-- <svg width="2" height="51" viewBox="0 0 1 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.5" y1="0.5" x2="0.499998" y2="50.5" stroke="#C1C2C4" />
                    </svg> -->

                    <v-col class="white">
                        <v-sheet class="text-left" style="border-right: 1px solid rgba(193, 194, 196, 1);">
                            <small class="spec-sticky-text">Max Range</small>
                            <div class="justify-center">
                                <svg class="mr-2" width="34" height="35" viewBox="0 0 34 35" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_6516_39155)">
                                        <path
                                            d="M26.9167 7.58333C26.9167 6.025 25.6417 4.75 24.0834 4.75H19.8334V7.58333H24.0834V11.3375L19.1534 17.5H14.1667V10.4167H8.50004C5.36921 10.4167 2.83337 12.9525 2.83337 16.0833V20.3333H5.66671C5.66671 22.685 7.56504 24.5833 9.91671 24.5833C12.2684 24.5833 14.1667 22.685 14.1667 20.3333H20.5134L26.9167 12.3292V7.58333ZM5.66671 17.5V16.0833C5.66671 14.525 6.94171 13.25 8.50004 13.25H11.3334V17.5H5.66671ZM9.91671 21.75C9.13754 21.75 8.50004 21.1125 8.50004 20.3333H11.3334C11.3334 21.1125 10.6959 21.75 9.91671 21.75Z"
                                            fill="black" />
                                        <path d="M14.1667 6.16669H7.08337V9.00002H14.1667V6.16669Z" fill="black" />
                                        <path
                                            d="M26.9167 16.0833C24.5651 16.0833 22.6667 17.9816 22.6667 20.3333C22.6667 22.685 24.5651 24.5833 26.9167 24.5833C29.2684 24.5833 31.1667 22.685 31.1667 20.3333C31.1667 17.9816 29.2684 16.0833 26.9167 16.0833ZM26.9167 21.75C26.1376 21.75 25.5001 21.1125 25.5001 20.3333C25.5001 19.5541 26.1376 18.9166 26.9167 18.9166C27.6959 18.9166 28.3334 19.5541 28.3334 20.3333C28.3334 21.1125 27.6959 21.75 26.9167 21.75Z"
                                            fill="black" />
                                        <path
                                            d="M9.91675 28.8333H15.5834V26L24.0834 30.25H18.4167V33.0833L9.91675 28.8333Z"
                                            fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6516_39155">
                                            <rect width="34" height="34" fill="white" transform="translate(0 0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <small class="spec-sticky-heading">120Km</small>
                            </div>
                        </v-sheet>
                    </v-col>

                    <!-- <svg width="2" height="51" viewBox="0 0 1 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.5" y1="0.5" x2="0.499998" y2="50.5" stroke="#C1C2C4" />
                    </svg> -->

                    <v-col class="white">
                        <v-sheet class="text-left">
                            <small class="spec-sticky-text">Power</small>
                            <div class="justify-center">
                                <svg class="mr-2" width="34" height="35" viewBox="0 0 34 35" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_6516_39169)">
                                        <path d="M15 21.4L15 35L27 14.6L19 14.6L19 0.999999L7 21.4L15 21.4Z"
                                            fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6516_39169">
                                            <rect width="34" height="34" fill="white" transform="translate(0 0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <small class="spec-sticky-heading">2000W</small>
                            </div>
                        </v-sheet>
                    </v-col>
                    <v-col style="background-color: rgba(79, 51, 121, 1);">
                        <v-sheet class="mr-6 text-left" style="background-color: rgba(79, 51, 121, 1);">
                            <small class="spec-sticky-text-medium" style="color: white;">OTR Price start from</small>
                            <div class="justify-center">
                                <small class="spec-sticky-heading" style="color: white;">Rp43.000.000</small>
                            </div>
                        </v-sheet>
                    </v-col>
                    <v-col style="background-color: rgba(79, 51, 121, 1);">
                        <v-sheet style="background-color: rgba(79, 51, 121, 1);">
                            <v-btn large :to="'/'" class="work-sans mr-6"
                                style="padding: 12px 24px; height: 48px; border-radius: 16px; border: 2px solid #FFFFFF; background-color: #f5f5f500; color: white; font-style: normal; font-weight: 700; font-size: 16px; line-height: 24px; letter-spacing: -0.02em; font-family: kumbh-sans !important;">
                                PREORDER NOW
                            </v-btn>
                        </v-sheet>
                    </v-col>
                </v-row>
            </div>

            <div v-show="$vuetify.breakpoint.mdAndDown">
                <v-row style="background: rgba(79, 51, 121, 1); align-items: center; text-align-last: center; margin: 0px;">
                    <v-col class="white" style="padding: 24px 16px;">
                        <v-sheet class="text-left" style="border-right: 1px solid rgba(193, 194, 196, 1);">
                            <small class="spec-sticky-text-mobile">Top Speed</small>
                            <div class="justify-center">
                                <small class="spec-sticky-heading-mobile">75Km</small>
                            </div>
                        </v-sheet>
                    </v-col>

                    <v-col class="white" style="padding: 24px 16px;">
                        <v-sheet class="text-left" style="border-right: 1px solid rgba(193, 194, 196, 1);">
                            <small class="spec-sticky-text-mobile">Max Range</small>
                            <div class="justify-center">
                                <small class="spec-sticky-heading-mobile">120Km</small>
                            </div>
                        </v-sheet>
                    </v-col>

                    <v-col class="white" style="padding: 24px 16px;">
                        <v-sheet class="text-left">
                            <small class="spec-sticky-text-mobile">Power</small>
                            <div class="justify-center">
                                <small class="spec-sticky-heading-mobile">2000W</small>
                            </div>
                        </v-sheet>
                    </v-col>
                    <v-col style="background-color: rgba(79, 51, 121, 1);">
                        <v-sheet class="mr-6 text-left" style="background-color: rgba(79, 51, 121, 1);">
                            <small class="spec-sticky-text-medium-mobile" style="color: white;">OTR Price start
                                from</small>
                            <div class="justify-center">
                                <small class="price-sticky-mobile" style="color: white;">Rp43.000.000</small>
                            </div>
                        </v-sheet>
                    </v-col>
                    <v-col style="background-color: rgba(79, 51, 121, 1);">
                        <v-sheet style="background-color: rgba(79, 51, 121, 1);">
                            <v-btn large :to="'/'" class="work-sans mr-6"
                                style="padding: 12px 24px; height: 48px; border-radius: 16px; border: 2px solid #FFFFFF; background-color: #f5f5f500; color: white; font-style: normal; font-weight: 700; font-size: 16px; line-height: 24px; letter-spacing: -0.02em; font-family: kumbh-sans !important;">
                                PREORDER NOW
                            </v-btn>
                        </v-sheet>
                    </v-col>
                </v-row>
            </div>
        </section>
        <v-footer class="pa-0" style="margin: 0px;">
            <Footer id="footer" :isMobileDevice="isMobileDevice" :dark="dark" :drawer="drawer"
                :windowWidth="windowWidth">
            </Footer>
        </v-footer>
    </v-sheet>
</template>

<script>
    import store from "@/store/index";
    import Footer from "../FooterPressLaunch";
    // import Footer from "./Footer";

    export default {
        name: "pressLaunch",
        metaInfo() {
            return {
                title: this.metaTitle,
                meta: [{
                        name: "description",
                        content: this.metaDesc
                    },
                    {
                        property: "og:title",
                        content: this.metaTitle
                    },
                    {
                        property: "og:image",
                        content: this.metaImg
                    },
                    {
                        property: "og:url",
                        content: this.metaUrl
                    },
                    {
                        property: "og:site_name",
                        content: "Charged Asia"
                    },
                    {
                        property: "og:type",
                        content: "website"
                    },
                    {
                        name: "robots",
                        content: "index,follow"
                    },
                ],
            };
        },
        props: {
            dark: Boolean,
            windowWidth: Number,
            drawer: Boolean,
            isMobileDevice: Boolean,
        },
        computed: {
            getLanguage() {
                return this.$store.state.user.language;
            },
        },
        data() {
            return {
                lang: this.$store.state.lang,
                //meta
                metaTitle: "Charged Asia - Ride for Good!",
                metaDesc: "Our mission at Charged is 10 million electric vehicles in 10 years. We plan to start with electric motorcycles and lead the mass adoption of sustainable mobility in our region.",
                metaImg: "https://firebasestorage.googleapis.com/v0/b/charged-app.appspot.com/o/img%2FCharged%20Factory%20Thumbnail.png?alt=media&token=cc8bf9be-e653-49f2-b520-98f0ce484930",
                metaUrl: "https://charged.asia",
                videoElement: null,
                options: {
                    quality: {
                        default: "1080p"
                    },
                    controls: ["play-large"],
                    hideControls: false,
                },
                imgSrc: "https://firebasestorage.googleapis.com/v0/b/charged-app.appspot.com/o/img%2FCharged%20Factory%20High%20Res.png?alt=media&token=4b2e7a77-e3db-4169-b5b5-10dff28ff515",
            };
        },
        watch: {},
        components: {
            Footer,
        },
        created() {
            this.changeShowNavigation();
        },
        methods: {
            onReady() {
                if (this.videoElement === null) {
                    this.videoElement = this.$refs.plyr.player;
                    this.videoElement.muted = true;
                    this.videoElement.elements.buttons.play[0].classList = [];
                    this.videoElement.elements.buttons.play[0].classList.add(
                        "button-hide-me"
                    );
                    this.videoElement.elements.container.classList.add(
                        "plyr--init-hide-controls"
                    );
                    // this.videoElement.elements.container.classList.add('plyr__control plyr__control--overlaid')
                    this.videoElement.play();
                }
            },
            updateElement() {
                if (this.videoElement === null) {
                    this.videoElement = this.$refs.plyr.player;
                    this.videoElement.play();
                }
            },
            videoEnded() {
                this.videoElement.play();
            },
            // changeShowNavigation() {
            //   store.commit("HideNav");
            // },
            changeLanguage(lang) {
                store.commit("SetLanguage", {
                    language: (this.getLanguage = lang),
                });
            },
            startChat() {
                var link = "https://api.whatsapp.com/send?phone=6281237686970&text=";
                if (this.getLanguage === "en") {
                    link += "Hello%20Charged";
                }
                if (this.getLanguage === "id") {
                    link += "Halo%20Charged";
                }
                window.open(link, "_blank");
            },
            routerGo(route) {
                this.$router.push(route);
            },
        },
    };
</script>

<style>
    @import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

    /* .v-responsive__sizer {
  transition: padding-bottom 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
  flex: 1 0 0px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
} */

    .text-over-image-white {
        color: #ffffff !important;
    }

    .text-primary {
        color: #6b4693 !important;
    }

    .margin-x-340 {
        margin-left: 340px;
        margin-right: 340px;
    }

    .charged-title {
        font-family: "Montserrat" !important;
        font-size: 36px !important;
        font-weight: 700 !important;
        line-height: 45px !important;
        letter-spacing: -0.03em !important;
        text-align: left !important;
    }

    .charged-title-2 {
        font-family: "Montserrat" !important;
        font-size: 28px !important;
        font-weight: 700 !important;
        line-height: 35px !important;
        letter-spacing: -0.03em !important;
        text-align: left !important;
    }

    .charged-title-mobile {
        font-family: "Montserrat" !important;
        font-size: 24px !important;
        font-weight: 700 !important;
        line-height: 36px !important;
        letter-spacing: -0.025em !important;
        text-align: left !important;
    }

    .charged-body-h1 {
        font-family: "Work Sans";
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #353638;
    }

    .charged-body-h2 {
        font-family: "Work Sans";
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #353638;
    }

    .charged-button-text {
        font-family: "Work Sans";
        font-size: 20px;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: -0.02em;
        text-align: center;
    }

    .charged-button {
        border-radius: 8px;
        padding: 12px 24px 12px 32px;
    }

    .charged-button-mobile {
        border-radius: 8px;
        padding: 10px 24px 10px 24px;
    }

    .no-uppercase {
        text-transform: unset !important;
    }

    .theme--light.v-sheet--outlined {
        border: none;
    }

    .v-tab,
    .v-tab:hover {
        background: none !important;
    }

    .theme--dark.v-btn:hover::before {
        opacity: 1;
    }

    .plyr--init-hide-controls .plyr__controls {
        display: none;
    }

    .button-hide-me {
        display: none;
    }

    .no-background-hover::before {
        background-color: transparent !important;
    }

    .v-card--link:focus::before {
        opacity: 0;
    }

    /* .marquee-text-move {
        font-size: 32px;
        background-color: rgba(246, 152, 67, 1);
        font-family: Kumbh Sans;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.03em;
        color: white;
        padding: 16px 0px;
    } */

    .marquee {
        position: relative;
        width: 100vw;
        max-width: 100%;
        /* height: 100px; */
        overflow-x: hidden;
        font-size: 32px;
        background-color: rgba(246, 152, 67, 1);
        font-family: Kumbh Sans;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.03em;
        color: white;
        padding: 16px 0px;
    }

    .track {
        /* position: absolute; */
        white-space: nowrap;
        will-change: transform;
        animation: marquee 32s linear infinite;
    }

    @keyframes marquee {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-50%);
        }
    }

    .maleoxdochi-bg {
        /* background-color: #CEFA6C; */
        background-image: url("../../../../public/img/home/maleoxdochi/bg-maleodochi-without-emoji.png");
        background-size: cover;
        height: 700px;
    }

    .maleoxdochi-bg-mobile {
        /* background-color: #CEFA6C; */
        background-image: url("../../../../public/img/home/maleoxdochi/bg-maleodochi-mobile-withtext.png");
        background-size: cover;
        height: 370px;
    }

    .maleoxdochi-bike {
        width: 1008px;
        margin-top: 0%;
        position: relative;
        z-index: 1;
    }

    .maleoxdochi-bike-mobile {
        width: 100%;
        margin-top: 21%;
        position: relative;
        z-index: 1;
    }

    /* START FLOATING/STICKY SECTION */
    #stickyfooter {
        position: fixed;
        bottom: 0;
        height: 115px;
        width: 100%;
        padding: 0;
        margin: 0;
        line-height: 50px;
        text-align: center;
        text-align-last: center;
        z-index: 1;
    }

    .spec-sticky-text {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: #000;
    }

    .spec-sticky-text-mobile {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: #000;
    }

    .spec-sticky-text-medium {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: #000;
    }

    .spec-sticky-text-medium-mobile {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.02em;
        color: #000;
    }

    .spec-sticky-heading {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 36px;
        line-height: 45px;
        letter-spacing: -0.03em;
        color: #000;
    }

    .spec-sticky-heading-mobile {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.02em;
        color: #000;
    }

    .price-sticky-mobile {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 35px;
        letter-spacing: -0.025em;
        color: #FFFFFF;
    }

    /* END FLOATING/STICKY SECTION */

    .v-footer {
        padding: 0px;
    }

    .display-none {
        display: none;
    }
</style>