<template>
  <div>
    <!-- ################################# Start View Mobile ################################ -->
    <div v-if="$vuetify.breakpoint.mobile">
      <!-- start list Menu -->
      <div v-if="selectedMenu == null">
        <div class="my-5 px-5 flex gap-3">
          <div>
            <v-avatar color="primary" size="40">
              <v-icon dark> mdi-account-circle </v-icon>
            </v-avatar>
          </div>
          <div>
            <span
              class="font-work-sans text-base font-semibold text-charged-grey-100 block"
            >
              {{ getUser.fullName }}
            </span>
            <span class="font-work-sans text-sm text-charged-grey-400 block">
              {{ getUser.phone }}
            </span>
            <span class="font-work-sans text-sm text-charged-grey-400 block">
              {{ getUser.email }}
            </span>
          </div>
          <div class="ml-auto">
            <v-btn small icon color="primary" right @click="selectedMenu = 0">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </div>
        </div>
        <span class="w-full h-2 bg-charged-grey-700 block"></span>
        <div class="my-5 px-5">
          <p class="font-work-sans text-sm text-charged-grey-400">
            {{ getLanguage == "en" ? "My Subscription" : "Langganan Saya" }}
          </p>
          <v-list>
            <v-list-item class="px-0" @click="selectedMenu = 2">
              <v-list-item-icon>
                <v-icon>mdi-sync</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ getLanguage == "en" ? "Processing" : "Proses" }}
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon style="color: #353638 !important"
                  >mdi-chevron-right</v-icon
                >
              </v-list-item-icon>
            </v-list-item>
            <v-list-item class="px-0" @click="toSectionOnGoing">
              <v-list-item-icon>
                <v-icon>mdi-list-status</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ getLanguage == "en" ? "Ongoing" : "Sedang Berlangsung" }}
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon style="color: #353638 !important"
                  >mdi-chevron-right</v-icon
                >
              </v-list-item-icon>
            </v-list-item>
            <v-list-item class="px-0" @click="selectedMenu = 4">
              <v-list-item-icon>
                <v-icon>mdi-history</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ getLanguage == "en" ? "History" : "Riwayat" }}
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon style="color: #353638 !important"
                  >mdi-chevron-right</v-icon
                >
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </div>
        <span class="w-full h-2 bg-charged-grey-700 block"></span>
        <div class="my-5 px-5">
          <v-list-item class="px-0" link @click="log">
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ getLanguage == "en" ? "Sign Out" : "Keluar" }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </div>
      <!-- end List Menu -->

      <!-- start content -->
      <myAccountSettings ref="account_settings" v-if="selectedMenu == 0" />
      <myAccountProcessing v-if="selectedMenu == 2" />
      <myAccountOngoing ref="account_ongoing" v-if="selectedMenu == 3" />
      <myAccountHistory v-if="selectedMenu == 4" />
      <!-- end content -->
    </div>
    <!-- ################################# End View Mobile ################################ -->

    <!-- ################################# Start View Desktop ################################ -->
    <div class="p-0" v-if="$vuetify.breakpoint.lg">
      <div class="flex">
        <div>
          <v-navigation-drawer permanent height="100vh">
            <div class="pl-4 mt-10">
              <h1
                class="mb-4 font-work-sans text-xl font-semibold text-charged-grey-100"
              >
                {{ lang[getLanguage].MY_ACCOUNT }}
              </h1>
              <v-row class="mb-4 d-flex items-center">
                <v-col cols="3" class="pr-0">
                  <v-avatar size="50">
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                    ></v-img>
                  </v-avatar>
                </v-col>
                <v-col
                  cols="9"
                  class="pl-1 font-work-sans text-charged-grey-100 font-semibold"
                >
                  <!-- {{ this.displayName }} -->
                  {{
                    getUser.fullName.charAt(0).toUpperCase() +
                    getUser.fullName.slice(1)
                  }}
                </v-col>
              </v-row>
            </div>

            <v-divider></v-divider>

            <v-list dense>
              <v-list-item-group color="primary" v-model="selectedItem">
                <v-list-item link>
                  <v-list-item-title>
                    {{
                      getLanguage == "en"
                        ? "Account Settings"
                        : "Pengaturan Akun"
                    }}
                  </v-list-item-title>
                </v-list-item>
                <v-divider class="my-2"></v-divider>
                <v-list-item link disabled>
                  <v-list-item-title>
                    {{
                      getLanguage == "en" ? "My Subscription" : "Langganan Saya"
                    }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-icon>
                    <!-- <v-icon>mdi-folder</v-icon> -->
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ getLanguage == "en" ? "Processing" : "Proses" }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-icon>
                    <!-- <v-icon>mdi-account-multiple</v-icon> -->
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ getLanguage == "en" ? "Ongoing" : "Sedang Berlangsung" }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-icon>
                    <!-- <v-icon>mdi-star</v-icon> -->
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ getLanguage == "en" ? "History" : "Riwayat" }}
                  </v-list-item-title>
                </v-list-item>

                <v-divider class="my-2"></v-divider>

                <v-list-item link @click="log">
                  <v-list-item-icon>
                    <v-icon>mdi-logout-variant</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ getLanguage == "en" ? "Sign Out" : "Keluar" }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
        </div>
        <div class="px-5 w-full">
          <div
            v-if="selectedItem == null || isLoader == true"
            class="flex justify-center items-center h-full"
          >
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </div>
          <myAccountSettings ref="account_settings" v-if="selectedItem == 0" />
          <myAccountProcessing v-if="selectedItem == 2" />
          <myAccountOngoing ref="account_ongoing" v-if="selectedItem == 3" />
          <myAccountHistory v-if="selectedItem == 4" />
        </div>
      </div>

      <FooterPressLaunch />
    </div>
    <!-- ################################# End View Desktop ################################ -->

    <!-- DIALOG LOADER -->
    <v-dialog v-model="isLoad" hide-overlay persistent width="300">
      <v-card color="white" class="p-3">
        <v-card-text class="text-center">
          <v-progress-circular
            color="primary"
            :size="50"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
        <v-card-text class="text-center text-charged-grey-100">
          Please stand by
          <!-- <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import FooterPressLaunch from "../../web/FooterPressLaunch.vue";
import myAccountSettings from "./sections/myAccountSettings.vue";
import myAccountProcessing from "./sections/myAccountProcessing.vue";
import myAccountOngoing from "./sections/myAccountOngoing.vue";
import myAccountHistory from "./sections/myAccountHistory.vue";
export default {
  name: "myaccount-v1",
  data() {
    return {
      lang: this.$store.state.lang,
      selectedItem: null,
      selectedMenu: null,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      profile: null,
      isLoader: false,
      isLoad: false,
    };
  },

  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
  },

  components: {
    FooterPressLaunch,
    myAccountSettings,
    myAccountProcessing,
    myAccountOngoing,
    myAccountHistory,
  },

  created() {
    this.isLoader = true;
    if (!this.getUser.isLoggedIn) {
      this.$router.push("/");
    } else {
      this.initial();
    }
  },

  mounted() {},

  methods: {
    log() {
      this.$router.push("/");
      this.$cookies.remove("token_api");
      window.location.reload();
    },
    initial() {
      this.selectedItem = 0;
    },
    async toSectionOnGoing() {
      this.selectedMenu = 3;
      this.isLoad = true;
    },
  },
};
</script>