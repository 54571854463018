<template>
  <div>
    <v-dialog v-model="subscribeNowDialog" fullscreen transition="dialog-bottom-transition">
      <v-toolbar :dark="dark" :color="dark ? 'grey darken-4' : '#FFF'">
        <v-btn @click="subscribeNowDialogClosed" icon style="margin-right:20px">
          <!-- this.$router.go(-1) -->
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-icon>mdi-lock-outline</v-icon>
        <span style="margin-right:5px">Secure Checkout by </span>
        <v-img src="img/icons/xendit_logo.png" max-width="64" max-height="24"></v-img>
        <v-spacer></v-spacer>
        <v-img v-if="!isMobileDevice" :src="logoSrcDark" max-width="144" max-height="32"></v-img>
        <v-spacer></v-spacer>
        <v-icon v-if="!isMobileDevice">mdi-phone</v-icon>
        <span v-if="!isMobileDevice">Need Help? <a
                                    href="https://wa.me/6281237686970?text=Hi+Charged+Indonesia%2C+Saya+perlu+bantuan"
                                    target="_blank"
                                    >+62 812 37 686970</a
                                  ></span>
      </v-toolbar>
      <v-card :dark="dark" :style="dark ? 'background-color:#2a2a2a' : 'background-color:#FFF'">
        <SubscribeNow ref="subscribe" :index="bikeIndex" :selectedBike="bike" :isMobileDevice="isMobileDevice"
          :subscriptionPolicyClicked="openSubscriptionPolicy"></SubscribeNow>
      </v-card>
    </v-dialog>

    
    <div id="recaptcha-container"></div>
    <v-dialog v-model="loginDialog" persistent max-width="700">
      <v-card class="login">
        <v-card-text class="title text-center" style="margin-top:-10px">We have automatically created your account so you can track your order. Please verify.</v-card-text>
        <v-card-text class="subheading font-weight-mediu text-center">We have sent a code through SMS to {{phoneNo}}.</v-card-text>
        
        <v-container
          class="otp-field"
        >
        <v-progress-circular
            v-if="!SMSsent"
            :size="50"
            color="primary"
            indeterminate
          >
          </v-progress-circular>
          <v-text-field
            class="otp"
            v-if="SMSsent"
            v-model="otp"
            outlined
            type="tel"
            color="deep-purple darken-4"
            maxlength="6"
            mask="######"
            label="Enter OTP"
            append-icon="mdi-message-processing-outline"
            @input="change1"
          ></v-text-field>
        </v-container>
       
        <v-btn
          :disabled="otp.length < 6 || loadingData"
          :loading="loadingData"
          color="primary"
          :width="windowWidth < 768 ? '100%' : '50%'"
          @click="verifyOtp"
          class="color:#2962FF;margin-left:-10px;margin-top:-4px;font-weight:normal;font-size:11px;text-transform: none !important;"
          >Verify</v-btn
        >
        <v-card-text class="subheading font-weight-mediu text-center">Didn’t receive the code? 
          <v-btn
                text
                :style="
                  windowWidth < 325
                    ? 'color:#2962FF;margin-left:-10px;margin-top:-4px;font-weight:normal;font-size:11px;text-transform: none !important;'
                    : 'color:#2962FF;margin-left:-10px;margin-top:-4px;font-weight:normal;font-size:13px;text-transform: none !important;'
                "
                @click.stop="sendOtp"
                >Resend Code</v-btn
              >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alertDialog" persistent max-width="500">
      
      <v-card class="login">
        <v-icon v-if="this.alertStatus == 'success'" class="succes-icon">mdi-check-circle</v-icon>
        <v-icon v-if="this.alertStatus == 'failed'" class="failed-icon">mdi-close-circle</v-icon>
        <v-card-text class="title text-center" >{{this.alertText}}</v-card-text>
        <v-btn
          :disabled="otp.length < 6 || loadingData"
          :loading="loadingData"
          color="primary"
          :width="windowWidth < 768 ? '100%' : '50%'"
          @click="closeAlertDialog"
          class="color:#2962FF;margin-left:-10px;margin-top:-4px;font-weight:normal;font-size:11px;text-transform: none !important;"
          >Ok</v-btn
        >
      </v-card>
    </v-dialog>
    <div v-if="showView === 1" :style="
      windowWidth < 770
        ? 'margin-right:5px;margin-left:5px'
        : 'margin-right:10%;margin-left:10%'">
      <v-container>
        <v-layout class="booking-fee-header">
          <p class="text-center montserrat font-weight-bold">
            BOOKING FEE RECEIPT
          </p>
          <p style="font-size:0.9em" class="text-center montserrat">Date of Order: {{ makeDate(invoiceData.createdAt) }} | Booking no: {{ invoiceData.id }}</p>
          <hr style="border: none;border-top: 1px dashed #000;height: 1px;width: 100%;">
          <p class="booking-fee-info">
              We have sent this receipt to your email. This receipt is valid as proof of transaction.
          </p>
          <span class="booking-fee-sub-title">To finish your subscription, we need you to complete your data and documents by clicking on the button below. 
              You can also find this link in your email. Don’t worry, your documents are safe with us!
          </span>
          <v-btn
            style="width:100%;color:#FFF;text-transform: none !important;"
            color="#6B4693"
            @click="submitDocument"
          >
            SUBMIT DOCUMENTS <v-icon class="booking-fee-icon">mdi-chevron-right</v-icon>
            
          </v-btn>
          <v-layout class="booking-fee-sub-title-countdown">
            <span v-if="!isloggedIn">
              Automatically redirecting in {{countDown}} seconds.
            </span>
          </v-layout>
       
          <hr style="border: none;border-top: 1px dashed #000;height: 1px;width: 100%;">
        </v-layout>
        
      

        <v-row class="booking-fee-body">
          <v-col col="4" md="4" class="booking-fee-bike">
            <v-img
            :src="'/img/shared/bikes/' + bikeName + '/' + bikeName + '.jpg'"
            max-height="200"
              max-width="300"
              width="100%"
            >
            </v-img>
            <p class="booking-fee-info">
              BIKE TO SUBSCRIBE
            </p>
            <span class="invTitle">{{bikeName}}</span>
          </v-col>
          <v-col col="8" md="8">
            <v-card
              height="50"
              style="width:100%;background-color:#F5F5F5"
              class="booking-fee-mini-card"
              outlined
              flat
            >
              <v-layout
                style="margin-top:12px;margin-left:10px" class="montserrat font-weight-bold"
              >
                Booking Fee
                <v-spacer></v-spacer>
                <span style="margin-right:10px">Rp.{{ formatPrice(invoiceData.invoices[0].amount) }}</span>
              </v-layout>

            </v-card>
          <hr style="border: none;border-top: 1px dashed #000;height: 1px;width: 100%;">


            <p class="mt-0"></p>
              <span class="invTitle">Customer Details</span>
              <p class="mt-0"></p>

              <v-layout style="width:100%">
                <v-layout>
                  <span class="font-weight-medium" style="width:200px">Full Name</span>
                  :
                  <span class="font-weight-medium" style="margin-left:50px">{{ invoiceData.customer.givenNames }}</span>
                </v-layout>
              </v-layout>

              <v-layout style="width:100%">
                <v-layout>
                  <span class="font-weight-medium" style="width:200px">Email Address</span>
                  :
                  <span class="font-weight-medium" style="margin-left:50px">{{ invoiceData.customer.email }}</span>
                </v-layout>
              </v-layout>

              <v-layout style="width:100%">
                <v-layout>
                  <span class="font-weight-medium" style="width:200px">Phone Number</span>
                  :
                  <span class="font-weight-medium" style="margin-left:50px">{{ invoiceData.customer.phoneNumber }}</span>
                </v-layout>
              </v-layout>

              <v-layout style="width:100%" mb-4>
                <v-layout>
                  <span class="font-weight-medium" style="width:200px">Residence Address</span>
                  :
                  <span class="font-weight-medium" style="margin-left:50px">{{ invoiceData.customer.address.streetLine1 }}</span>
                </v-layout>
              </v-layout>

              <hr style="border: none;border-top: 1px dotted #000;height: 1px;width: 100%;">

              <p class="mt-0"></p>
              <span class="invTitle">Shipping Details</span>
              <p class="mt-0"></p>

              <v-layout style="width:100%" mb-2>
                <v-layout>
                  <span class="font-weight-medium" style="color:#7b7b7b;font-size:0.9em">Please <b>collect your bike</b> in:</span>
                </v-layout>
              </v-layout>

              <v-row>
                <v-col col="12" md="6">
                  <v-container fluid pa-0>
                    <div class="mapouter">
                      <div class="gmap_canvas">
                        <iframe
                          width="100%"
                          height="200"
                          id="gmap_canvas"
                          src="https://maps.google.com/maps?q=industri%20charged%20mobilitas&t=&z=15&ie=UTF8&iwloc=&output=embed"
                          frameborder="0"
                          scrolling="no"
                          marginheight="0"
                          marginwidth="0"
                        ></iframe>
                      </div>
                    </div>
                  </v-container>
                </v-col> 

                <v-col col="12" md="6">
                  <v-row class="mb-2">
                    <v-col cols="1" class="py-0"><v-icon>mdi-map-marker</v-icon></v-col>
                    <v-col cols="11" class="py-0"
                      ><p class="mb-0">Jl. Bhumimas IV No.8 Talaga, Cikupa, Tangerang Regency, Banten 15710</p></v-col
                    >
                  </v-row>

                  <v-row class="mb-2" style="margin-top: 2rem;">
                    <v-col cols="1" class="py-0"></v-col>
                    <v-col cols="11" class="py-0">
                      <p>We are available on:</p>
                      <p>Mon - Sat : 09.00 - 17:00 (WIB)</p>
                      <p>Sun : Closed</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <p class="booking-fee-info-2">
                        *Your ETA will be informed to you once documents are completed.
                    </p>
                  </v-row>
                </v-col>
              </v-row>

              <hr style="border: none;
                        border-top: 1px dashed #000;
                        height: 1px;
                        width: 100%;">

              <p class="mt-0"></p>
              <span class="invTitle">Your Sales PIC</span>
              <p class="mt-0"></p>

              <v-layout style="width:100%">
                <v-layout>
                  <span class="font-weight-medium" style="width:200px">PIC Name</span>
                  :
                  <span class="font-weight-medium" style="margin-left:50px">{{ invoiceData.plan }}</span>
                </v-layout>
              </v-layout>

              <v-layout style="width:100%">
                <v-layout>
                  <span class="font-weight-medium" style="width:200px">PIC Phone Number</span>
                  :
                  <span class="font-weight-medium" style="margin-left:50px">{{ invoiceData.color }}</span>
                </v-layout>
              </v-layout>


              <hr style="border: none;
                        border-top: 1px dashed #000;
                        height: 1px;
                        width: 100%;margin-top: 1rem;">
                        
              <p class="mt-0"></p>
              <span class="invTitle">Subscription and Customization Details</span>
              <p class="mt-0"></p>

              <v-layout style="width:100%">
                <v-layout>
                  <span class="font-weight-medium" style="width:200px">Color</span>
                  :
                  <span class="font-weight-medium" style="margin-left:50px">
                    <v-layout
                    >
                      <v-icon class="radio-selection-color" :color="invoiceData.subscriptionDetail.bikeColor">mdi-circle </v-icon>
                      - {{invoiceData.subscriptionDetail.bikeColor}}
                    </v-layout>
                  </span>
                </v-layout>
              </v-layout>

              <v-layout style="width:100%">
                <v-layout>
                  <span class="font-weight-medium" style="width:200px">Charger Type</span>
                  :
                  <span class="font-weight-medium" style="margin-left:50px">{{ invoiceData.subscriptionDetail.chargerType }}</span>
                </v-layout>
              </v-layout>

              <v-layout style="width:100%">
                <v-layout>
                  <span class="font-weight-medium" style="width:200px">Battery Quantity</span>
                  :
                  <span class="font-weight-medium" style="margin-left:50px">{{ invoiceData.subscriptionDetail.batteryQuantity }}</span>
                </v-layout>
              </v-layout>

              <v-layout style="width:100%" mb-4>
                <v-layout>
                  <span class="font-weight-medium" style="width:200px">Subscription Plan</span>
                  :
                  <span class="font-weight-medium" style="margin-left:50px">{{ invoiceData.subscriptionDetail.subscriptionPlan }}</span>
                </v-layout>
              </v-layout>


          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col col="12" md="12" class="d-flex justify-center align-center">
            <v-img
              :src="displaySelectedBike.src"
              max-height="400"
              max-width="400"
              width="100%"
              :class="displaySelectedBike.flip === 0 ? 'bikes-img' : ''"
            ></v-img>
          </v-col>
        </v-row> -->

      </v-container>
    </div>

    <div v-if="showView === 2" >
      <v-container class="my-12">
        <v-row>
          <v-col col="12" md="12">
            <v-layout column align-center>
              <v-icon color="red" size="50">mdi-alert-circle-outline</v-icon>
              <h2 class="text-center montserrat font-weight-bold">
                This page has expired!
              </h2>
            </v-layout >
          </v-col>
        </v-row>
      </v-container>
    </div>





    <Footer
      id="footer"
      :isMobileDevice="isMobileDevice"
      :dark="dark"
      :drawer="drawer"
      :windowWidth="windowWidth"
    >
    </Footer>
  </div>
</template>
<script>

import {db, auth} from "@/main";
import store from "@/store/index";
//import { contact, createContact } from "@/services/ContactApiCaller";
import Footer from "../Footer";
const dateformat = require('dateformat')
// import PaymentController from "@/clients/PaymentAPI";
//import { event, createEventCalendar } from "../../../services/EventApiCaller";
import { 
  doc, 
  updateDoc,
  getDoc,
} from "firebase/firestore"; 
import {
  signInWithPhoneNumber,
  RecaptchaVerifier,
  updateProfile,
  updateEmail
} from "firebase/auth";
// import {ref, set } from "firebase/database";

export default {
  name: "subscribe_plan",
  props: {
    model: String,
    dark: Boolean,
    isMobileDevice: Boolean,
    bikesImage: String,
    close: Function,
    windowWidth: Number,
    drawer: Boolean,
  },
  data: () => {
    return {
      //Initial
      showView: 0,
      invoiceId: 0,
      bikeName: '',
      invoiceAmount: 0,
      uid: "",
      loadingData: false,
      lang: store.state.lang,
      currentUser: null,
      //Posted Data
      name: '',
      phone: '',
      email: '',
      city_zip: '',
      date: new Date().toISOString().substr(0, 10),
      loginDialog: false,
      phoneNo:"",
      SMSsent:false,
      otp:"",
      wrongOtp: false,
      alertDialog:false,
      alertStatus:false,
      alertText:"",
      countDown: 10,
      isloggedIn: false,
      subscribeNowDialog: true,

    };
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getPlatform() {
      return this.$store.state.device.platform;
    },
    getUser() {
      return this.$store.state.user;
    },
    getSubscription() {
      return this.$store.state.subscription;
    },
    getCities() {
      return this.$store.state.cities;
    },
  },
  components: {
    Footer,
  },
  watch: {
    // "$route.params": "checkSlug",
  },
  created() {
    setTimeout(() => {
      this.currentUser = auth.currentUser;
      if (this.currentUser) {
        console.log("####### Welcome App User #######");
        // this.loginDialog = false
        // this.countDownTimer();
        this.checkSlug()
        this.isloggedIn = true;
        return;
      }else{
        this.initReCaptcha();
      }
    }, 1000)
    
  },
  methods: {
    //Initialize
    checkSlug() {
      var obj = this.$route.params;
      console.log('obj');
      const isEmpty = Object.keys(obj).length === 0;
      console.log(isEmpty);

      if (!isEmpty) {
        this.invoiceId = this.$route.params.inv_id;
        this.getInoiveRecord()
      } else {
        this.showView = 2
      }
    },
    submitDocument(){
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSfjUMfdjdPU_qKJesDXpqbMklFH82O3AbTulInAV-ISz0tOZw/viewform?vc=0&c=0&w=1&flr=0&usp=mail_form_link', '_blank')
    },
    countDownTimer () {
      if (this.countDown > 0) {
          setTimeout(() => {
              this.countDown -= 1
              this.countDownTimer()
          }, 1000)
      }
      if(this.countDown == 0){
        this.submitDocument();
        this.isloggedIn = true
      }
    },
    closeAlertDialog(){
      this.alertDialog = false
    },
    change1() {
      this.alert = false;
    },
    verifyOtp() {
      this.loadingData = true
      if (this.otp.length !== 6) {
        console.log("Invalid SMS code format!");
        this.alert = true;
      } else {
        //
        this.loader = "loading";
        this.alert = false;
        // let vm = this;
        let code = this.otp;
        //
        window.confirmationResult
          .confirm(code)
          .then(() => {
            // User signed in successfully.
            // route to set Display name, email and password
            // vm.$router.push("/signupnext");
            this.alertDialog = true
            this.alertStatus = 'success'
            this.alertText = 'Done!'
            this.loadingData = false
            this.loginDialog = false
            this.countDownTimer();
          })
          .catch((error) => {
            // User couldn't sign in (bad verification code?)
            if (error) {
              // Handle error
              this.alertDialog = true
              this.alertStatus = 'failed'
              this.alertText = 'Wrong OTP!'
              this.loadingData = false
              console.log(error);
              this.alert = true;
              this.loader = null;
              this.loading = false;
            }
          });
      }
    },
    initReCaptcha() {
      setTimeout(() => {
        window.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container",{
            "size": "invisible",
            'callback': () => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // ...
            },
            'expired-callback': () => {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            }
          }, auth);
        
        this.checkSlug()
        this.appVerifier = window.recaptchaVerifier;
      }, 1000);
    },
    async sendOtp(phoneNo) {
      if (phoneNo == null) {
        alert("Invalid Phone Number Format !");
      } else {
        //
        // let countryCode = this.countryCode
        // this.loader = "loading";
        // this.phoneNoValid = true;
        // this.alert = false;
        // this.SMSsent = false;
        //
        let appVerifier = this.appVerifier;

        await signInWithPhoneNumber(auth, phoneNo, appVerifier)
          .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            //
            console.log("SMS sent");
            // hide phoneNo field, show code fields
            this.SMSsent = true;
            // this.loader = null;
            // this.loading = false;
          })
          .catch((error) => {
            // Error; SMS not sent
            if (error) {
              console.log("Error ! SMS not sent");
              console.log(error);
              // this.alert = true;
              // this.loader = null;
              // this.loading = false;
            }
          });
      }
    },
    async getInoiveRecord () {
      // var docRef = db.collection("invoicePayment").doc(this.invoiceId);

      const docSnap = doc(db, "order", this.invoiceId);
      const docRef = await getDoc(docSnap);

      if (docRef.exists) {
          console.log("Document data:", docRef);
          this.invoiceData = docRef.data()
          console.log('this.invoiceData')
          console.log(this.invoiceData)
          console.log('this.currentUser')
          console.log(this.currentUser)
          if(this.currentUser == null){
            this.loginDialog = true
            this.sendOtp(this.invoiceData.customer.phoneNumber)
          }else{
            if(this.currentUser.displayName == null){
              updateProfile(this.currentUser, {
                displayName: this.invoiceData.email
              }).then(() => {
                console.log('Profile updated!')
              }).catch((error) => {
                console.log(error)
                // An error occurred
                // ...
              });
            }
            if(this.currentUser.email == null){
              updateEmail(this.currentUser, this.invoiceData.customer.email).then(() => {
                console.log('Email updated!')
                // Email updated!
                // ...
              }).catch((error) => {
                console.log(error)
                // An error occurred
                // ...
              });
            }
          }
          
          


          // if (this.invoiceData.count === 0) {
          //   console.log('update count and payment')
          //   this.bikeName = this.invoiceData.bike
          //   this.showView = 1
          //   this.updateInvoiceRecord()
          // } else {
          //   console.log('No update action. Show error view.')
          //   this.showView = 2
          // }
          if (this.invoiceData != null) {
            console.log('update count and payment')
            this.bikeName = this.invoiceData.orderDetailItem[0].name
            this.phoneNo = this.invoiceData.customer.phoneNumber
            this.showView = 1
            this.updateInvoiceRecord()
          } else {
            console.log('No update action. Show error view.')
            this.showView = 2
          }
      } else {
          console.log("No such document!");
          this.showView = 2
          return
      }

      // docRef.get().then((doc) => {
      //   if (doc.exists) {
      //       console.log("Document data:", doc.data());
      //       this.invoiceData = doc.data()
      //       if (this.invoiceData.count === 0) {
      //         console.log('update count and payment')
      //         this.bikeName = this.invoiceData.bike
      //         this.showView = 1
      //         this.updateInvoiceRecord()
      //       } else {
      //         console.log('No update action. Show error view.')
      //         this.showView = 2
      //       }
      //   } else {
      //       console.log("No such document!");
      //       this.showView = 2
      //       return
      //   }
      // }).catch((error) => {
      //     console.log("Error getting document:", error);
      // });

    },
    async updateInvoiceRecord () {
      let obj =  {
        count: 1,
        payment: true
      }
      console.log('sanasini')
      const docRef = doc(db, "invoice", this.invoiceId);

      // Set the "capital" field of the city 'DC'
      await updateDoc(docRef, obj);


    //   db.collection('invoice').doc(this.invoiceId).update(obj)
    //   .then(() => {
    //       console.log('InvoicePayment in bucket updated')
    //       console.log('sini')
    //     })
    //   .catch(error => {
    //     console.log('sana')

    //     console.log(error)
    //   })
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    makeDate (date) {
      return dateformat(new Date(date), 'dd mmm, yyyy')
    },
    /* registerRecurringPayment() {
        var newPostKey = db.collection("recurringPayment").doc();
        Promise.resolve(PaymentController.registerRecurringPayment({
            id: newPostKey.id,
            amount: this.getSubscription.subscriptionAmount,
            email: this.getSubscription.email,
            customer:{
                name: this.getSubscription.customerName,
                mobile_number: this.getSubscription.customerMobileNumber,
            },
            credit_card_token: this.getSubscription.creditCardToken
        }))
        .then(result => {
          console.log(result)
        })
        .catch(err => {
          console.log(err)
        })
    }, */

  },
};
</script>
<style>

.invTitle {
  color: #000000;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;
}

.otp {
  width: 200px;
}

.login{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.otp-field{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  align-items: center;
}

.succes-icon{
  font-size: 36px !important;
  color: #4FBD6D !important;
}

.failed-icon{
  font-size: 36px !important;
  color: #CB3A31 !important;
}
.booking-fee-sub-title{
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: 1rem 0;
}

.booking-fee-sub-title-countdown{
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: 1rem 0;
  color: #CB3A31 !important;
}

.booking-fee-header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.booking-fee-info{
  font-size: 16px;
  font-weight: 400;
  margin: 1rem 0;
}

.booking-fee-info-2{
  font-size: 14px;
  font-weight: 400;
  margin: 1rem 0;
}

.booking-fee-icon{
  color: white !important;
}

.booking-fee-body{
  margin: 1rem 0;
}

.booking-fee-mini-card{
  margin: 1rem 0;
}

.booking-fee-bike{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
</style>
