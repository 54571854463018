<template>
  <v-sheet fluid class="p-0 m-0">
    <section class="bg-baycat">
      <v-container class="banner-footer-container" fluid>
        <v-row>
          <v-col class="baycat-color-option-container">
            <span style="text-transform: uppercase" class="font-semibold">
              {{ lang[getLanguage].COLOR_OPTION_TEXT }}
            </span>
            <span class="mx-10">:</span>
            <template v-if="bike.colorOptions">
              <div
                v-for="color in bike.colorOptions"
                :key="color"
                class="color-palette"
                :style="{ backgroundColor: color }"
              ></div>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="$vuetify.breakpoint.smAndUp" class="banner-footer-desktop banner-container banner-footer banner-footer-info">
            <div class="flex justify-center items-center">
              <div>
                <span
                  class="banner-footer-info-title font-work-sans font-semibold block"
                  :style="
                    getLanguage === 'id'
                      ? 'font-size: 18px !important; letter-spacing: -0.36px;'
                      : 'font-size: 18px !important; letter-spacing: -0.36px;'
                  "
                >
                  {{ lang[getLanguage].OTR }} (Jakarta)
                </span>
                <span class="text-2xl font-bold mr-3">
                  {{ bike.price }}
                </span>
                <span class="text-decoration-line-through text-gray-800">
                  {{ bike.actualPrice }}
                </span>
                <span class="banner-footer-info-subtitle text-base block">
                  {{
                    getLanguage == "en"
                      ? "Include 1 Battery + 1 Standard Charger"
                      : "Termasuk 1 Baterai + 1 Pengisi Daya Standar"
                  }}
                </span>
              </div>
              <v-divider class="mx-9 bg-white" vertical></v-divider>
              <div>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfxDpk_QwR8k4wmvhZTtO4pBeLYqlsIiPvXzsnzdm4FhTgzIA/viewform"
                  target="_blank"
                  style="
                    text-decoration: none;
                    display: inline-block;
                    width: 200px !important;
                  "
                >
                  <span
                    class="font-work-sans text-base py-3 px-6 bg-charged-purple-300 rounded-lg text-white w-full inline-block text-center hover:bg-charged-purple-400 duration-300"
                    style="letter-spacing: -0.32px"
                  >
                    {{ lang[getLanguage].PRODUCT_SUBSCRIBE_BUY_NOW }}
                  </span>
                </a>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    
    <section>
      <div class="banner-footer-mobile py-7 px-4 banner-footer-info-mobile" style="background-color: #262626;">
        <span
          class="font-work-sans text-xl font-semibold inline-block mb-1"
          style="letter-spacing: -0.4px"
        >
          {{ lang[getLanguage].OTR }} (Jakarta) </span
        ><br />
        <span class="text-2xl font-bold mr-3">
          {{ bike.price }}
        </span>
        <span class="text-decoration-line-through text-gray-500">
          {{ bike.actualPrice }}
        </span>
        <span class="banner-footer-info-subtitle text-base block">
          {{
            getLanguage == "en"
              ? "Include 1 Battery + 1 Standard Charger"
              : "Termasuk 1 Baterai + 1 Pengisi Daya Standar"
          }}
        </span>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSfxDpk_QwR8k4wmvhZTtO4pBeLYqlsIiPvXzsnzdm4FhTgzIA/viewform"
          target="_blank"
        >
          <v-btn
            color="primary"
            class="work-sans mb-4"
            style="
              color: rgb(255, 255, 255);
              height: 45px;
              text-transform: initial;
              font-size: 16px;
              border-radius: 8px;
              margin-bottom: 0px !important;
              width: 90%;
              margin-top: 12px;
              letter-spacing: -0.32px;
            "
            >{{ lang[getLanguage].PRODUCT_SUBSCRIBE_BUY_NOW }}</v-btn
          >
        </a>
      </div>
    </section>

    <section v-if="$vuetify.breakpoint.smAndUp" class="baycat-features desktop-view font-work-sans">
      <div class="title-header">{{ lang[getLanguage].BAYCAT_FEATURE_TITLE }}</div>
      <div class="subtitle-header mt-8">
        {{ lang[getLanguage].BAYCAT_FEATURE_TITLE_DESCRIPTION }}
      </div>
      <v-tabs style="margin-top: 72px;" grow v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          href="#tab-1"
          :class="
            tab == 'tab-1'
              ? 'work-sans font-semibold'
              : 'work-sans border-b border-charged-grey-400'
          "
          style="
            font-size: 18px;
            text-transform: unset;
            letter-spacing: -0.36px;
          "
        >
          {{ lang[getLanguage].CONVENIENCE }}
        </v-tab>

        <v-tab
          href="#tab-2"
          :class="
            tab == 'tab-2'
              ? 'work-sans font-semibold'
              : 'work-sans border-b border-charged-grey-400'
          "
          style="
            font-size: 18px;
            text-transform: unset;
            letter-spacing: -0.36px;
          "
        >
          {{ lang[getLanguage].AGILITY }}
        </v-tab>

        <v-tab
          href="#tab-3"
          :class="
            tab == 'tab-3'
              ? 'work-sans font-semibold capitalize'
              : 'work-sans capitalize border-b border-charged-grey-400'
          "
          style="
            font-size: 18px;
            text-transform: unset;
            letter-spacing: -0.36px;
          "
        >
          {{ lang[getLanguage].REFINED_POWER }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-row align="center" justify="center" class="mt-12">
            <v-col cols="12" md="5" class="work-sans">
              <div
                class="mb-8"
                v-html="bike.convenienceDesc[getLanguage]"
              ></div>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfxDpk_QwR8k4wmvhZTtO4pBeLYqlsIiPvXzsnzdm4FhTgzIA/viewform"
                target="_blank"
                style="text-decoration: none"
              >
                <v-btn
                  outlined
                  class="work-sans mb-4 font-medium"
                  style="
                    color: #fff;
                    font-size: 16px;
                    border-radius: 8px;
                    text-transform: initial;
                    padding: 12px 24px;
                    letter-spacing: -0.32px;
                    height: 48px;
                  "
                  >{{
                    lang[getLanguage].PRODUCT_SUBSCRIBE_BUY_NOW
                  }}</v-btn
                >
              </a>
            </v-col>
            <v-col cols="12" md="1"></v-col>
            <v-col cols="12" md="6">
              <v-img :src="bikeTab1" max-height="25%"></v-img>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-row align="center" justify="center" class="mt-12">
            <v-col cols="12" md="5" class="work-sans">
              <div
                class="mb-8"
                v-html="bike.agilityDesc[getLanguage]"
              ></div>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfxDpk_QwR8k4wmvhZTtO4pBeLYqlsIiPvXzsnzdm4FhTgzIA/viewform"
                target="_blank"
                style="text-decoration: none"
              >
                <v-btn
                  outlined
                  class="work-sans mb-4 font-medium"
                  style="
                    color: #fff;
                    font-size: 16px;
                    border-radius: 8px;
                    text-transform: initial;
                    padding: 12px 24px;
                    letter-spacing: -0.32px;
                    height: 48px;
                  "
                  >{{
                    lang[getLanguage].PRODUCT_SUBSCRIBE_BUY_NOW
                  }}</v-btn
                >
              </a>
            </v-col>
            <v-col cols="12" md="1"></v-col>
            <v-col cols="12" md="6">
              <v-img :src="bikeTab2" max-height="25%"></v-img>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="tab-3">
          <v-row align="center" justify="center" class="mt-12">
            <v-col cols="12" md="5" class="work-sans">
              <div
                class="mb-8"
                v-html="bike.refinedPowerDesc[getLanguage]"
              ></div>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfxDpk_QwR8k4wmvhZTtO4pBeLYqlsIiPvXzsnzdm4FhTgzIA/viewform"
                target="_blank"
                style="text-decoration: none"
              >
                <v-btn
                  outlined
                  class="work-sans mb-4 font-medium"
                  style="
                    color: #fff;
                    font-size: 16px;
                    border-radius: 8px;
                    text-transform: initial;
                    padding: 12px 24px;
                    letter-spacing: -0.32px;
                    height: 48px;
                  "
                  >{{
                    lang[getLanguage].PRODUCT_SUBSCRIBE_BUY_NOW
                  }}</v-btn
                >
              </a>
            </v-col>
            <v-col cols="12" md="1"></v-col>
            <v-col cols="12" md="6">
              <v-img :src="bikeTab3" max-height="25%"></v-img>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </section>

    <section class="baycat-features mobile-view font-work-sans" v-if="$vuetify.breakpoint.smAndDown">
      <v-card-title
        class="pl-0 montserrat font-weight-bold"
        style="
          font-size: 28px;
          letter-spacing: -0.025em;
          width: 88%;
          word-break: break-word;
        "
      >
        {{ bike.productTagLine[getLanguage].title }}
      </v-card-title>

      <v-card-text class="pl-0 mt-4">
        <p
          class="work-sans"
          style="font-size: 18px; color: #fff; letter-spacing: -0.02em"
        >
          {{ bike.productTagLine[getLanguage].desctiption }}
        </p>
      </v-card-text>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-plus">
            <span class="font-semibold">
              {{ lang[getLanguage].CONVENIENCE }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              class="work-sans px-0"
              style="margin: 0px !important"
            >
              <div cols="12" md="6" class="mt-6">
                <v-img :src="bikeTab1" max-height="25%"></v-img>
              </div>
              <div cols="12" md="6" class="work-sans my-8">
                <div
                  class="text-white"
                  style="letter-spacing: -0.32px"
                  v-html="bike.convenienceDesc[getLanguage]"
                ></div>
              </div>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfxDpk_QwR8k4wmvhZTtO4pBeLYqlsIiPvXzsnzdm4FhTgzIA/viewform"
                target="_blank"
                style="text-decoration: none; margin-left: 0px"
              >
                <v-btn
                  outlined
                  class="work-sans mb-8 font-normal"
                  style="
                    color: #fff;
                    font-size: 16px;
                    border-radius: 8px;
                    text-transform: initial;
                    padding: 10px 24px;
                    letter-spacing: -0.32px;
                    height: 40px;
                  "
                  >{{ lang[getLanguage].PRODUCT_SUBSCRIBE_BUY_NOW }}</v-btn
                >
              </a>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-plus">
            <span class="font-semibold">
              {{ lang[getLanguage].AGILITY }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              class="work-sans px-0"
              style="margin: 0px !important"
            >
              <div cols="12" md="6" class="mt-6">
                <v-img :src="bikeTab2" max-height="25%"></v-img>
              </div>
              <div cols="12" md="6" class="work-sans mt-8">
                <div
                  class="text-white"
                  style="letter-spacing: -0.32px"
                  v-html="bike.agilityDesc[getLanguage]"
                ></div>
              </div>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfxDpk_QwR8k4wmvhZTtO4pBeLYqlsIiPvXzsnzdm4FhTgzIA/viewform"
                target="_blank"
                style="text-decoration: none; margin-left: 0px"
              >
                <v-btn
                  outlined
                  class="work-sans mb-8 font-normal"
                  style="
                    color: #fff;
                    font-size: 16px;
                    border-radius: 8px;
                    text-transform: initial;
                    padding: 10px 24px;
                    letter-spacing: -0.32px;
                    height: 40px;
                  "
                  >{{ lang[getLanguage].PRODUCT_SUBSCRIBE_BUY_NOW }}</v-btn
                >
              </a>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-plus">
            <span class="font-semibold">
              {{ lang[getLanguage].REFINED_POWER }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              class="work-sans px-0"
              style="margin: 0px !important"
            >
              <v-col cols="12" md="6" class="mt-6">
                <v-img :src="bikeTab3" max-height="25%"></v-img>
              </v-col>
              <v-col cols="12" md="6" class="work-sans my-8">
                <div
                  class="text-white"
                  style="letter-spacing: -0.32px"
                  v-html="bike.refinedPowerDesc[getLanguage]"
                ></div>
              </v-col>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfxDpk_QwR8k4wmvhZTtO4pBeLYqlsIiPvXzsnzdm4FhTgzIA/viewform"
                target="_blank"
                style="text-decoration: none; margin-left: 0px"
              >
                <v-btn
                  outlined
                  class="work-sans mb-8 font-normal"
                  style="
                    color: #fff;
                    font-size: 16px;
                    border-radius: 8px;
                    text-transform: initial;
                    padding: 10px 24px;
                    letter-spacing: -0.32px;
                    height: 40px;
                  "
                  >{{ lang[getLanguage].PRODUCT_SUBSCRIBE_BUY_NOW }}</v-btn
                >
              </a>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section>

    <section id="detail-specification">
      <div class="bg-white w-full px-4 py-16 box-border">
        <div class="md:py-14 md:px-20">
          <v-row class="md:px-20">
            <!-- Heading -->
            <v-col cols="12" md="12">
              <v-card-title
                style="
                  color: #858688;
                  line-height: 32px;
                  letter-spacing: -0.6px;
                  font-weight: 700;
                "
                class="pl-0 pr-0 montserrat text-uppercase justify-center text-xl md:text-2xl"
              >
                {{ lang[getLanguage].PRODUCT_SPECIFICATION_TITLE }}
              </v-card-title>
            </v-col>

            <!-- 2 ROW DATA -->
            <v-col
              cols="12"
              md="6"
              class="md:border-r md:border-charged-grey-500"
            >
              <v-card-title
                style="
                  color: #858688;
                  font-size: 18px;
                  line-height: 26px;
                  letter-spacing: -0.36px;
                  font-weight: 600 !important;
                "
                class="pl-0 font-work-sans font-semibold font-weight-bold text-uppercase justify-center"
              >
                {{ lang[getLanguage].PRODUCT_SPEED_TITLE }}
              </v-card-title>
              <v-card-title class="justify-center">
                <p
                  class="montserrat font-weight-bold text-fs-52 md:text-fs-68"
                  style="color: #353638; letter-spacing: -1.56px"
                >
                  {{ bike.topSpeed }}
                </p>
                <small
                  class="work-sans ml-3 text-lg"
                  style="color: #858688; letter-spacing: -0.36px"
                >
                  {{ lang[getLanguage].PRODUCT_SPEED_QUANTITY }}
                </small>
              </v-card-title>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-title class="justify-center">
                <p
                  style="
                    color: #858688;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.36px;
                    font-weight: 600 !important;
                  "
                  class="pl-0 mb-0 font-work-sans font-semibold font-weight-bold text-uppercase"
                >
                  {{ lang[getLanguage].PRODUCT_DISTANCE_TITLE }}
                </p>
                <small
                  v-if="bike.battery > 1"
                  class="work-sans uppercase font-normal"
                  style="color: #858688; font-size: 14px"
                >
                  &nbsp; ({{ bike.battery }}
                  {{ lang[getLanguage].BATTERIES }})</small
                >
              </v-card-title>
              <v-card-title class="justify-center">
                <p
                  class="montserrat font-weight-bold text-fs-52 md:text-fs-68"
                  style="color: #353638; letter-spacing: -1.56px"
                >
                  {{ bike.maximumRange }}
                </p>
                <small
                  class="work-sans ml-3 text-lg"
                  style="color: #858688; letter-spacing: -0.36px"
                >
                  {{ lang[getLanguage].PRODUCT_DISTANCE_QUANTITY }}
                </small>
              </v-card-title>
            </v-col>

            <!-- Mini Text -->
            <v-col cols="12" md="12">
              <v-card-title
                style="
                  color: #858688;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: -0.24px;
                  font-weight: 400;
                "
                class="px-0 work-sans justify-center"
              >
                <span
                  class="hidden md:table text-center"
                  v-html="
                    formattedWrapText(
                      bike.specificationDesc[getLanguage],
                      getLanguage == 'en' ? 'Your results' : 'Hasil mungkin'
                    )
                  "
                ></span>
                <span class="md:hidden">
                  {{ bike.specificationDesc[getLanguage] }}
                </span>
              </v-card-title>
            </v-col>
          </v-row>

          <!-- dekstop -->
          <div class="hidden md:table w-full mt-24">
            <!-- Technical Specification Dekstop -->
            <div class="w-full pb-10">
              <v-tabs
                vertical
                v-model="tab2"
                background-color="#ffffff"
                color="#535456"
                class="px-16 py-4 flex justify-between custom-table-hover"
              >
                <v-tabs-slider color="white"></v-tabs-slider>

                <div class="flex gap-x-36">
                  <div class="w-full">
                    <v-tab
                      href="#tab2-1"
                      class="work-sans"
                      style="
                        text-transform: unset;
                        font-size: 18px;
                        letter-spacing: -0.02em;
                      "
                    >
                      <span
                        :class="
                          tab2 == 'tab2-1'
                            ? 'font-semibold'
                            : 'font-normal'
                        "
                        :style="
                          tab2 == 'tab2-1'
                            ? 'color: #535456'
                            : 'color: #858688 !important'
                        "

                      >
                        {{ lang[getLanguage].KEY_NUMBERS }}
                      </span>
                      <v-spacer></v-spacer>
                      <v-icon
                        right
                        :class="
                          tab2 == 'tab2-1'
                            ? 'font-semibold'
                            : 'font-normal'
                        "
                        :style="
                          tab2 == 'tab2-1'
                            ? 'color: #858688'
                            : 'color: #535456 !important'
                        "
                      >
                        mdi-chevron-right
                      </v-icon>
                    </v-tab>

                    <v-tab
                      href="#tab2-2"
                      class="work-sans"
                      style="
                        color: #535456;
                        text-transform: unset;
                        font-size: 18px;
                        letter-spacing: -0.02em;
                      "
                    >
                      <span
                        :class="
                          tab2 == 'tab2-2'
                            ? 'font-semibold'
                            : 'font-normal'
                        "
                        :style="
                          tab2 == 'tab2-2'
                            ? 'color: #858688'
                            : 'color: #535456 !important'
                        "
                      >
                        {{ lang[getLanguage].POWERTRAIN }}
                      </span>
                      <v-spacer></v-spacer>
                      <v-icon
                        right
                        :class="
                          tab2 == 'tab2-2'
                            ? 'font-semibold'
                            : 'font-normal'
                        "
                        :style="
                          tab2 == 'tab2-2'
                            ? 'color: #858688'
                            : 'color: #535456 !important'
                        "
                      >
                        mdi-chevron-right
                      </v-icon>
                    </v-tab>

                    <v-tab
                      href="#tab2-3"
                      class="work-sans"
                      style="
                        color: #535456;
                        text-transform: unset;
                        font-size: 18px;
                        letter-spacing: -0.02em;
                      "
                    >
                      <span
                        :class="
                          tab2 == 'tab2-3'
                            ? 'font-semibold'
                            : 'font-normal'
                        "
                        :style="
                          tab2 == 'tab2-3'
                            ? 'color: #858688'
                            : 'color: #535456 !important'
                        "
                      >
                        {{ lang[getLanguage].VEHICLE_SPECIFICATION }}
                      </span>
                      <v-spacer></v-spacer>
                      <v-icon
                        right
                        :class="
                          tab2 == 'tab2-3'
                            ? 'font-semibold'
                            : 'font-normal'
                        "
                        :style="
                          tab2 == 'tab2-3'
                            ? 'color: #858688'
                            : 'color: #535456 !important'
                        "
                      >
                        mdi-chevron-right
                      </v-icon>
                    </v-tab>
                  </div>
                  <div class="w-full">
                    <v-tabs-items
                      class="mr-0"
                      v-model="tab2"
                      style="background-color: #ffffff"
                    >
                      <v-tab-item
                        value="tab2-1"
                        class="work-sans"
                        style="color: #535456; text-align: -webkit-center"
                      >
                        <v-simple-table
                          light
                          style="
                            background-color: #ffffff;
                            color: #535456;
                            width: 707px;
                          "
                        >
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="item in bike.specification.keys[
                                  getLanguage
                                ]"
                                :key="item.description"
                              >
                                <td class="text-left font-semibold">
                                  {{ item.description }}
                                </td>
                                <td class="text-right">{{ item.details }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-tab-item>
                      <v-tab-item
                        value="tab2-2"
                        class="work-sans"
                        style="color: #535456; text-align: -webkit-center"
                      >
                        <v-simple-table
                          light
                          style="
                            background-color: #ffffff;
                            color: #535456;
                            width: 707px;
                          "
                        >
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="item in bike.specification.powertrain[
                                  getLanguage
                                ]"
                                :key="item.description"
                              >
                                <td class="text-left font-semibold">
                                  {{ item.description }}
                                </td>
                                <td class="text-right">{{ item.details }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-tab-item>
                      <v-tab-item
                        value="tab2-3"
                        class="work-sans"
                        style="color: #535456; text-align: -webkit-center"
                      >
                        <v-simple-table
                          light
                          style="
                            background-color: #ffffff;
                            color: #535456;
                            width: 707px;
                          "
                        >
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="item in bike.specification.other[
                                  getLanguage
                                ]"
                                :key="item.description"
                              >
                                <td class="text-left font-semibold">
                                  {{ item.description }}
                                </td>
                                <td class="text-right">{{ item.details }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                </div>
              </v-tabs>
            </div>
            <!-- button unduh brosur in Dekstop -->
            <v-row
              v-if="bike.isRelease"
              class="flex justify-center mt-14 w-full mx-auto"
            >
              <span
                class="text-charged-grey-800 py-3 px-6 border border-black rounded-lg cursor-pointer hover:bg-white hover:bg-opacity-10"
                style="height: 48px"
                @click.stop="openBrochureBike"
              >
                <v-icon
                  color="#353638"
                  style="margin-right: 0.5rem; font-size: 20px"
                  >mdi-tray-arrow-down</v-icon
                >
                {{
                  getLanguage == "en"
                    ? "Download " + bike.name + " E-Brosur 2025"
                    : "Unduh " + bike.name + " E-Brosur 2025"
                }}
              </span>
            </v-row>
            <!-- END Technical Specification Dekstop -->
          </div>

          <!-- Mobile -->
          <div class="md:hidden">
            <!-- Technical Specification Mobile -->
            <v-container class="px-0" style="padding: 0px !important">
              <div class="faq-question">
                <input id="q4" type="checkbox" class="panel" />
                <!-- <div class="plus" style="color: white; right: 17px">+</div> -->
                <label
                  for="q4"
                  href="#tab2-1"
                  class="work-sans panel-title relative"
                  style="color: #353638; text-align: initial"
                >
                  <span class="-ml-3 font-semibold">
                    {{ lang[getLanguage].KEY_NUMBERS }}
                  </span>
                  <span class="btn-toggle-collaps-product-tab-2"></span>
                </label>
                <div
                  class="work-sans panel-content"
                  style="margin: 0px !important"
                  v-for="item in bike.specification.keys[getLanguage]"
                  :key="item.description"
                >
                  <v-container class="pl-0">
                    <v-row style="flex-direction: column">
                      <span
                        class="text-left font-semibold"
                        style="color: #353638"
                      >
                        {{ item.description }}
                      </span>
                      <span class="text-left font-normal" style="color: #353638">
                        {{ item.details }}
                      </span>
                    </v-row>
                  </v-container>
                </div>
              </div>

              <div class="faq-question">
                <input id="q5" type="checkbox" class="panel" />
                <!-- <div class="plus" style="color: white; right: 17px">+</div> -->
                <label
                  for="q5"
                  href="#tab2-1"
                  class="work-sans panel-title relative"
                  style="color: #353638; text-align: initial"
                >
                  <span class="-ml-3 font-semibold">
                    {{ lang[getLanguage].POWERTRAIN }}
                  </span>
                  <span class="btn-toggle-collaps-product-tab-2"></span>
                </label>
                <div
                  class="work-sans panel-content"
                  style="margin: 0px !important"
                  v-for="item in bike.specification.powertrain[getLanguage]"
                  :key="item.description"
                >
                  <v-container class="pl-0">
                    <v-row style="flex-direction: column">
                      <span
                        class="text-left font-semibold"
                        style="color: #353638"
                        >{{ item.description }}</span
                      >
                      <span
                        class="text-left font-normal"
                        style="color: #353638"
                        >{{ item.details }}</span
                      >
                    </v-row>
                  </v-container>
                </div>
              </div>

              <div class="faq-question">
                <input id="q6" type="checkbox" class="panel" />
                <label
                  for="q6"
                  href="#tab2-1"
                  class="work-sans panel-title relative"
                  style="color: #353638; text-align: initial"
                >
                  <span class="-ml-3 font-semibold">
                    {{ lang[getLanguage].VEHICLE_SPECIFICATION }}
                  </span>
                  <span class="btn-toggle-collaps-product-tab-2"></span>
                </label>
                <div
                  class="work-sans panel-content"
                  style="margin: 0px !important"
                  v-for="item in bike.specification.other[getLanguage]"
                  :key="item.description"
                >
                  <v-container class="pl-0">
                    <v-row style="flex-direction: column">
                      <span
                        class="text-left font-semibold"
                        style="color: #353638"
                        >{{ item.description }}</span
                      >
                      <span
                        class="text-left font-normal"
                        style="color: #353638"
                        >{{ item.details }}</span
                      >
                    </v-row>
                  </v-container>
                </div>
              </div>
            </v-container>

            <!-- button download -->
            <div
              v-if="bike.isRelease"
              class="mt-16 md:mt-10 flex justify-center"
            >
              <v-btn
                outlined
                class="work-sans"
                style="
                  color: #353638;
                  border-radius: 8px !important;
                  text-transform: initial;
                  width: 100%;
                  padding: 20px 10px 20px 10px !important;
                  height: 48px;
                "
                @click.stop="openBrochureBike"
              >
                <v-icon
                  color="#353638"
                  style="margin-right: 0.5rem; font-size: 20px"
                  >mdi-tray-arrow-down
                </v-icon>
                {{
                  getLanguage == "en"
                    ? "Download " + bike.name + " E-Brosur 2025"
                    : "Unduh " + bike.name + " E-Brosur 2025"
                }}
              </v-btn>
            </div>
            <!-- END Technical Specification Mobile -->
          </div>
        </div>
      </div>
    </section>

    <section>
      <v-img src="/img/shared/bikes/baycat 2/red jump out/Baycat website.png"/>
    </section>

    <div v-if="bike.isRelease" class="pt-20 pb-6 px-4">
      <div
        class="flex flex-wrap justify-between md:pt-10 md:pb-28 md:px-20 items-center"
      >
        <div class="w-full md:w-3/5 mb-8">
          <v-img
            :src="bikeSideEmblem"
            :width="$vuetify.breakpoint.mdAndDown ? '328px' : '670px'"
            class="mx-auto"
          ></v-img>
          <div class="flex justify-center mb-10 flex-wrap items-center mt-5">
            <span
              style="text-transform: uppercase"
              class="font-semibold hidden md:inline-block"
            >
              {{ lang[getLanguage].COLOR_OPTION_TEXT }}
            </span>
            <span class="mx-10 mb-2 md:mb-0 hidden md:inline-block">:</span>
            <div class="d-flex" v-if="bike.colorOptionsDes">
              <div
                v-for="color in bike.colorOptionsDes"
                :key="color.name"
                class="mx-5"
              >
                <div
                  class="color-palette mx-auto"
                  :style="{ backgroundColor: color.hex }"
                ></div>
                <span
                  class="w-full whitespace-nowrap text-center relative top-2 text-sm text-gray-40 md:block hidden"
                >
                  {{ color.name }}
                </span>
              </div>
            </div>
          </div>
          <span
            class="font-work-sans text-xs text-charged-grey-400 flex md:items-center"
            style="letter-spacing: -0.24px"
          >
            <v-icon
              color="#858688"
              class="pr-3 pb-3 pt-3"
              style="font-size: 20px"
              >mdi-alert-circle-outline</v-icon
            >
            {{ lang[getLanguage].PRODUCT_SUBSCRIBE_DESCRIPTION }}
          </span>
        </div>
        <div class="w-full my-auto lg:w-1/3">
          <v-card-title class="pl-0 desktop-view ">
            <small
              class="work-sans text-xs md:text-base"
              style="
                color: #353638;
                font-weight: 400;
                margin-right: 0.5rem;
                letter-spacing: -0.24px;
              "
            >
              {{ lang[getLanguage].OTR }} (Jakarta)
            </small>
          </v-card-title>
          <v-card-text class="pl-0 flex items-center gap-3 desktop-view ">
            <p
              class="montserrat font-weight-bold text-charged-grey-200 text-2xl md:text-4xl"
              style="
                margin-bottom: 0 !important;
                letter-spacing: -1.08px;
                letter-spacing: -0.6px;
              "
            >
              {{ bike.price }}
            </p>
            <small
              v-if="bike.actualPrice"
              class="text-decoration-line-through text-lg font-normal"
            >
              {{ bike.actualPrice }}
            </small>
          </v-card-text>
          <v-card-text
            class="work-sans pl-0 pt-2 text-xs md:text-base desktop-view "
            style="color: #858688"
          >
            {{ bike.priceDescription[getLanguage] }}
          </v-card-text>

          <v-divider class="my-2 desktop-view" style="border-top-width: 2px"></v-divider>

          <v-card-text
            class="work-sans pb-4 pl-0 text-charged-grey-200"
            style="font-size: 18px; font-weight: 500; letter-spacing: -0.32px"
          >
            {{ bike.text.includeInTheOtrPrice[getLanguage] }}
          </v-card-text>

          <div
            v-for="include in bike?.text?.includeInTheOtrDescription[
              getLanguage
            ]"
            :key="include.name"
          >
            <v-card-text
              class="work-sans pb-2 pl-0 text-charged-grey-200"
              style="
                font-size: 20px;
                font-weight: 600;
                padding: 0 !important;
                text-transform: capitalize;
              "
            >
              <v-icon color="green">mdi-check-circle</v-icon>
              {{ include?.name }}
            </v-card-text>
            <v-card-text class="work-sans pl-8 pt-2" style="color: #858688">
              {{ include?.description }}
            </v-card-text>
          </div>

          <v-divider class="my-2 mobile-view" style="border-top-width: 2px"></v-divider>

          <v-card-title class="pl-0 mobile-view">
            <small
              class="work-sans text-xs md:text-base"
              style="
                color: #353638;
                font-weight: 400;
                margin-right: 0.5rem;
                letter-spacing: -0.24px;
              "
            >
              {{ lang[getLanguage].OTR }} (Jakarta)
            </small>
          </v-card-title>
          <v-card-text class="pl-0 flex items-center gap-3 mobile-view">
            <p
              class="montserrat font-weight-bold text-charged-grey-200 text-2xl md:text-4xl"
              style="
                margin-bottom: 0 !important;
                letter-spacing: -1.08px;
                letter-spacing: -0.6px;
              "
            >
              {{ bike.price }}
            </p>
            <small
              v-if="bike.actualPrice"
              class="text-decoration-line-through text-lg font-normal"
            >
              {{ bike.actualPrice }}
            </small>
          </v-card-text>
          <v-card-text
            class="work-sans pl-0 pt-2 text-xs md:text-base mobile-view"
            style="color: #858688"
          >
            {{ bike.priceDescription[getLanguage] }}
          </v-card-text>

          <div class="work-sans px-0">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfxDpk_QwR8k4wmvhZTtO4pBeLYqlsIiPvXzsnzdm4FhTgzIA/viewform"
              target="_blank"
            >
              <v-btn
                color="primary"
                class="work-sans"
                style="
                  color: rgb(255, 255, 255);
                  height: 45px;
                  text-transform: initial;
                  font-size: 16px;
                  border-radius: 8px;
                  margin-bottom: 0px !important;
                  width: 100%;
                  margin-top: 12px;
                  letter-spacing: -0.32px;
                "
                >{{ lang[getLanguage].PRODUCT_SUBSCRIBE_BUY_NOW }}</v-btn
              >
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Section Form interested -->
    <section
      ref="form_interested"
      v-if="!bike.isRelease"
      class="overflow-hidden box-border"
    >
      <div class="px-2 md:px-4 my-16 mx-auto">
        <v-row justify="space-between" class="md:px-16 items-center">
          <v-col cols="12" md="7">
            <v-carousel hide-delimiters height="">
              <v-carousel-item
                v-for="(item, index) in 2"
                :key="index"
                class="mx-auto"
              >
                <v-sheet height="100%">
                  <div class="d-flex fill-height justify-center align-center">
                    <img
                      :src="
                        '/img/shared/bikes/' +
                        bikeName +
                        '/slide-' +
                        (index + 1) +
                        '.png'
                      "
                      width="100%"
                      style="max-width: 680px"
                      :alt="bike.name"
                    />
                  </div>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
            <div class="flex items-center mt-8">
              <div class="mr-2">
                <v-icon
                  :style="
                    windowWidth > 770 ? 'font-size: 24px;' : 'font-size: 20px;'
                  "
                  start
                  >mdi-information-outline</v-icon
                >
              </div>
              <div>
                <span
                  class="font-work-sans text-xs text-charged-grey-400 block"
                  :style="
                    windowWidth > 770
                      ? ''
                      : 'letter-spacing: -0.24px; line-height: 1.2rem;'
                  "
                >
                  {{ lang[getLanguage].PRODUCT_SUBSCRIBE_DESCRIPTION }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="5">
            <div class="flex md:block items-center gap-3">
              <p
                class="text-charged-grey-200 font-work-sans text-xs md:text-base"
              >
                {{ lang[getLanguage].LIMITED_EDITION }}
              </p>
              <p
                class="font-montserrat text-charged-grey-200 font-bold text-2xl md:text-4xl"
              >
                {{ lang[getLanguage].TITLE_COMMINGSOON }}
              </p>
            </div>
            <p
              class="font-work-sans text-charged-grey-200 font-medium text-sm md:text-base"
            >
              {{ lang[getLanguage].INFO_AVAILABLE_PURCHASE }}
            </p>
            <p
              class="font-work-sans text-charged-grey-400 text-xs md:text-sm"
              style="line-height: 1.2rem"
            >
              {{ lang[getLanguage].SUBTITLE_COMMINGSOON_FORM }}
            </p>
            <v-divider class="mb-5 mt-5" :thickness="2"></v-divider>
            <span
              class="font-work-sans text-charged-grey-200 text-base md:text-lg font-medium block md:leading-3"
              v-html="lang[getLanguage].SUGEST_COMMINGSOON_FORM"
            >
            </span>

            <v-btn
              block
              class="capitalize font-work-sans font-medium text-base rounded-lg mt-5"
              color="primary"
              style="height: 48px; font-size: 16px !important"
              @click="submitFormInterest"
              :disabled="disableBtnSubmitInterest"
            >
              {{ lang[getLanguage].BUT_SUBMIT }}
            </v-btn>

            <span
              class="text-center font-work-sans text-xs text-charged-grey-400 block mt-4"
            >
              {{ lang[getLanguage].INFO_SUMIT_COMMINGSOON_FORM }}
            </span>
          </v-col>
        </v-row>
      </div>
    </section>

    <section>
      <!-- section untuk informasi / For more information DEKSTOP -->
      <v-layout wrap v-if="!isMobileDevice">
        <v-col style="padding: 0px" cols="12" sm="12">
          <v-img
            position="center"
            min-height="150"
            style="background-color: #f5f4f6"
          >
            <v-layout class="mt-16 mx-auto">
              <v-container class="md:px-20">
                <v-row>
                  <v-layout column>
                    <span
                      class="montserrat font-weight-bold"
                      style="
                        color: #535456;
                        font-size: 24px;
                        letter-spacing: -0.025em;
                      "
                      >{{ lang[getLanguage].PRODUCT_INFORMATION }}</span
                    >
                  </v-layout>

                  <v-layout column>
                    <v-btn
                      text
                      color="#535456"
                      :to="'/contact'"
                      class="work-sans no-background-hover"
                    >
                      <v-icon
                        right
                        style="
                          color: #535456;
                          font-size: 32px;
                          margin-left: auto;
                        "
                      >
                        mdi-arrow-right
                      </v-icon>
                    </v-btn>
                  </v-layout>
                </v-row>
              </v-container>
            </v-layout>
          </v-img>
        </v-col>
      </v-layout>

      <!-- section untuk informasi / For more information MOBILE -->
      <v-layout wrap v-if="isMobileDevice">
        <v-col style="padding: 0px" cols="12" sm="12">
          <v-container class="px-5">
            <v-layout class="my-7 flex flex-nowrap">
              <span
                class="montserrat font-weight-bold font-semibold text-base"
                style="
                  color: #535456;
                  font-size: 16px;
                  letter-spacing: -0.32px;
                  /* margin-left: 5px; */
                "
              >
                {{ lang[getLanguage].PRODUCT_INFORMATION }}
              </span>
              <v-btn
                text
                color="#535456"
                :to="'/contact'"
                class="work-sans no-background-hover self-center"
              >
                <v-icon right style="color: #535456; font-size: 20px">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-layout>
          </v-container>
        </v-col>
      </v-layout>
    </section>

    <v-footer class="pa-0">
      <Footer
        id="footer"
        :isMobileDevice="isMobileDevice"
        :dark="dark"
        :drawer="drawer"
        :windowWidth="windowWidth"
      >
      </Footer>
    </v-footer>
  </v-sheet>
</template>

<script>
import store from "@/store/index";
import Footer from "../../FooterPressLaunch";

export default {
  name: "baycat-page",
  props: {
    model: String,
    dark: Boolean,
    isMobileDevice: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean,
  },
  components: {
    Footer,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
  },
  created() {
    this.getBikes();
    this.bike = this.$store.getters.vehicle_show_store;
    this.bikeName = this.bike.nameLowercase;
    this.getBikeName();
  },
  methods: {
    getBikes() {
      this.bikes = this.$store.getters.getListVehicle;
    },
    openBrochureBike() {
      window.open(
        "/pdf/brochure_" + this.bikeName + "_" + this.getLanguage + ".pdf",
        "_blank"
      );
    },
    getBikeName() {
      this.bikeTab1 = "/img/shared/bikes/" + this.bikeName + "/3 features/tab1.jpg";
      this.bikeTab2 = "/img/shared/bikes/" + this.bikeName + "/3 features/tab2.jpg";
      this.bikeTab3 = "/img/shared/bikes/" + this.bikeName + "/3 features/tab3.jpg";
      this.bikeSideEmblem = "/img/shared/bikes/" + this.bikeName + " 2/bike only 3 colors/Baycat website_bike only desktop 500x444.png";
    },
    formattedWrapText(text, textWrap) {
      const textAwalWrap = text.indexOf(textWrap);
      const firstParagraph = text.slice(0, textAwalWrap).trim();
      const secondParagraph = text.slice(textAwalWrap).trim();

      return `${firstParagraph}<br>${secondParagraph}`;
    },
  },
  data: () => {
    return {
      lang: store.state.lang,
      bike: {},
      tab: null,
      tab2: null,
    };
  },
};
</script>

<style scoped>
.bg-baycat {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.banner-container {
  display: flex;
  justify-content: center;
  align-items: end;
  align-content: center;
  height: 100%;
  width: 100%;
}

.baycat-color-option-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
}

.banner-footer-container {
  bottom: 0;
  width: 100vw;
  position: absolute;
  background: transparent;
}

.banner-footer {
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 0;
}

.banner-footer-mobile {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  width: 100%;
  padding: 2rem 0;
  margin: 0 !important;
}

.banner-footer-info {
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  color: white;
  border-right: 1px solid white;
  padding-right: 2rem;
}

.banner-footer-info-mobile {
  text-align: center;
  width: 100%;
  color: white;
}

.banner-footer-info-title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.banner-footer-info-title-mobile {
  font-size: 15px;
  font-weight: 700;
}

.banner-footer-info-subtitle {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.08em;
}

.banner-footer-info-subtitle-mobile {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.02em;
}

.banner-footer-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  padding-left: 3rem;
}

.baycat-features {
  background-color: #0c0b09;
  color: #ffffff;
}

.baycat-features > .title-header {
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -3%;
  font-family: Montserrat !important;
}

.baycat-features > .subtitle-header {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -2%;
}

.expansion-panel-header {
  background-color: transparent !important;
}
.expansion-panel-header {
  background-color: transparent !important;
}

.faq-header {
  font-size: 42px;
  border-bottom: 1px dotted #ccc;
  padding: 24px;
}

.faq-content {
  margin: 0 auto;
}

.faq-question {
  padding: 20px 0;
  border-bottom: 1px solid #ccc !important;
}

.panel-title {
  font-size: 18px;
  line-height: 26px;
  color: #262626;
  font-weight: 700;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 10px 10px 0 48px;
  display: block;
  cursor: pointer;
}

.panel-content {
  font-size: 16px;
  padding: 0px 14px;
  line-height: 26px;
  font-weight: 400;
  color: #535456;
  height: 0;
  overflow: hidden;
  z-index: 0;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition: 0.4s ease;
}

.panel:checked ~ .panel-content {
  height: auto;
  opacity: 1;
  padding: 14px;
}

.plus {
  position: absolute;
  /* margin-left: 1120px; */
  right: 40px;
  margin-top: 4px;
  z-index: 1;
  font-size: 42px;
  line-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.panel:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.panel {
  display: none;
}

.btn-toggle-collaps-product-tab-2::after {
  content: "";
  position: absolute;
  /* margin-top: 15px; */
  width: 16px;
  height: 2.3px;
  right: -25px;
  top: 23px;
  background: #535456;
}

.btn-toggle-collaps-product-tab-2::before {
  content: "";
  position: absolute;
  /* margin-top: 15px; */
  width: 16px;
  height: 2px;
  right: -25px;
  top: 23px;
  background: #535456;
  transform: rotate(90deg);
}

.panel:checked ~ .relative .btn-toggle-collaps-product-tab-2::before {
  transform: rotate(0deg);
}

@media (max-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .plus {
    margin-left: 700px;
  }

  .panel-title {
    margin-left: -45px;
  }
}

@media (max-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
  .plus {
    z-index: 1;
    margin-left: 350px;
  }

  .panel-title {
    margin-left: -35px;
  }

  .text-responsive-m {
    font-size: 4.2vw !important;
  }
}

@media (min-width: 768px) or (min-width: 1024px) or (min-width: 1280px) or (min-width: 1536px) {
  .container {
    max-width: 100vw !important;
  }
}

@media (max-width: 767px) {
  .bg-baycat {
    background-image: url("/img/shared/bikes/baycat 2/hero banner 2/Baycat website_mobile 390x844.jpg");
  }
  .baycat-features {
    padding: 80px 20px;
  }
  .banner-footer-mobile {
    display: block;
  }
  .banner-footer-desktop {
    display: none;
  }
  .mobile-view {
    display: block;
  }
  .desktop-view {
    display: none;
  }
}

@media (min-width: 768px) {
  .bg-baycat {
    background-image: url("/img/shared/bikes/baycat 2/hero banner 2/Baycat website_tablet 1024x768.jpg");
  }
  .baycat-features {
    padding: 120px 80px;
  }
  .banner-footer-mobile {
    display: none;
  }
  .banner-footer-desktop {
    display: block;
  }
  .mobile-view {
    display: none;
  }
  .desktop-view {
    display: block;
  }
}

@media (min-width: 1024px) {
  .bg-baycat {
    background-image: url("/img/shared/bikes/baycat 2/hero banner 2/Baycat website_tablet 1024x768.jpg");
  }
  .baycat-features {
    padding: 120px 170px;
  }
  .banner-footer-mobile {
    display: none;
  }
  .banner-footer-desktop {
    display: block;
  }
  .mobile-view {
    display: none;
  }
  .desktop-view {
    display: block;
  }
}

@media (min-width: 1500px) {
  .bg-baycat {
    height: 120vh;
    background-image: url("/img/shared/bikes/baycat 2/hero banner 2/Baycat website_desktop 1440x1024.jpg");
  }
  .baycat-features {
    padding: 120px 170px;
  }
  .banner-footer-mobile {
    display: none;
  }
  .banner-footer-desktop {
    display: block;
  }
  .mobile-view {
    display: none;
  }
  .desktop-view {
    display: block;
  }
}
</style>

<style>
.baycat-features > .theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}

.baycat-features > .theme--light.v-tabs-items {
  background-color: transparent !important;
  color: white !important;
}

.baycat-features > .theme--light.v-tabs > .v-tabs-bar > .v-slide-group__wrapper > .v-slide-group__content > .v-tab {
  color: #858688 !important;
}

.baycat-features > .theme--light.v-tabs > .v-tabs-bar > .v-slide-group__wrapper > .v-slide-group__content > .v-tab--active {
  color: white !important;
}

.baycat-features > .theme--light.v-tabs > .v-tabs-bar > .v-slide-group__wrapper > .v-slide-group__content > .v-tabs-slider-wrapper {
  color: white !important;
}

.baycat-features > .theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
  color: white !important;
  border-bottom: 1px solid white !important;
  border-radius: none !important;
  padding-top: 12px;
}

.baycat-features > .theme--light.v-expansion-panels .v-expansion-panel > .v-expansion-panel-header {
  padding-left: 0px;
  padding-right: 0px;
  font-family: Work Sans;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -2%;
}

.baycat-features > .theme--light.v-expansion-panels > .v-expansion-panel > .v-expansion-panel-header .v-expansion-panel-header__icon > i {
  color: white !important;
}

.baycat-features .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
